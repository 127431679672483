import {
  NestedDropdownAnswerValueType,
  RequestCounter,
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { ZvjsNestedDropdownViewOption } from "../../../../../../../components/ZvjsNestedDropDown";
import { findItem } from "../../../../../../redux/slice";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { API_Clients } from "../../../../../../../../store/context/dataApi/Data";
import { CisUstavZvjs_GR_Kod } from "../../../../../../../specs/countersValues";
import {
  getTranslationByLanguageCode,
  getUniqueCodesFromCiselnik, isAnyPorusenaPodmienka,
} from "../../../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import i18n from "i18next";
import {PodmienkaZiadostiEnum} from "../../../../utils/PodmienkaZiadostiEnum";

const LIST_OF_INSTITUTIONS = "LIST_OF_INSTITUTIONS";
const TRESTNOPRAVNE_UDAJE = "TRESTNOPRAVNE_UDAJE";
const QUESTION_ANSWER_ID = "miestoPozadovanehoUmiestnenia";

export const PlaceOfLocationQuestionConstants = {
  LIST_OF_INSTITUTIONS,
  TRESTNOPRAVNE_UDAJE,
  QUESTION_ANSWER_ID,
};

/**
 * OnInit
 */

export interface PlaceOfLocationQuestionInstitutionType {
  id: string;
  degreeOfProtection: string;
  differentiationGroup: string;
  hasExpectedFreeCapacity: boolean;
}

export const SC_KIO_051404_PlaceOfLocationQuestion_onInit = async (
  calcData: UserCalcData,
  counters: RequestCounter
): Promise<void> => {
  const { EPVVVT_Post } = await API_Clients();

  const data = await Promise.all([
    EPVVVT_Post("/api/stitkovnicaklientov/ListVsetciKlienti", {
      body: {
        ustavId: Number(localStorage.getItem("klientUstavId")),
      },
    }),
  ]);

  const epvvvtKlient = data[0].data
    ?.filter(
      (klient) =>
        klient.klientObjectId === localStorage.getItem("klientObjectId")
    )
    .at(0);

  // TODO we probably won't use epvvvtKlient but other api to retrieve information about client
  // if (epvvvtKlient === undefined) {
  //   // if some data were not fetch, do not display request and throw error
  //   throw new LoaderError();
  // }

  calcData[PlaceOfLocationQuestionConstants.TRESTNOPRAVNE_UDAJE] = epvvvtKlient;

  // const listOfInstitutions: PlaceOfLocationQuestionInstitutionType[] = [
  //   {
  //     id: "44",
  //     name: "Ústav na výkon väzby a Ústav na výkon trestu odňatia slobody Banská Bystrica",
  //     degreeOfProtection: "1. stupeň",
  //     differentiationGroup: "915 01",
  //     hasExpectedFreeCapacity: true,
  //   },
  //   {
  //     id: "42",
  //     name: "Ústav na výkon trestu odňatia slobody a Ústav na výkon väzby Leopoldov",
  //     degreeOfProtection: "1. stupeň",
  //     differentiationGroup: "915 01",
  //     hasExpectedFreeCapacity: false,
  //   },
  //   {
  //     id: "51",
  //     name: "Ústav na výkon väzby a Ústav na výkon trestu odňatia slobody Žilina",
  //     degreeOfProtection: "2. stupeň",
  //     differentiationGroup: "915 03",
  //     hasExpectedFreeCapacity: false,
  //   },
  // ];

  const listOfInstitutions: PlaceOfLocationQuestionInstitutionType[] =
    getUniqueCodesFromCiselnik(
      counters["/api/CisUstavZvjs/List"]?.records ?? []
    ) // GR should not be available to select -> filter it out
      ?.filter((code) => code !== CisUstavZvjs_GR_Kod && code)
      .map((code) => {
        return {
          id: code,
          // TODO load this data from somewhere
          degreeOfProtection: "",
          differentiationGroup: "",
          hasExpectedFreeCapacity: true,
        };
      }) ?? [];

  calcData[PlaceOfLocationQuestionConstants.LIST_OF_INSTITUTIONS] =
    listOfInstitutions;
};

/**
 * Selectors
 */

const getPlaceOfLocationQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { tuiz, getFallbackJazyk } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const answers = state.answers[question.id] as
      | NestedDropdownAnswerValueType[]
      | undefined;

    const selectedLabels: string[] = [];

    const options = state.userCalcData[
      PlaceOfLocationQuestionConstants.LIST_OF_INSTITUTIONS
    ].map(
      (
        item: PlaceOfLocationQuestionInstitutionType
      ): ZvjsNestedDropdownViewOption => {
        const isChecked =
          answers?.some(
            (x) =>
              x[PlaceOfLocationQuestionConstants.QUESTION_ANSWER_ID] === item.id
          ) ?? false;

        if (isChecked) {
          selectedLabels.push(
            getTranslationByLanguageCode(
              state.counters["/api/CisUstavZvjs/List"]?.records ?? [],
              getCiselnikJazyk(i18n.language),
              getFallbackJazyk(),
              item.id,
              "nazov"
            )
          );
        }

        return {
          label: getTranslationByLanguageCode(
            state.counters["/api/CisUstavZvjs/List"]?.records ?? [],
            getCiselnikJazyk(i18n.language),
            getFallbackJazyk(),
            item.id,
            "nazov"
          ),
          key: item.id,
          checked: isChecked,
        };
      }
    );

    return {
      id: question.id,
      title: tuiz(question.title),
      answerRequired: question.isRequired,
      options: {
        answerId: PlaceOfLocationQuestionConstants.QUESTION_ANSWER_ID,
        children: options,
      },
      selectedOptionsText: selectedLabels.join(","),
      selectedOptionsCount: selectedLabels.length,
    };
  });

export const getInstitutionWithId = (
  userCalcData: UserCalcData,
  id: string
): PlaceOfLocationQuestionInstitutionType | undefined => {
  const institutions = userCalcData[
    PlaceOfLocationQuestionConstants.LIST_OF_INSTITUTIONS
  ] as PlaceOfLocationQuestionInstitutionType[];

  for (const institution of institutions) {
    if (institution.id === id) {
      return institution;
    }
  }

  return undefined;
};

const displayDifferentDegreeOfProtectionOrDifferentiationGroupWarning404 = (
  location: number[]
) =>
  createSelector(selectSelf, (state: State): boolean => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const answers = state.answers[question.id];

    if (answers !== undefined) {
      if (Array.isArray(answers) && answers.length === 0) {
        return false;
      }
      for (const answer of answers as NestedDropdownAnswerValueType[]) {
        const institution = getInstitutionWithId(
          state.userCalcData,
          answer[PlaceOfLocationQuestionConstants.QUESTION_ANSWER_ID]
        );

        // TODO uncomment once integrations regarding institutions and client are prepared
        // if (
        //   institution?.degreeOfProtection !==
        //     state.userCalcData[
        //       PlaceOfLocationQuestionConstants.TRESTNOPRAVNE_UDAJE
        //     ].stupenStrazenia ||
        //   institution?.differentiationGroup !==
        //     state.userCalcData[
        //       PlaceOfLocationQuestionConstants.TRESTNOPRAVNE_UDAJE
        //     ].diferenciacnaSkupina
        // ) {
        //   return true;
        // }
      }
      return false;
    }

    return false;
  });

const displayNoExpectedFreeCapacityWarning404 = (location: number[]) =>
  createSelector(selectSelf, (state: State): boolean => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const answers = state.answers[question.id];

    if (answers !== undefined) {
      if (Array.isArray(answers) && answers.length === 0) {
        return false;
      }
      for (const answer of answers as NestedDropdownAnswerValueType[]) {
        const institution = getInstitutionWithId(
          state.userCalcData,
          answer[PlaceOfLocationQuestionConstants.QUESTION_ANSWER_ID]
        );

        if (institution?.hasExpectedFreeCapacity === false) {
          return true;
        }
      }
      return false;
    }

    return false;
  });


const displaySameRequestInNonTerminatedStateWarning = (location: number[]) =>
  createSelector(selectSelf, (state: State): boolean => {
    const questionControl = state.userCalcData["SC_KIO_051404_QUESTION_CONTROL"];
    if (questionControl === undefined || questionControl.porusenePodmienky === null){
      return false;
    }
    return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR036]);
  });

export const allSelectors = {
  getPlaceOfLocationQuestionDisplayData,
  displayDifferentDegreeOfProtectionOrDifferentiationGroupWarning404,
  displayNoExpectedFreeCapacityWarning404,
  displaySameRequestInNonTerminatedStateWarning,
};

/**
 * CustomSubmitAnswerData
 */
export const SC_KIO_051404_PlaceOfLocationQuestion_getCustomSubmitAnswerData = (
  answers: NestedDropdownAnswerValueType[],
  questionId: string
): {
  [key: string]: string[];
} => {
  const toReturn = [];
  for (const answer of answers) {
    toReturn.push(answer[PlaceOfLocationQuestionConstants.QUESTION_ANSWER_ID]);
  }
  return { [questionId]: toReturn };
};
