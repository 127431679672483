import React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import ClearIcon from "@mui/icons-material/Clear";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { useModal } from "../../../../../../store/context/modal-context";
import {
  ZvjsButton,
  ZvjsSelect,
  ZvjsTextField,
} from "../../../../../components";
import {
  getTranslationByLanguageCode,
  isSubmitting,
} from "../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../locales/i18n";
import i18n from "i18next";
import { Params, useNavigation } from "react-router-dom";
import { ActionResultTypes } from "../../../../../../router/ActionResult";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import ZvjsDateSelect from "../../../../../components/ZvjsDateSelect";
import {
  DetailZakladneUdajeKlientaData,
} from "../../../../../specs/swaggerTypes";
import {
  CisPohlavie_NEZISTENE_ID,
} from "../../../../../specs/countersValues";
import { CiselnikListResultType } from "../../../../../../store/context/dataApi/Data";
import {
  addNewOptionPersonReceivingPackage,
  RequestActionResult,
  RequestActionsType,
} from "../../../../Request";

interface AddNewPersonSubmitData {
  basicClientData: DetailZakladneUdajeKlientaData;
  formData: AddPersonFormData;
}

function isUnderage(dateOfBirth: string): boolean {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  const age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    return age - 1 < 18;
  }

  return age < 18;
}

export async function SC_KIO_050502_addNewPerson({
  params,
  data,
}: {
  params: Params;
  data: unknown;
}): Promise<RequestActionResult> {
  const { EOO_Post } = await API_Clients();

  const dataToCreateClosePerson = data as AddNewPersonSubmitData;

  console.log(dataToCreateClosePerson);

  const resultNovaFyzickaOsoba = await EOO_Post(
    "/api/FyzickaOsoba/CreateFyzickaOsoba",
    {
      body: {
        meno: dataToCreateClosePerson.formData.firstname,
        priezvisko: dataToCreateClosePerson.formData.surname,
        datumNarodenia: dataToCreateClosePerson.formData.dateOfBirth,
        pohlavieId: CisPohlavie_NEZISTENE_ID,
      },
    }
  );

  if (resultNovaFyzickaOsoba.error || !resultNovaFyzickaOsoba.response.ok) {
    if (
      resultNovaFyzickaOsoba.response.status === 503 &&
      resultNovaFyzickaOsoba.response.headers.get("X-Zvjs-Offline") ===
      "offline"
    ) {
      return {
        type: ActionResultTypes.ERROR,
        // TODO add translation
        message: "Akcia nie je na tablete podporovaná",
        action: RequestActionsType.SC_KIO_050502_ADD_NEW_PERSON,
      };
    }

    return {
      type: ActionResultTypes.ERROR,
      // TODO add translation
      message: "Osobu sa nepodarilo pridať",
      action: RequestActionsType.SC_KIO_050502_ADD_NEW_PERSON,
    };
  }

  const resultNovaAdresa = await EOO_Post("/api/Adresa/CreateAdresa", {
    body: {
      statNazov: dataToCreateClosePerson.formData.stat,
      krajNazov: dataToCreateClosePerson.formData.kraj,
      obecNazov: dataToCreateClosePerson.formData.okres,
      psc: dataToCreateClosePerson.formData.psc,
      ulica: dataToCreateClosePerson.formData.ulica,
      supisneCislo: dataToCreateClosePerson.formData.supisneCislo,
      orientacneCislo: dataToCreateClosePerson.formData.orientacneCislo,
    },
  });

  await addNewOptionPersonReceivingPackage(params.requestCode, {
    blizkaOsobaId: undefined,
    fyzickaOsobaId: resultNovaFyzickaOsoba.data.data?.fyzickaOsobaId ?? 0,
    firstname: resultNovaFyzickaOsoba.data.data?.meno,
    surname: resultNovaFyzickaOsoba.data.data?.priezvisko,
    relationId: dataToCreateClosePerson.formData.relation,
    isUnderage: isUnderage(dataToCreateClosePerson.formData.dateOfBirth),
    isPossibleToContact: true,
    inPrison: false,
    stat: dataToCreateClosePerson.formData.stat,
    kraj: dataToCreateClosePerson.formData.kraj,
    okres: dataToCreateClosePerson.formData.okres,
    obec: dataToCreateClosePerson.formData.obec,
    psc: dataToCreateClosePerson.formData.psc,
    ulica: dataToCreateClosePerson.formData.ulica,
    supisneCislo: dataToCreateClosePerson.formData.supisneCislo,
    orientacneCislo: dataToCreateClosePerson.formData.orientacneCislo,
  });

  return {
    type: ActionResultTypes.SUCCESS,
    action: RequestActionsType.SC_KIO_050502_ADD_NEW_PERSON,
  };
}

const generateSchema = (tuiz: (key: string) => string) =>
  yup
    .object({
      firstname: yup
        .string()
        .required(capitalize(tuiz("Napíšte meno")))
        .default(""), // todo add translation
      surname: yup
        .string()
        .required(capitalize(tuiz("Napíšte priezvisko")))
        .default(""), // todo add translation
      dateOfBirth: yup
        .string()
        .required(capitalize(tuiz("Napíšte dátum narodenia")))
        .default(""),
      relation: yup
        .number()
        // valid ids start from 0
        .min(0, capitalize(tuiz("Vyberte vzťah k osobe")))
        .default(-1),
      stat: yup
        .string()
        .required(capitalize(tuiz("Napíšte štát")))
        .default(""), // todo add translation
      kraj: yup
        .string()
        .required(capitalize(tuiz("Napíšte kraj")))
        .default(""), // todo add translation
      okres: yup
        .string()
        .required(capitalize(tuiz("Napíšte okres")))
        .default(""), // todo add translation
      obec: yup
        .string()
        .required(capitalize(tuiz("Napíšte obec")))
        .default(""), // todo add translation
      psc: yup
        .string()
        .required(capitalize(tuiz("Napíšte PSČ")))
        .default(""), // todo add translation
      ulica: yup
        .string()
        .required(capitalize(tuiz("Napíšte ulicu")))
        .default(""), // todo add translation
      supisneCislo: yup
        .string()
        .required(capitalize(tuiz("Napíšte súpisné číslo")))
        .default(""), // todo add translation
      orientacneCislo: yup
        .string()
        .required(capitalize(tuiz("Napíšte orientačné číslo")))
        .default(""), // todo add translation
    })
    .required();
type AddPersonFormData = yup.InferType<ReturnType<typeof generateSchema>>;

interface PersonRelation {
  name: string;
  surname: string;
  klientId: number;
  relation: number;
}

interface SC_KIO_050502_AddPersonReceivingPackageFormProps {
  onSubmit: (toSubmit: unknown) => void;
  basicClientData: DetailZakladneUdajeKlientaData;
  closePersonRelationCounter: CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"];
  newList?: PersonRelation[];
}

const SC_KIO_050502_AddPersonReceivingPackageForm: React.FC<
  SC_KIO_050502_AddPersonReceivingPackageFormProps
> = ({ onSubmit, basicClientData, closePersonRelationCounter, newList }) => {
  const { closeModal } = useModal();
  const { tuiz, getFallbackJazyk } = useUITranslation();
  const navigation = useNavigation();

  const schema = generateSchema(tuiz);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(undefined),
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (formData: AddPersonFormData) => {
    const toSubmit: AddNewPersonSubmitData = {
      formData: formData,
      basicClientData: basicClientData,
    };
    onSubmit(toSubmit);
  };

  return (
    <Stack width={"70vw"} maxHeight={"80vh"} overflow={"auto"}>
      {/* todo add translation */}
      <Typography variant={"h2"} mb={2}>
        {capitalize(tuiz("ziadost.ziadostOnavstevu.labelDoplnenieInejOsoby"))}
      </Typography>{" "}
      <form
        style={{ width: "20 rem" }}
        onSubmit={handleSubmit(handleOnSubmit)}
        // TODO think about how to make live validation work - low priority
        // onBlur={handleSubmit(dummyFunction)}
      >
        <Stack spacing={1}>
          {/*/!* todo add translation *!/*/}
          <ZvjsTextField
            label={capitalize(tuiz("Meno"))}
            register={{ ...register("firstname") }}
            error={errors.firstname !== undefined}
            validationErrorMessage={errors.firstname?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Priezvisko"))}
            register={{ ...register("surname") }}
            error={errors.surname !== undefined}
            validationErrorMessage={errors.surname?.message}
          />
          <ZvjsDateSelect
            text={capitalize(tuiz("Dátum narodenia"))}
            register={{ ...register("dateOfBirth") }}
            error={errors.dateOfBirth !== undefined}
            validationErrorMessage={errors.dateOfBirth?.message}
          />
          <ZvjsSelect
            text={capitalize(tuiz("Vzťah k osobe"))}
            register={{ ...register("relation") }}
            items={
              closePersonRelationCounter?.records
                ?.filter((relation) => relation.id)
                .map((relation) => {
                  if (relation.id && relation.kod) {
                    return {
                      value: relation.id,
                      text: getTranslationByLanguageCode(
                        closePersonRelationCounter?.records ?? [],
                        getCiselnikJazyk(i18n.language),
                        getFallbackJazyk(),
                        relation.kod,
                        "nazov"
                      ),
                    };
                  }
                  throw new Error();
                }) ?? []
            }
            uncontrolled={{ defaultValue: "" }}
            error={errors.relation !== undefined}
            validationErrorMessage={errors.relation?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Štát"))}
            register={{ ...register("stat") }}
            error={errors.stat !== undefined}
            validationErrorMessage={errors.stat?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Kraj"))}
            register={{ ...register("kraj") }}
            error={errors.kraj !== undefined}
            validationErrorMessage={errors.kraj?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Okres"))}
            register={{ ...register("okres") }}
            error={errors.okres !== undefined}
            validationErrorMessage={errors.okres?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Obec"))}
            register={{ ...register("obec") }}
            error={errors.obec !== undefined}
            validationErrorMessage={errors.obec?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("PSČ"))}
            register={{ ...register("psc") }}
            error={errors.psc !== undefined}
            validationErrorMessage={errors.psc?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Ulica"))}
            register={{ ...register("ulica") }}
            error={errors.ulica !== undefined}
            validationErrorMessage={errors.ulica?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Súpisné číslo"))}
            register={{ ...register("supisneCislo") }}
            error={errors.supisneCislo !== undefined}
            validationErrorMessage={errors.supisneCislo?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Orientačné číslo"))}
            register={{ ...register("orientacneCislo") }}
            error={errors.orientacneCislo !== undefined}
            validationErrorMessage={errors.orientacneCislo?.message}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} mt={3} justifyContent={"center"}>
          <ZvjsButton
            type={"submit"}
            zvjsVariant={"secondaryAction"}
            disabled={
              Object.keys(errors).length > 0 || isSubmitting(navigation)
            }
            text={capitalize(tuiz("Pridať osobu"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            disabled={isSubmitting(navigation)}
            text={capitalize(tuiz("Zrušiť"))}
            startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
            onClick={closeModal}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default SC_KIO_050502_AddPersonReceivingPackageForm;
