import { UserCalcData, ZvjsAnswers } from "../../../../redux/model";
import { SC_KIO_05050_does_user_already_use_device } from "../validations/custom";
import {
  CisDovodPodaniaZiadostiElektrospotrebic_Revizia_Kod,
  CisDovodZiadostiSprchovanie_Ine_Dovody_Kod,
  CisDovodZiadostiSprchovanie_Pracovne_Zaradenie_Kod,
  CisDovodZiadostiSprchovanie_Zdravotne_Dovody_Kod,
  CisDruhBalik_BalikOdevObuv_ID,
  CisDruhBalik_BalikVeciOsobnejPotreby_ID,
  CisDruhBalik_IneVeciOsobnejPotreby_ID,
  CisDruhBalik_Spodky_ID,
  CisDruhBalik_Topanky_ID,
  CisDruhBalik_Tricko_ID,
  CisDruhBalik_ZimnaBundaCiapka_ID,
  CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod,
  CisFormaRealizacieNavstevy_Fyzicka_Navsteva_Kod,
  CisPohlavie_MUZ_ID,
  CisPohlavie_ZENA_ID,
  CisStavZiadosti_Poziadavka_O_Stanovisko_ID,
  CisStavZiadosti_Schvalena_ID,
  CisStavZiadosti_Spracovane_Stanovisko_ID,
  CisStavZiadosti_Zaevidovana_ID,
  CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod,
  CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod,
  CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod,
  CisTypZiadosti_SC_KIO_050101_ID,
  CisTypZiadosti_SC_KIO_050204_ID,
  CisTypZiadosti_SC_KIO_050501_ID,
  CisTypZiadosti_SC_KIO_050802_ID,
  CisTypZiadosti_SC_KIO_051001_ID,
  EooCiselnikTypKontaktu_Mobil_ID,
  EooCiselnikTypKontaktu_Skype_ID,
  EooCiselnikTypKontaktu_Telefon_ID,
} from "../../../../../specs/countersValues";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../../router/LoaderError";
import {
  CreateZiadostRequestBody,
  FyzickaOsobaKontakt,
  KlientNaPracovisku,
} from "../../../../../specs/swaggerTypes";
import { getIsFemale } from "./reusable";
import { isAnyPorusenaPodmienka, isEmptyArray } from "../../../../../../utils/helpers";
import { MAX_NUMBER_OF_ITEMS } from "../../../../../../store/context/dataApi/Data";
import {
  FORM_OF_VISIT_QUESTION_ID,
  INVITED_PEOPLE_LIST_QUESTION_ID,
  MONTH_OF_VISIT_QUESTION_ID,
  SC_KIO_050101_can_request_visit_all_checks,
  SC_KIO_050101_is_any_selected_person_in_prison,
} from "../../../../requestTemplates/SC_KIO_050101_Ziadost o navstevu";
import { isAccused, isSentenced } from "../../../../../../utils/dataFetchers";
import { capitalize } from "@mui/material/utils";
import { components as szoo_v1_components } from "../../../../../../api/types/szoo_v1";
import { TypZiadostiEnum } from "../TypZiadostiEnum";
import { PodmienkaZiadostiEnum } from "../PodmienkaZiadostiEnum";
type DruhBalikaEnum =
  szoo_v1_components["schemas"]["Zvjs.Oprap.Szoo.Dto.Enums.DruhBalikaEnum"];

export enum CustomCalculationType {
  SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_ONE = "SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_ONE",
  SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_OTHER = "SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_OTHER",
  SC_KIO_050301_QUESTION_CONTROL = "SC_KIO_050301_QUESTION_CONTROL",
  SC_KIO_050701_QUESTION_CONTROL = "SC_KIO_050701_QUESTION_CONTROL",
  SC_KIO_051402_QUESTION_CONTROL = "SC_KIO_051402_QUESTION_CONTROL",
  SC_KIO_051403_QUESTION_CONTROL = "SC_KIO_051403_QUESTION_CONTROL",
  SC_KIO_051404_QUESTION_CONTROL = "SC_KIO_051404_QUESTION_CONTROL",
  SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE = "SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
  SC_KIO_051404_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE = "SC_KIO_051404_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
  SC_KIO_050302_AVAILABLE_USER_PACKAGE_TYPE_OPTIONS = "SC_KIO_050302_AVAILABLE_USER_PACKAGE_TYPE_OPTIONS",
  SC_KIO_050302_SHOULD_DISPLAY_PERSONAL_BELONGINGS_PACKAGE_OPTION = "SC_KIO_050302_SHOULD_DISPLAY_PERSONAL_BELONGINGS_PACKAGE_OPTION",
  SC_KIO_050302_SHOULD_DISPLAY_OTHER_PERSONAL_BELONGINGS_OPTION = "SC_KIO_050302_SHOULD_DISPLAY_OTHER_PERSONAL_BELONGINGS_OPTION",
  SC_KIO_050302_SHOULD_DISPLAY_CLOTHING_PACKAGE_OPTION = "SC_KIO_050302_SHOULD_DISPLAY_CLOTHING_PACKAGE_OPTION",
  SC_KIO_050302_SHOULD_DISPLAY_SHIRT_OPTION = "SC_KIO_050302_SHOULD_DISPLAY_SHIRT_OPTION",
  SC_KIO_050302_SHOULD_DISPLAY_SHOES_OPTION = "SC_KIO_050302_SHOULD_DISPLAY_SHOES_OPTION",
  SC_KIO_050302_SHOULD_DISPLAY_UNDERPANTS_OPTION = "SC_KIO_050302_SHOULD_DISPLAY_UNDERPANTS_OPTION",
  SC_KIO_050302_SHOULD_DISPLAY_WINTER_JACKET_HAT_OPTION = "SC_KIO_050302_SHOULD_DISPLAY_WINTER_JACKET_HAT_OPTION",
  SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST = "SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST",
  SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING = "SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING",
  SC_KIO_050501_HAS_REFUSED_NEW_DEVICE_OF_TYPE_OTHER_IN_PAST_3_MONTHS = "SC_KIO_050501_HAS_REFUSED_NEW_DEVICE_OF_TYPE_OTHER_IN_PAST_3_MONTHS",
  SC_KIO_050501_CAN_REQUEST_DEVICE_REVISION_CONTROL = "SC_KIO_050501_CAN_REQUEST_DEVICE_REVISION_CONTROL",
  SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION = "SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION",
  SC_KIO_051201_SHOULD_DISPLAY_RAMADAN_QUESTION = "SC_KIO_051201_SHOULD_DISPLAY_RAMADAN_QUESTION",
  SC_KIO_050802_FOOD_ALREADY_REQUESTED = "SC_KIO_050802_FOOD_ALREADY_REQUESTED",
  SC_KIO_050802_CLOTHING_ALREADY_REQUESTED = "SC_KIO_050802_CLOTHING_ALREADY_REQUESTED",
  SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED = "SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED",
  SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING = "SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING",
  SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE = "SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE",
  SC_KIO_050301_IS_ALLOWED_TO_REQUEST_CORRESPONDENCE = "SC_KIO_050301_IS_ALLOWED_TO_REQUEST_CORRESPONDENCE",
  SC_KIO_051403_SHOULD_SHOW_PRIORITY_QUESTION = "SC_KIO_051403_SHOULD_SHOW_PRIORITY_QUESTION",
  SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST = "SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST",
  SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_JOB = "SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_JOB",
  SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_HEALTH = "SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_HEALTH",
  SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_OTHER = "SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_OTHER",
  SC_KIO_051003_CLIENT_AVAILABLE_BALANCE = "SC_KIO_051003_CLIENT_AVAILABLE_BALANCE",
  SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST = "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST",
  SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM = "SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM",
  SC_KIO_051102_CIVILIAN_CLOTHES_LIST = "SC_KIO_051102_CIVILIAN_CLOTHES_LIST",
  SC_KIO_050101_CLOSE_PEOPLE_LIST = "SC_KIO_050101_CLOSE_PEOPLE_LIST",
  SC_KIO_050101_VISIT_REQUESTS_FOR_CURRENT_AND_NEXT_MONTH_LIST = "SC_KIO_050101_VISIT_REQUESTS_FOR_CURRENT_AND_NEXT_MONTH_LIST",
  SC_KIO_050101_CAN_REQUEST_EXTRAORDINARY_VISIT_ONLY = "SC_KIO_050101_CAN_REQUEST_EXTRAORDINARY_VISIT_ONLY",
  SC_KIO_050101_IS_PHYSICAL_VISIT_AVAILABLE = "SC_KIO_050101_IS_PHYSICAL_VISIT_AVAILABLE",
  SC_KIO_050101_IS_VIDEO_CALL_VISIT_AVAILABLE = "SC_KIO_050101_IS_VIDEO_CALL_VISIT_AVAILABLE",
  SC_KIO_050101_IS_UNDERAGE_KIDS_VIDEO_CALL_VISIT_AVAILABLE = "SC_KIO_050101_IS_UNDERAGE_KIDS_VIDEO_CALL_VISIT_AVAILABLE",
  SC_KIO_050101_SHOULD_DISPLAY_INVITED_PERSON_IN_PRISON_WARNING = "SC_KIO_050101_SHOULD_DISPLAY_INVITED_PERSON_IN_PRISON_WARNING",
  SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS = "SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS",
  SC_KIO_050204_REQUESTS_THIS_MONTH_LIST = "SC_KIO_050204_REQUESTS_THIS_MONTH_LIST",
  SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON = "SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON",
  SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED = "SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED",
  SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_DESIGNATED_PERSON = "SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_DESIGNATED_PERSON",
  SC_KIO_050204_DOES_CLIENT_HAVE_POSSIBLE_WITHDRAWAL_AVAILABLE = "SC_KIO_050204_DOES_CLIENT_HAVE_POSSIBLE_WITHDRAWAL_AVAILABLE",
  SC_KIO_050204_DOES_CLIENT_HAVE_AVAILABLE_ACCOUNT_AVAILABLE = "SC_KIO_050204_DOES_CLIENT_HAVE_AVAILABLE_ACCOUNT_AVAILABLE",
  SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE = "SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
  SC_KIO_050701_IS_ANY_CLOSE_PERSON_IN_PRISON = "SC_KIO_050701_IS_ANY_CLOSE_PERSON_IN_PRISON",
  SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST = "SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST",
  SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST = "SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST",
}

// group one includes women with kids, youngsters etc.
const SC_KIO_051102_is_user_sentenced_and_group_one = () => {
  // TODO replace with actual logic
  return true;
};

const SC_KIO_051102_is_user_sentenced_and_group_other = () => {
  // TODO replace with actual logic
  return true;
};

const SC_KIO_050501_get_all_user_electric_devices_list = async (): Promise<
  SC_KIO_050501_ElectricDevice[] | undefined
> => {
  const { EDPOO_Post, CIS_Post, SZOO_Post, HASURA_Get } = await API_Clients();

  // fetch user electric devices
  const userDevicesResponse = await EDPOO_Post(
    "/api/OsobneVeci/ListElektrospotrebic",
    {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }
  );

  if (userDevicesResponse.error || !userDevicesResponse.response.ok) {
    console.error("Failed to fetch user devices list (ListElektrospotrebic)");
    throw new LoaderError();
  }

  const userDevices = userDevicesResponse.data;

  if (!isEmptyArray(userDevices.records)) {
    const devicesCounter = await CIS_Post(
      "/api/CisDruhElektrospotrebica/List",
      {
        body: {
          filters: [
            {
              aktualny: true,
              platny: true,
            },
          ],
          paging: {
            currentPage: 1,
            recordsPerPage: MAX_NUMBER_OF_ITEMS,
          },
        },
      }
    );

    if (
      devicesCounter.error ||
      !devicesCounter.response.ok ||
      !devicesCounter.data?.records
    ) {
      console.error(
        "Failed to fetch devices counter (CisDruhElektrospotrebica)"
      );
      throw new LoaderError();
    }

    // fetch 050501 active requests (datumUkoncenia is null) for the logged-in user
    const requestsSzooResponse = await SZOO_Post(
      "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
      {
        body: {
          klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
          podanieUstavId: {
            eq: Number(localStorage.getItem("klientUstavId")),
          },
          typZiadostiId: { eq: CisTypZiadosti_SC_KIO_050501_ID },
          datumUkoncenia: { isNull: true },
        },
      }
    );

    if (requestsSzooResponse.error || !requestsSzooResponse.response.ok) {
      console.error("Failed to fetch client requests from szoo");
      throw new LoaderError();
    }

    // collect a list of devices which revision request is in active state (is ongoing)
    const ongoingRevisionRequest: { [deviceId: string]: boolean } = {};
    for (const requestSzoo of requestsSzooResponse.data ?? []) {
      if (requestSzoo.cisloZiadosti) {
        // fetch request body for current request from Hasura
        const responseHasura = await HASURA_Get(
          "/api/rest/v1/kiosk-ziadost-by-id/{id}",
          {
            params: {
              path: {
                id: requestSzoo.cisloZiadosti,
              },
            },
          }
        );

        if (
          responseHasura.error ||
          !responseHasura.response.ok ||
          !responseHasura.data.kioskZiadost?.body
        ) {
          console.error("Failed to fetch request body from Hasura");
          throw new LoaderError();
        }

        const requestBody: CreateZiadostRequestBody = responseHasura.data
          .kioskZiadost.body as CreateZiadostRequestBody;

        if (
          requestBody.dovodPodaniaZiadosti ===
            CisDovodPodaniaZiadostiElektrospotrebic_Revizia_Kod &&
          requestBody.evidovanyElektrickySpotrebicId
        ) {
          ongoingRevisionRequest[requestBody.evidovanyElektrickySpotrebicId] =
            true;
        }
      }
    }

    return (
      (userDevices.records ?? [])
        // filter out devices with unknown druhElektrospotrebicaId (druhElektrospotrebicaId not present in devicesCounter)
        .filter(
          (device) =>
            devicesCounter.data.records?.find(
              (counterItem) =>
                // TODO remove when swagger is correct
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                counterItem.id === device.druhElektrospotrebicaId
            ) !== undefined && device.id
        )
        .map((device): SC_KIO_050501_ElectricDevice => {
          const deviceCode = devicesCounter.data.records?.find(
            (counterItem) =>
              // TODO remove when swagger is correct
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              counterItem.id === device.druhElektrospotrebicaId
          )?.kod;
          if (deviceCode === undefined || deviceCode === null || !device.id) {
            // you should never end up here as items falling to this path should be filtered out in filter above
            throw new LoaderError();
          }

          return {
            id: device.id,
            typeCode: deviceCode,
            // TODO remove when swagger is correct
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            controlValidUntil: device.datumPlatnostiRevizie,
            ongoingInspection: ongoingRevisionRequest[device.id] ?? false,
            // TODO remove when swagger is correct
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            nameOfOtherDevice: device.nazovInehoElektrospotrebica,
          };
        })
    );
  } else {
    // if a user does not have any electric devices return an empty array
    return [];
  }
};

const SC_KIO_050501_display_user_already_use_device_warning = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (
    answers["druhElektrickehoSpotrebica"] !==
      CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod &&
    SC_KIO_05050_does_user_already_use_device(
      answers["druhElektrickehoSpotrebica"] as string,
      userCalcData["SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST"]
    )
  ) {
    return true;
  }

  return false;
};

const SC_KIO_050501_has_refused_new_device_of_type_other_in_past_3_months =
  async ({
    userCalcData,
    answers,
  }: {
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
  }): Promise<boolean> => {
    const questionControl = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z080101 });

    if (questionControl === undefined || questionControl.porusenePodmienky === null){
      return false;
    }
    return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR044]);
  };

const SC_KIO_050501_can_request_device_revision_control = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  const userDevices =
    userCalcData.SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST as
      | SC_KIO_050501_ElectricDevice[]
      | undefined;

  if (userDevices) {
    const today = new Date();
    for (const userDevice of userDevices) {
      // if a user has any device with expired control validity
      // or with controlValidUntil not set, then the user can request device revision control
      if (
        !userDevice.controlValidUntil ||
        new Date(userDevice.controlValidUntil) < today
      ) {
        return true;
      }
    }
  }

  return false;
};

const SC_KIO_051201_should_display_ramadan_question = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with real values (instead of strings)
  if (
    userCalcData["CURRENT_DIET_TYPE"] === "Strava s vylúčením mäsa" ||
    userCalcData["CURRENT_DIET_TYPE"] === "Strava s vylúčením bravčového mäsa"
  ) {
    return true;
  }

  return false;
};

const SC_KIO_050802_food_already_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    userCalcData[
      CustomCalculationType.SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS
    ] as SC_KIO_050802_AlreadyRequestedItemsStatus
  ).ziadostOStravu;
};

const SC_KIO_050802_clothing_already_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    userCalcData[
      CustomCalculationType.SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS
    ] as SC_KIO_050802_AlreadyRequestedItemsStatus
  ).ziadamOPoskytnutieOdevuObuvi;
};

const SC_KIO_050802_travel_expenses_already_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    userCalcData[
      CustomCalculationType.SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS
    ] as SC_KIO_050802_AlreadyRequestedItemsStatus
  ).cestovneCestovnyListok;
};

const SC_KIO_050603_is_user_illiterate_or_without_primary_schooling = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return true;
};

const SC_KIO_050603_is_user_not_illiterate_or_without_primary_schooling = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return !userCalcData[
    "SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING"
  ];
};

const SC_KIO_050301_can_request_private_correspondence = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControl = userCalcData["SC_KIO_050301_QUESTION_CONTROL"];
  if (questionControl === undefined || questionControl.porusenePodmienky === null) { return true; }
  return !isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR026]);
};

const SC_KIO_050301_is_allowed_to_request_correspondence = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControl = userCalcData["SC_KIO_050301_QUESTION_CONTROL"];
  if (questionControl === undefined || questionControl.porusenePodmienky === null) { return true; }
  return !isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR025]) ||
      isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR024]);
};

const SC_KIO_051403_should_show_priority_question = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // if exactly 2 institutions are selected in the first question display priority question
  if (
    Array.isArray(answers["miestoPozadovanehoPremiestnenia"]) &&
    answers["miestoPozadovanehoPremiestnenia"].length === 2
  ) {
    return true;
  }
  return false;
};

const SC_KIO_051403_is_client_allowed_to_send_request = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControl = userCalcData["SC_KIO_051403_QUESTION_CONTROL"];
  if (questionControl === undefined || questionControl.porusenePodmienky === null){
    return false;
  }
  return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR047, PodmienkaZiadostiEnum.BR049])
};

const SC_KIO_051001_can_request_shower_reason_job = async () => {
  const { EZOO_Post, SZOO_Post, HASURA_Get } = await API_Clients();

  const clientWorkplacesResponse = await EZOO_Post(
    "/api/Integracie/ListKlientNaPracovisku",
    {
      body: {
        filters: [
          {
            klientObjectId: localStorage.getItem("klientObjectId"),
          },
        ],
      },
    }
  );

  if (clientWorkplacesResponse.error || !clientWorkplacesResponse.response.ok) {
    console.error("Failed to fetch client workplaces from SZOO");
    throw new LoaderError();
  }

  if (isEmptyArray(clientWorkplacesResponse.data.records)) {
    // if user has no job he cannot choose job reason in request
    return false;
  }

  function getGreatestDatumZaradenia(
    clientWorkplaces: KlientNaPracovisku[]
  ): Date | null {
    const today = new Date();
    let greatestDate: Date | null = null;

    for (const record of clientWorkplaces) {
      if (record.datumZaradenia) {
        const datumZaradenia = new Date(record.datumZaradenia);
        const datumVyradenia = record.datumVyradenia
          ? new Date(record.datumVyradenia)
          : null;

        if (datumVyradenia === null || datumVyradenia > today) {
          if (greatestDate === null || datumZaradenia > greatestDate) {
            greatestDate = datumZaradenia;
          }
        }
      }
    }

    return greatestDate;
  }

  const greatestDatumZaradenia = getGreatestDatumZaradenia(
    clientWorkplacesResponse.data?.records ?? []
  );

  if (greatestDatumZaradenia) {
    const requestsSzooResponse = await SZOO_Post(
      "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
      {
        body: {
          klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
          podanieUstavId: {
            eq: Number(localStorage.getItem("klientUstavId")),
          },
          typZiadostiId: { eq: CisTypZiadosti_SC_KIO_051001_ID },
          datumPodaniaZiadosti: {
            gt: greatestDatumZaradenia.toISOString(),
          },
        },
      }
    );

    if (requestsSzooResponse.error || !requestsSzooResponse.response.ok) {
      console.error("Failed to fetch client requests from szoo");
      throw new LoaderError();
    }

    for (const requestSzoo of requestsSzooResponse.data ?? []) {
      if (requestSzoo.cisloZiadosti) {
        // fetch request body for current request from Hasura
        const responseHasura = await HASURA_Get(
          "/api/rest/v1/kiosk-ziadost-by-id/{id}",
          {
            params: {
              path: {
                id: requestSzoo.cisloZiadosti,
              },
            },
          }
        );

        if (
          responseHasura.error ||
          !responseHasura.response.ok ||
          !responseHasura.data.kioskZiadost?.body
        ) {
          console.error("Failed to fetch request body from Hasura");
          throw new LoaderError();
        }

        const requestBody: CreateZiadostRequestBody = responseHasura.data
          .kioskZiadost.body as CreateZiadostRequestBody;

        if (
          requestBody.dovodZiadosti ===
          CisDovodZiadostiSprchovanie_Pracovne_Zaradenie_Kod
        ) {
          // If the client requested more frequent showers due to being assigned to work after the last change in his job assignment,
          // then this option should not be displayed to him
          return false;
        }
      }
    }
  } else {
    // if there is no greatestDatumZaradenia then the client does not have any active job assigment => do not display this option to him
    return false;
  }

  return true;
};

const SC_KIO_051001_can_request_shower_reason_health = async () => {
  const { SZOO_Post, HASURA_Get } = await API_Clients();

  const today = new Date();

  const requestsSzooResponse = await SZOO_Post(
    "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
    {
      body: {
        klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
        podanieUstavId: {
          eq: Number(localStorage.getItem("klientUstavId")),
        },
        typZiadostiId: { eq: CisTypZiadosti_SC_KIO_051001_ID },
        datumPodaniaZiadosti: {
          gt: new Date(
            today.getFullYear(),
            today.getMonth() - 3,
            today.getDate()
          ).toISOString(),
        },
      },
    }
  );

  if (requestsSzooResponse.error || !requestsSzooResponse.response.ok) {
    console.error("Failed to fetch client requests from szoo");
    throw new LoaderError();
  }

  for (const requestSzoo of requestsSzooResponse.data ?? []) {
    if (requestSzoo.cisloZiadosti) {
      // fetch request body for current request from Hasura
      const responseHasura = await HASURA_Get(
        "/api/rest/v1/kiosk-ziadost-by-id/{id}",
        {
          params: {
            path: {
              id: requestSzoo.cisloZiadosti,
            },
          },
        }
      );

      if (
        responseHasura.error ||
        !responseHasura.response.ok ||
        !responseHasura.data.kioskZiadost?.body
      ) {
        console.error("Failed to fetch request body from Hasura");
        throw new LoaderError();
      }

      const requestBody: CreateZiadostRequestBody = responseHasura.data
        .kioskZiadost.body as CreateZiadostRequestBody;

      if (
        requestBody.dovodZiadosti ===
        CisDovodZiadostiSprchovanie_Zdravotne_Dovody_Kod
      ) {
        return false;
      }
    }
  }

  return true;
};

const SC_KIO_051001_can_request_shower_reason_other = async () => {
  const { SZOO_Post, HASURA_Get } = await API_Clients();

  const today = new Date();

  const requestsSzooResponse = await SZOO_Post(
    "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
    {
      body: {
        klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
        podanieUstavId: {
          eq: Number(localStorage.getItem("klientUstavId")),
        },
        typZiadostiId: { eq: CisTypZiadosti_SC_KIO_051001_ID },
        datumPodaniaZiadosti: {
          gt: new Date(
            today.getFullYear(),
            today.getMonth() - 3,
            today.getDate()
          ).toISOString(),
        },
      },
    }
  );

  if (requestsSzooResponse.error || !requestsSzooResponse.response.ok) {
    console.error("Failed to fetch client requests from szoo");
    throw new LoaderError();
  }

  for (const requestSzoo of requestsSzooResponse.data ?? []) {
    if (requestSzoo.cisloZiadosti) {
      // fetch request body for current request from Hasura
      const responseHasura = await HASURA_Get(
        "/api/rest/v1/kiosk-ziadost-by-id/{id}",
        {
          params: {
            path: {
              id: requestSzoo.cisloZiadosti,
            },
          },
        }
      );

      if (
        responseHasura.error ||
        !responseHasura.response.ok ||
        !responseHasura.data.kioskZiadost?.body
      ) {
        console.error("Failed to fetch request body from Hasura");
        throw new LoaderError();
      }

      const requestBody: CreateZiadostRequestBody = responseHasura.data
        .kioskZiadost.body as CreateZiadostRequestBody;

      if (
        requestBody.dovodZiadosti === CisDovodZiadostiSprchovanie_Ine_Dovody_Kod
      ) {
        return false;
      }
    }
  }

  return true;
};

const SC_KIO_051003_client_available_balance = () => {
  // TODO replace with actual logic
  return 30;
};

const SC_KIO_051003_client_personal_hygiene_needs_list = async (): Promise<
  PersonalHygieneItem[]
> => {
  const { HASURA_Get, EDPOO_Post } = await API_Clients();

  const response = await Promise.all([
    HASURA_Get("/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-a"),
    HASURA_Get("/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-norma-a"),
    getIsFemale(),
    // alternatively use hasura api api/rest/v1/get-skladova-polozka-dpoh
    EDPOO_Post("/api/SkladDpoh/ListStavSkladu", {
      // body: {
      //   filters: [
      //     {
      //       ustavZvjsId:
      //         Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ??
      //         NaN,
      //     },
      //   ],
      // },
    }),
  ]);

  if (
    response[0].error ||
    !response[0].response.ok ||
    response[1].error ||
    !response[1].response.ok ||
    response[3].error ||
    !response[3].response.ok
  ) {
    throw new LoaderError();
  }

  const isFemale = response[2];

  const dalsiePotrebyOsobnejHygienyNormaA =
    response[1].data.dalsiePotrebyOsobnejHygienyNormaAView?.filter(
      (item) =>
        item.aktualny &&
        item.platny &&
        item.kod &&
        // filter items only for actual gender of user
        (isFemale
          ? item.pohlavieId === CisPohlavie_ZENA_ID
          : item.pohlavieId === CisPohlavie_MUZ_ID)
    );

  const dalsiePotrebyOsobnejHygienyA =
    response[0].data.dalsiePotrebyOsobnejHygienyAView?.filter(
      (item) =>
        item.aktualny &&
        item.platny &&
        item.kod &&
        item.dalsiePotrebyOsobnejHygienyId &&
        // via this check, you filter out items which are only for one gender (shaving items for men)
        dalsiePotrebyOsobnejHygienyNormaA?.some(
          (itemNorma) =>
            itemNorma.dalsiePotrebyOsobnejHygienyId ===
            item.dalsiePotrebyOsobnejHygienyId
        )
    );

  const listSkladovaPolozka = response[3].data.records;

  // TODO replace with sorting via BE call
  // consider the newest records primarily
  listSkladovaPolozka?.sort(
    (l1, l2) =>
      new Date(l2.datum ?? "").getTime() - new Date(l1.datum ?? "").getTime()
  );

  return (
    dalsiePotrebyOsobnejHygienyA?.map((item) => {
      if (!item.kod || !item.dalsiePotrebyOsobnejHygienyId) {
        // you should never und up here, ensured by the filter above
        throw new Error();
      }
      return {
        typeCode: item.kod,
        // TODO replace with value from counter (pass only typeId here and determine translation from counter)
        typeName: item.nazov ?? "",
        consumptionStandardInMonths:
          dalsiePotrebyOsobnejHygienyNormaA?.find(
            (itemNorma) =>
              itemNorma.dalsiePotrebyOsobnejHygienyId ===
              item.dalsiePotrebyOsobnejHygienyId
          )?.intervalVymenyMes ?? NaN,
        estimatedPrice:
          listSkladovaPolozka?.find(
            (itemSkladovaPolozka) =>
              itemSkladovaPolozka.tovarId === item.dalsiePotrebyOsobnejHygienyId
          )?.cenaZaJednotkuVEuro ?? 0,
        // TODO retrieve real value from edpoo/hasura endpoints
        dateOfLastIssue: "2023-10-25T00:00:00.000Z",
      };
    }) ?? []
  );
};

const SC_KIO_051003_is_allowed_to_request_personal_hygiene_item = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (userCalcData["SHOPPED_LAST_CALENDAR_MONTH"] === true) {
    return false;
  }

  let estimatedPriceTotalSum = 0;
  for (const item of userCalcData[
    "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST"
  ]) {
    estimatedPriceTotalSum += item.estimatedPrice;
  }

  if (userCalcData["AVAILABLE_ACCOUNT_AMOUNT"] >= estimatedPriceTotalSum) {
    return false;
  }

  return true;
};

const SC_KIO_051102_civilian_clothes_list = async (): Promise<
  CivilianClothes[]
> => {
  const { EDPOO_Post, SHARE_INT_Post } = await API_Clients();

  const data1Response = await EDPOO_Post("/api/OsobneVeci/ListOsobnaVec", {
    body: {
      filters: [
        {
          id: Number.parseInt(localStorage.getItem("klientId") ?? ""),
          ustavZvjsId: Number.parseInt(
            localStorage.getItem("klientUstavId") ?? ""
          ),
        },
      ],
    },
  });

  const data2Response = await SHARE_INT_Post(
    "/api/interfaces/Cis/ListSzooCisDruhCivilnychVeciA",
    {
      body: {},
    }
  );

  const data1Records = data1Response?.data?.records || [];
  const data1: { typVeciId: number }[] = data1Records
    .filter((item) => item.id !== null && item.id !== undefined)
    .map((item) => ({
      typVeciId: item.id as number,
    }));

  const data2Raw = data2Response.data || [];
  const data2: { typVeciId: number; nazov: string; maxPocet: number }[] =
    data2Raw
      .filter(
        (item) =>
          item.szooCisDruhCivilnychVeciId !== null &&
          item.szooCisDruhCivilnychVeciId !== undefined &&
          item.nazov !== null &&
          item.maxPocet !== null
      )
      .map((item) => ({
        typVeciId: item.szooCisDruhCivilnychVeciId as number,
        nazov: item.nazov as string,
        maxPocet: item.maxPocet as number,
      }));

  const finalList: CivilianClothes[] = [];
  const itemTracker: Record<number, CivilianClothes> = {};

  for (const item1 of data1) {
    const typVeciId = item1.typVeciId;
    let existingItem = itemTracker[typVeciId];
    if (!existingItem) {
      const matchingData2 = data2.find(
        (item2) => item2.typVeciId === typVeciId
      );

      if (matchingData2) {
        const newItem: CivilianClothes = {
          civilianClothesType: String(typVeciId),
          civilianClothesName: capitalize(matchingData2.nazov),
          countOfCurrentlyRegisteredItems: 1,
          maxCountOfRegisteredItems: matchingData2.maxPocet,
          estimatedWashingCostPerItem: 10,
          clientMethodOfEnsuringHygienicSafety: "RUCNE",
        };

        itemTracker[typVeciId] = newItem;
        finalList.push(newItem);
        existingItem = newItem;
      }
    } else {
      // If the item exists, update the count
      if (typeof existingItem.countOfCurrentlyRegisteredItems === "number") {
        existingItem.countOfCurrentlyRegisteredItems += 1;
      }
    }
  }

  return finalList;
};
// "RUCNE" "PRACOV" "OBMENA"

const SC_KIO_050701_pravny_zastupcovia_klienta_list = async (): Promise<SC_KIO_050701_PravnyZastupca[]> => {
  const { EOO_Post, SHARE_INT_Post } = await API_Clients();

  const response = await EOO_Post("/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList", {
    body: {
      filters: [
        {
          klientId: Number(localStorage.getItem("klientId")),
          ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      ],
      paging: {
        currentPage: 1,
        recordsPerPage: 999999,
      },
    },
  });

  const getCallContact = async (
    physicalPersonId: number,
    contactTypeId: number
  ) => {
    const personContactsResponse = await EOO_Post("/api/FyzickaOsoba/ListKontakt", {
      body: { filters: [physicalPersonId] },
    });

    if (personContactsResponse.error || !personContactsResponse.response.ok) {
      throw new LoaderError();
    }

    const now = new Date();
    return personContactsResponse.data.records?.find((contact) => {
      return (
        contact.primarny === true &&
        contact.typId === contactTypeId &&
        contact.platnostOd &&
        new Date(contact.platnostOd) < now &&
        (!contact.platnostDo ||
          (contact.platnostDo && new Date(contact.platnostDo) > now))
      );
    });
  };

  const getOsobaSPovolenimNaTelefonovanie = async (
    physicalPersonId: number,
  ) => {
    const personContactsResponse = await SHARE_INT_Post("/api/interfaces/Epvvvt/ListOsobaSPovolenimNaTelefonovanie", {
      body: {
        pravnyZastupcaKlientaId: { eq: physicalPersonId },
        klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
      },
    });

    if (personContactsResponse.error || !personContactsResponse.response.ok) {
      throw new LoaderError();
    }

    return personContactsResponse?.data[0]?.klientSuhlasNahravanie ?? false;
  };

  const pravniZastupcovia: SC_KIO_050701_PravnyZastupca[] = [];

  for (const record of response?.data?.records ?? []) {
    if (record.id && record.pravnyZastupcaFyzickaOsobaId) {
      const pravnyZastupca: SC_KIO_050701_PravnyZastupca = {
        id: record.id,
        fyzickaOsobaId: record.pravnyZastupcaFyzickaOsobaId,
        firstname: record.meno ?? "",
        surname: record.priezvisko ?? "",
        phoneCallAsCivilian: await getOsobaSPovolenimNaTelefonovanie(
          record.pravnyZastupcaFyzickaOsobaId,
        ),
        phoneCallContact: await getCallContact(
          record.pravnyZastupcaFyzickaOsobaId,
          EooCiselnikTypKontaktu_Mobil_ID
        ),
        phoneCallContact2: await getCallContact(
          record.pravnyZastupcaFyzickaOsobaId,
          EooCiselnikTypKontaktu_Telefon_ID
        ),
      };

      pravniZastupcovia.push(pravnyZastupca);
    }
  }

  return pravniZastupcovia;
};


interface AdresaRecord {
  adresaOsobaId: number | undefined;
  adresaId: number | undefined;
  osobaId: number;
  druhAdresyId: number;
  druhAdresyNazov: string;
  ulica: string;
  orientacneCislo: string;
  supisneCislo: string;
  obecNazov: string;
  psc: string;
  krajNazov: string;
  okresNazov: string;
  statNazov: string;
}

const getAddress = async (
  physicalPersonId: number
): Promise<AdresaRecord | undefined> => {
  const { EOO_Post } = await API_Clients();
  const personContactsResponse = await EOO_Post(
    "/api/FyzickaOsoba/ListAdresaOsoba",
    {
      body: { filters: [physicalPersonId] },
    }
  );

  if (personContactsResponse.error || !personContactsResponse.response.ok) {
    throw new LoaderError();
  }

  const rawRecords = personContactsResponse?.data?.records ?? [];

  const personContactAddress: AdresaRecord[] = rawRecords
    .map((record: any) => ({
      adresaOsobaId: record.adresaOsobaId ?? undefined,
      adresaId: record.adresaId ?? undefined,
      osobaId: record.osobaId,
      druhAdresyId: record.druhAdresyId,
      druhAdresyNazov: record.druhAdresyNazov,
      ulica: record.ulica ?? "",
      orientacneCislo: record.orientacneCislo ?? "",
      supisneCislo: record.supisneCislo ?? "",
      obecNazov: record.obecNazov ?? "",
      psc: record.psc ?? "",
      krajNazov: record.krajNazov ?? "",
      okresNazov: record.okresNazov ?? "",
      statNazov: record.statNazov ?? "",
    }))
    // Filter out incomplete records if necessary
    .filter(
      (record) =>
        record.osobaId &&
        record.druhAdresyId &&
        record.druhAdresyNazov &&
        record.ulica &&
        record.obecNazov &&
        record.psc
    );

  return personContactAddress[0] ?? undefined;
};

const SC_KIO_050502_zaslany_balik_osoby_list = async (): Promise<
  SC_KIO_050502_ClosePerson[]
> => {
  const { EOO_Post } = await API_Clients();

  const response = await EOO_Post(
    "/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
    {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }
  );

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  const closePeopleList: SC_KIO_050502_ClosePerson[] = [];
  const today = new Date();
  const date18YearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  const isInPrison = async (physicalPersonId: number): Promise<boolean> => {
    const klientListResponse = await EOO_Post("/api/Klient/List", {
      body: { filters: [{ fyzickaOsobaId: physicalPersonId }] },
    });

    if (klientListResponse.error || !klientListResponse.response.ok) {
      throw new LoaderError();
    }

    if (
      klientListResponse.data?.records &&
      klientListResponse.data.records.length > 0
    ) {
      for (const clientData of klientListResponse.data.records) {
        if (
          (await isAccused({
            klientId: clientData.id ?? NaN,
            ustavZvjsId: clientData.ustavZvjsId ?? NaN,
          })) ||
          (await isSentenced({
            klientId: clientData.id ?? NaN,
            ustavZvjsId: clientData.ustavZvjsId ?? NaN,
          }))
        ) {
          return true;
        }
      }
    }

    return false;
  };

  for (const record of response.data.records ?? []) {
    if (record.id && record.fyzickaOsobaId) {
      const adresa = await getAddress(record.fyzickaOsobaId);
      const closePerson: SC_KIO_050502_ClosePerson = {
        blizkaOsobaId: record.id,
        fyzickaOsobaId: record.fyzickaOsobaId,
        firstname: record.fyzickaOsoba?.meno,
        surname: record.fyzickaOsoba?.priezvisko,
        relationId: record.typId,
        isUnderage: record.fyzickaOsoba?.datumNarodenia
          ? new Date(record.fyzickaOsoba?.datumNarodenia) > date18YearsAgo
          : false,
        inPrison: await isInPrison(record.fyzickaOsobaId),
        isPossibleToContact: record.zverejnitAdresuKlientovi,
        adresaId: adresa?.adresaId,
        stat: adresa?.statNazov ?? "",
        kraj: adresa?.krajNazov ?? "",
        okres: adresa?.okresNazov ?? "",
        obec: adresa?.obecNazov ?? "",
        psc: adresa?.psc ?? "",
        ulica: adresa?.ulica ?? "",
        orientacneCislo: adresa?.orientacneCislo ?? "",
        supisneCislo: adresa?.supisneCislo ?? "",
      };

      closePeopleList.push(closePerson);
    }
  }

  return closePeopleList;
};

const SC_KIO_050101_close_people_list = async (): Promise<
  SC_KIO_050101_ClosePerson[]
> => {
  const { EOO_Post, SHARE_INT_Post } = await API_Clients();

  const response = await EOO_Post(
    "/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
    {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }
  );

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  const closePeopleList: SC_KIO_050101_ClosePerson[] = [];
  const today = new Date();
  const date18YearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  const isInPrison = async (physicalPersonId: number): Promise<boolean> => {
    const klientListResponse = await EOO_Post("/api/Klient/List", {
      body: { filters: [{ fyzickaOsobaId: physicalPersonId }] },
    });

    if (klientListResponse.error || !klientListResponse.response.ok) {
      throw new LoaderError();
    }

    if (
      klientListResponse.data?.records &&
      klientListResponse.data?.records?.length > 0
    ) {
      for (const clientData of klientListResponse.data.records ?? []) {
        if (
          (await isAccused({
            klientId: clientData.id ?? NaN,
            ustavZvjsId: clientData.ustavZvjsId ?? NaN,
          })) ||
          (await isSentenced({
            klientId: clientData.id ?? NaN,
            ustavZvjsId: clientData.ustavZvjsId ?? NaN,
          }))
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const getCallContact = async (
    physicalPersonId: number,
    contactTypeId: number
  ) => {
    const personContactsResponse = await EOO_Post(
      "/api/FyzickaOsoba/ListKontakt",
      {
        body: { filters: [physicalPersonId] },
      }
    );

    if (personContactsResponse.error || !personContactsResponse.response.ok) {
      throw new LoaderError();
    }

    const now = new Date();
    return personContactsResponse.data.records?.find((contact) => {
      return (
        contact.primarny === true &&
        contact.typId === contactTypeId &&
        contact.platnostOd &&
        new Date(contact.platnostOd) < now &&
        (!contact.platnostDo ||
          (contact.platnostDo && new Date(contact.platnostDo) > now))
      );
    });
  };

  const isNumberBlocked = async (
    physicalPersonId: number,
  ): Promise<boolean> => {
    const personContactsResponse = await SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListOsobaSPovolenimNaTelefonovanie",
      {
        body: {
          socialnyKontaktKlientaId: { eq: physicalPersonId },
          klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
        },
      },
    );

    if (personContactsResponse.error || !personContactsResponse.response.ok) {
      throw new LoaderError();
    }

    return personContactsResponse?.data?.some(
      (item: { zablokovanieTelCisla?: boolean | null }) =>
        item.zablokovanieTelCisla === true
    ) ?? false;
  };

  for (const record of response.data.records ?? []) {
    if (record.id && record.fyzickaOsobaId) {
      const closePerson: SC_KIO_050101_ClosePerson = {
        blizkaOsobaId: record.id,
        fyzickaOsobaId: record.fyzickaOsobaId,
        firstname: record.fyzickaOsoba?.meno,
        surname: record.fyzickaOsoba?.priezvisko,
        relationId: record.typId,
        isUnderage: record.fyzickaOsoba?.datumNarodenia
          ? new Date(record.fyzickaOsoba?.datumNarodenia) > date18YearsAgo
          : false,
        inPrison: await isInPrison(record.fyzickaOsobaId),
        videoCallContact: await getCallContact(
          record.fyzickaOsobaId,
          EooCiselnikTypKontaktu_Skype_ID
        ),
        phoneCallContact: await getCallContact(
          record.fyzickaOsobaId,
          EooCiselnikTypKontaktu_Mobil_ID
        ),
        phoneCallContact2: await getCallContact(
          record.fyzickaOsobaId,
          EooCiselnikTypKontaktu_Telefon_ID
        ),
        isPossibleToContact: !(await isNumberBlocked(
          record.fyzickaOsobaId
        )),
      };

      closePeopleList.push(closePerson);
    }
  }

  return closePeopleList;
};

const SC_KIO_050701_isAnyClosePersonInPrison = async (): Promise<boolean> => {
  const { EOO_Post } = await API_Clients();

  const response = await EOO_Post(
    "/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
    {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }
  );

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  for (const record of response.data.records ?? []) {
    if (record.id && record.fyzickaOsobaId) {
      const klientListResponse = await EOO_Post("/api/Klient/List", {
        body: { filters: [{ fyzickaOsobaId: record.fyzickaOsobaId }] },
      });

      if (klientListResponse.error || !klientListResponse.response.ok) {
        throw new LoaderError();
      }

      for (const clientData of klientListResponse.data.records ?? []) {
        const isAccusedResult = await isAccused({
          klientId: clientData.id ?? NaN,
          ustavZvjsId: clientData.ustavZvjsId ?? NaN,
        });

        const isSentencedResult = await isSentenced({
          klientId: clientData.id ?? NaN,
          ustavZvjsId: clientData.ustavZvjsId ?? NaN,
        });

        if (isAccusedResult || isSentencedResult) {
          return true;
        }
      }
    }
  }

  return false; // No close person is accused or sentenced
};


const SC_KIO_050101_visit_requests_for_current_and_next_month_list =
  async (): Promise<CreateZiadostRequestBody[]> => {
    const { SZOO_Post, HASURA_Get } = await API_Clients();

    const today = new Date();

    // fetch all requests from the start of the previous month
    const requestsSzooResponse = await SZOO_Post(
      "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
      {
        body: {
          klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
          podanieUstavId: {
            eq: Number(localStorage.getItem("klientUstavId")),
          },
          typZiadostiId: { eq: CisTypZiadosti_SC_KIO_050101_ID },
          datumPodaniaZiadosti: {
            gt: new Date(
              today.getFullYear(),
              today.getMonth() - 1,
              1
            ).toISOString(),
          },
        },
      }
    );

    if (requestsSzooResponse.error || !requestsSzooResponse.response.ok) {
      console.error("Failed to fetch client requests from szoo");
      throw new LoaderError();
    }

    // filter out requests which are only in certain states
    requestsSzooResponse.data = requestsSzooResponse.data.filter(
      (request) =>
        request.stavZiadostiId === CisStavZiadosti_Poziadavka_O_Stanovisko_ID ||
        request.stavZiadostiId === CisStavZiadosti_Schvalena_ID ||
        request.stavZiadostiId === CisStavZiadosti_Spracovane_Stanovisko_ID ||
        request.stavZiadostiId === CisStavZiadosti_Zaevidovana_ID
    );

    const toReturn: CreateZiadostRequestBody[] = [];
    for (const requestSzoo of requestsSzooResponse.data ?? []) {
      if (requestSzoo.cisloZiadosti) {
        // fetch request body for current request from Hasura
        const responseHasura = await HASURA_Get(
          "/api/rest/v1/kiosk-ziadost-by-id/{id}",
          {
            params: {
              path: {
                id: requestSzoo.cisloZiadosti,
              },
            },
          }
        );

        if (
          responseHasura.error ||
          !responseHasura.response.ok ||
          !responseHasura.data.kioskZiadost?.body
        ) {
          console.error("Failed to fetch request body from Hasura");
          throw new LoaderError();
        }

        const requestBody: CreateZiadostRequestBody = responseHasura.data
          .kioskZiadost.body as CreateZiadostRequestBody;

        // consider only those requests inside which the client requests visit for current or next month
        if (today.getMonth() + 1 === 12) {
          if (
            requestBody.mesiacNavstevy === 12 ||
            requestBody.mesiacNavstevy === 1
          ) {
            toReturn.push(requestBody);
          }
        } else {
          if (
            requestBody.mesiacNavstevy === today.getMonth() + 1 ||
            requestBody.mesiacNavstevy ===
              (today.getMonth() === 11 ? 1 : today.getMonth() + 2)
          ) {
            toReturn.push(requestBody);
          }
        }
      }
    }

    return toReturn;
  };

/**
 * Check if the client can request an extraordinary visit only (no standard visit available)
 * video call with underage kids has no effect on this condition
 */
export const SC_KIO_050101_can_request_extraordinary_visit_only = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}): boolean => {
  const visitChecks = SC_KIO_050101_can_request_visit_all_checks({
    userCalcData: userCalcData,
  });
  const today = new Date();

  if (
    new Date(answers[MONTH_OF_VISIT_QUESTION_ID] as string).getMonth() ===
    today.getMonth()
  ) {
    // this month
    return (
      visitChecks.thisMonthExtraordinaryVisit &&
      !visitChecks.thisMonthStandardPhysicalVisit &&
      !visitChecks.thisMonthStandardVideoCallVisit
    );
  } else {
    // next month
    return (
      visitChecks.nextMonthExtraordinaryVisit &&
      !visitChecks.nextMonthStandardPhysicalVisit &&
      !visitChecks.nextMonthStandardVideoCallVisit
    );
  }
};

export const SC_KIO_050101_is_physical_visit_available = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}): boolean => {
  const visitChecks = SC_KIO_050101_can_request_visit_all_checks({
    userCalcData: userCalcData,
  });
  const today = new Date();

  if (
    new Date(answers[MONTH_OF_VISIT_QUESTION_ID] as string).getMonth() ===
    today.getMonth()
  ) {
    // this month
    return (
      visitChecks.thisMonthStandardPhysicalVisit ||
      visitChecks.thisMonthExtraordinaryVisit
    );
  } else {
    // next month
    return (
      visitChecks.nextMonthStandardPhysicalVisit ||
      visitChecks.nextMonthExtraordinaryVisit
    );
  }
};

export const SC_KIO_050101_is_video_call_visit_available = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}): boolean => {
  const visitChecks = SC_KIO_050101_can_request_visit_all_checks({
    userCalcData: userCalcData,
  });
  const today = new Date();

  if (
    new Date(answers[MONTH_OF_VISIT_QUESTION_ID] as string).getMonth() ===
    today.getMonth()
  ) {
    // this month
    return (
      visitChecks.thisMonthStandardVideoCallVisit ||
      visitChecks.thisMonthExtraordinaryVisit
    );
  } else {
    // next month
    return (
      visitChecks.nextMonthStandardVideoCallVisit ||
      visitChecks.nextMonthExtraordinaryVisit
    );
  }
};

export const SC_KIO_050101_is_underage_kids_video_call_visit_available = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}): boolean => {
  const visitChecks = SC_KIO_050101_can_request_visit_all_checks({
    userCalcData: userCalcData,
  });
  const today = new Date();

  if (
    new Date(answers[MONTH_OF_VISIT_QUESTION_ID] as string).getMonth() ===
    today.getMonth()
  ) {
    // this month
    return visitChecks.thisMonthVideoCallKidsVisit;
  } else {
    // next month
    return visitChecks.nextMonthVideoCallKidsVisit;
  }
};

const SC_KIO_050101_display_2_hours_visit_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const today = new Date();
  const visitChecks = SC_KIO_050101_can_request_visit_all_checks({
    userCalcData: userCalcData,
  });

  // check what type of visit user has selected and check if a 2-hour personal visit is still available to user, or he has only 1 hour left
  if (
    answers[FORM_OF_VISIT_QUESTION_ID] ===
    CisFormaRealizacieNavstevy_Fyzicka_Navsteva_Kod
  ) {
    if (
      new Date(answers[MONTH_OF_VISIT_QUESTION_ID] as string).getMonth() ===
      today.getMonth()
    ) {
      // this month
      if (
        visitChecks.thisMonthTotalStandardVisitTimeRequested === 0 ||
        // display 2 hours also when requesting extraordinary request
        (visitChecks.thisMonthExtraordinaryVisit &&
          !visitChecks.thisMonthStandardPhysicalVisit &&
          !visitChecks.thisMonthStandardVideoCallVisit)
      ) {
        return true;
      }
    } else {
      // next month
      if (
        visitChecks.nextMonthTotalStandardVisitTimeRequested === 0 ||
        // display 2 hours also when requesting extraordinary request
        (visitChecks.thisMonthExtraordinaryVisit &&
          !visitChecks.thisMonthStandardPhysicalVisit &&
          !visitChecks.thisMonthStandardVideoCallVisit)
      ) {
        return true;
      }
    }
  }

  return false;
};

const SC_KIO_050101_should_display_invited_person_in_prison_warning = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (
    answers[FORM_OF_VISIT_QUESTION_ID] ===
    CisFormaRealizacieNavstevy_Fyzicka_Navsteva_Kod
  ) {
    return SC_KIO_050101_is_any_selected_person_in_prison(
      (answers[INVITED_PEOPLE_LIST_QUESTION_ID] ?? []) as string[],
      (userCalcData["SC_KIO_050101_CLOSE_PEOPLE_LIST"] ??
        []) as SC_KIO_050101_ClosePerson[]
    );
  }

  return false;
};

const SC_KIO_050802_has_already_requested_items_status =
  async (): Promise<SC_KIO_050802_AlreadyRequestedItemsStatus> => {
    const { SZOO_Post, HASURA_Get } = await API_Clients();

    // fetch all client requests of this request type
    const responseSzoo = await SZOO_Post(
      "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
      {
        body: {
          klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
          podanieUstavId: {
            eq: Number(localStorage.getItem("klientUstavId")),
          },
          typZiadostiId: { eq: CisTypZiadosti_SC_KIO_050802_ID },
        },
      }
    );

    if (responseSzoo.error || !responseSzoo.response.ok) {
      console.error("Failed to fetch client requests from SZOO");
      throw new LoaderError();
    }

    const toReturn: SC_KIO_050802_AlreadyRequestedItemsStatus = {
      ziadostOStravu: false,
      ziadamOPoskytnutieOdevuObuvi: false,
      // When cestovneCestovnyListok is false, then all other travel related fields should be false.
      // When cestovneCestovnyListok is true, then exactly one of the travel related fields should be set to true.
      cestovneCestovnyListok: false,
      poHranicu: false,
      najblizsiZastupitelskyUrad: false,
      prechodnyPobyt: false,
      trvalyPobyt: false,
    };

    for (const requestSzoo of responseSzoo.data) {
      if (requestSzoo.cisloZiadosti) {
        // fetch request body for current request from Hasura
        const responseHasura = await HASURA_Get(
          "/api/rest/v1/kiosk-ziadost-by-id/{id}",
          {
            params: {
              path: {
                id: requestSzoo.cisloZiadosti,
              },
            },
          }
        );
        if (
          responseHasura.error ||
          !responseHasura.response.ok ||
          !responseHasura.data.kioskZiadost?.body
        ) {
          console.error("Failed to fetch request body from Hasura");
          throw new LoaderError();
        }

        const requestBody: CreateZiadostRequestBody = responseHasura.data
          .kioskZiadost.body as CreateZiadostRequestBody;

        if (requestBody.ziadostOStravu) {
          toReturn.ziadostOStravu = true;
        }

        if (requestBody.ziadamOPoskytnutieOdevuObuvi) {
          toReturn.ziadamOPoskytnutieOdevuObuvi = true;
        }

        if (requestBody.cestovneCestovnyListok) {
          toReturn.cestovneCestovnyListok = true;

          if (requestBody.trvalyPobyt) {
            toReturn.trvalyPobyt = true;
          }
          if (requestBody.prechodnyPobyt) {
            toReturn.prechodnyPobyt = true;
          }
          if (requestBody.najblizsiZastupitelskyUrad) {
            toReturn.najblizsiZastupitelskyUrad = true;
          }
          if (requestBody.poHranicu) {
            toReturn.poHranicu = true;
          }
        }
      } else {
        console.error(
          "Field cisloZiadosti not present in SZOO - not possible to fetch request body in Hasura"
        );
        throw new LoaderError();
      }
    }

    return toReturn;
  };

const SC_KIO_050204_requests_this_month_list = async ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const { SZOO_Post, HASURA_Get } = await API_Clients();

  const today = new Date();
  const startOfTheMonthString = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  ).toISOString();

  const requestsSzooResponse = await SZOO_Post(
    "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
    {
      body: {
        klientId: { eq: localStorage.getItem("klientObjectId") ?? "" },
        podanieUstavId: {
          eq: Number(localStorage.getItem("klientUstavId")),
        },
        typZiadostiId: { eq: CisTypZiadosti_SC_KIO_050204_ID },
        datumPodaniaZiadosti: {
          gt: startOfTheMonthString,
        },
      },
    }
  );

  if (requestsSzooResponse.error || !requestsSzooResponse.response.ok) {
    console.error("Failed to fetch client requests from szoo");
    throw new LoaderError();
  }

  const requestBodies: CreateZiadostRequestBody[] = [];
  for (const requestSzoo of requestsSzooResponse.data ?? []) {
    if (requestSzoo.cisloZiadosti) {
      // fetch request body for current request from Hasura
      const responseHasura = await HASURA_Get(
        "/api/rest/v1/kiosk-ziadost-by-id/{id}",
        {
          params: {
            path: {
              id: requestSzoo.cisloZiadosti,
            },
          },
        }
      );

      if (
        responseHasura.error ||
        !responseHasura.response.ok ||
        !responseHasura.data.kioskZiadost?.body
      ) {
        console.error("Failed to fetch request body from Hasura");
        throw new LoaderError();
      }

      const requestBody: CreateZiadostRequestBody = responseHasura.data
        .kioskZiadost.body as CreateZiadostRequestBody;

      requestBodies.push(requestBody);
    }
  }

  return requestBodies;
};

const SC_KIO_050204_can_transfer_funds_to_close_person = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (
    userCalcData["IS_USER_SENTENCED"] &&
    !(
      userCalcData[
        "SC_KIO_050204_REQUESTS_THIS_MONTH_LIST"
      ] as CreateZiadostRequestBody[]
    ).some(
      (requestBody) =>
        requestBody.typOdoslaniaProstriedkov ===
        CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod
    )
  ) {
    return true;
  }
  return false;
};

const SC_KIO_050204_can_transfer_funds_to_other_person_or_harm_caused = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (
    userCalcData["IS_USER_SENTENCED"] &&
    !(
      userCalcData[
        "SC_KIO_050204_REQUESTS_THIS_MONTH_LIST"
      ] as CreateZiadostRequestBody[]
    ).some(
      (requestBody) =>
        requestBody.typOdoslaniaProstriedkov ===
        CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod
    )
  ) {
    return true;
  }
  return false;
};

const SC_KIO_050204_can_transfer_funds_to_designated_person = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (
    userCalcData["IS_USER_ACCUSED"] &&
    !(
      userCalcData[
        "SC_KIO_050204_REQUESTS_THIS_MONTH_LIST"
      ] as CreateZiadostRequestBody[]
    ).some(
      (requestBody) =>
        requestBody.typOdoslaniaProstriedkov ===
        CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod
    )
  ) {
    return true;
  }
  return false;
};

const SC_KIO_050204_does_client_have_possible_withdrawal_available = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return userCalcData["POSSIBLE_WITHDRAWAL_AMOUNT"] > 0;
};

const SC_KIO_050204_does_client_have_available_account_available = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return userCalcData["AVAILABLE_ACCOUNT_AMOUNT"] > 0;
};

const SC_KIO_050302_available_user_package_type_options = async ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const packageTypeIdListToCheck: DruhBalikaEnum[] = [
    CisDruhBalik_BalikVeciOsobnejPotreby_ID,
    CisDruhBalik_IneVeciOsobnejPotreby_ID,
    CisDruhBalik_BalikOdevObuv_ID,
    CisDruhBalik_Tricko_ID,
    CisDruhBalik_Topanky_ID,
    CisDruhBalik_Spodky_ID,
    CisDruhBalik_ZimnaBundaCiapka_ID,
  ];

  const availableUserPackageTypeOptions: SC_KIO_050302_PackageTypeAvailability[] = [];
  for (const packageTypeId of packageTypeIdListToCheck) {
    const responseSzoo = await getQuestionControlData({
      typZiadosti: TypZiadostiEnum.Z010101,
      druhBalika: packageTypeId
    });
    const questionControl = responseSzoo !== undefined ? responseSzoo : { isValid: false };

    availableUserPackageTypeOptions.push({
      druhBalikaId: packageTypeId,
      isValid: questionControl?.isValid as boolean,
      odpoved: questionControl?.odpoved,
      porusenePodmienky: questionControl?.porusenePodmienky,
    });
  }
  return availableUserPackageTypeOptions;
};

const SC_KIO_050302_should_display_personal_belongings_package_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    SC_KIO_050302_get_package_availability_item(
      CisDruhBalik_BalikVeciOsobnejPotreby_ID,
      userCalcData
    )?.isValid ?? false
  );
};

const SC_KIO_050302_should_display_other_personal_belongings_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    SC_KIO_050302_get_package_availability_item(
      CisDruhBalik_IneVeciOsobnejPotreby_ID,
      userCalcData
    )?.isValid ?? false
  );
};

const SC_KIO_050302_should_display_clothing_package_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    SC_KIO_050302_get_package_availability_item(
      CisDruhBalik_BalikOdevObuv_ID,
      userCalcData
    )?.isValid ?? false
  );
};

const SC_KIO_050302_should_display_shirt_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    SC_KIO_050302_get_package_availability_item(
      CisDruhBalik_Tricko_ID,
      userCalcData
    )?.isValid ?? false
  );
};

const SC_KIO_050302_should_display_shoes_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    SC_KIO_050302_get_package_availability_item(
      CisDruhBalik_Topanky_ID,
      userCalcData
    )?.isValid ?? false
  );
};

const SC_KIO_050302_should_display_underpants_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    SC_KIO_050302_get_package_availability_item(
      CisDruhBalik_Spodky_ID,
      userCalcData
    )?.isValid ?? false
  );
};

const SC_KIO_050302_should_display_winter_jacket_hat_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    SC_KIO_050302_get_package_availability_item(
      CisDruhBalik_ZimnaBundaCiapka_ID,
      userCalcData
    )?.isValid ?? false
  );
};

const SC_KIO_050302_get_package_availability_item = (
  druhBalikaId: number,
  userCalcData: UserCalcData
) => {
  return userCalcData[
    "SC_KIO_050302_AVAILABLE_USER_PACKAGE_TYPE_OPTIONS"
  ]?.find(
    (item: SC_KIO_050302_PackageTypeAvailability) =>
      item.druhBalikaId === druhBalikaId
  );
};

const SC_KIO_050301_questions_control = async ({ userCalcData, answers }:
{
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z010201 });
  return questionControlData !== undefined ? questionControlData : { isValid: false, porusenePodmienky: null };
};

const SC_KIO_051404_question_control = async ({ userCalcData, answers }:
{
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z030001 });
  return questionControlData !== undefined ? questionControlData : { isValid: false, porusenePodmienky: null };
};

const SC_KIO_051404_has_same_request_in_non_terminated_state = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControl = userCalcData["SC_KIO_051404_QUESTION_CONTROL"];
  if (questionControl === undefined || questionControl.porusenePodmienky === null){
    return false;
  }
  return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR036]);
};


const SC_KIO_051402_question_control = async ({ userCalcData, answers }: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z030002 });
  return questionControlData !== undefined ? questionControlData : { isValid: false, porusenePodmienky: null };
};

const SC_KIO_051402_has_same_request_in_non_terminated_state = ({ userCalcData, answers }:
{
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControl = userCalcData["SC_KIO_051402_QUESTION_CONTROL"];
  if (questionControl === undefined || questionControl.porusenePodmienky === null){
    return false;
  }
  return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR036]);
};

const SC_KIO_050701_question_control = async ({ userCalcData, answers }: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z130001 });
  return questionControlData !== undefined ? questionControlData : { isValid: false, porusenePodmienky: null };
};

const SC_KIO_050701_has_same_request_in_non_terminated_state = ({ userCalcData, answers }:
{
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  const questionControl = userCalcData["SC_KIO_050701_QUESTION_CONTROL"];

  if (questionControl === undefined || questionControl.porusenePodmienky === null){
    return false;
  }
  return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR036]);
};

const SC_KIO_051403_question_control = async ({ userCalcData, answers }: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {

  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z030004 });
  return questionControlData !== undefined ? questionControlData : { isValid: false, porusenePodmienky: null };
};

const getQuestionControlData = async (params : object) => {
  const { SZOO_Get } = await API_Clients();
  const responseSzoo = await SZOO_Get("/api/Kio/ClientRequestValidate", {
    params: {
      query: {
        klientId: localStorage.getItem("klientObjectId") ?? "",
        ...params
      },
    },
  });

  if (responseSzoo.error || !responseSzoo.response.ok || !responseSzoo.data) {
    console.error("Failed to fetch request body from Szoo");
  }

  return responseSzoo?.data;
};


const customCalculations: CalculationType = {
  SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_ONE:
    SC_KIO_051102_is_user_sentenced_and_group_one,
  SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_OTHER:
    SC_KIO_051102_is_user_sentenced_and_group_other,
  SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST:
    SC_KIO_050501_get_all_user_electric_devices_list,
  SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING:
    SC_KIO_050501_display_user_already_use_device_warning,
  SC_KIO_050501_HAS_REFUSED_NEW_DEVICE_OF_TYPE_OTHER_IN_PAST_3_MONTHS:
    SC_KIO_050501_has_refused_new_device_of_type_other_in_past_3_months,
  SC_KIO_050501_CAN_REQUEST_DEVICE_REVISION_CONTROL:
    SC_KIO_050501_can_request_device_revision_control,
  SC_KIO_051201_SHOULD_DISPLAY_RAMADAN_QUESTION:
    SC_KIO_051201_should_display_ramadan_question,
  SC_KIO_050802_FOOD_ALREADY_REQUESTED: SC_KIO_050802_food_already_requested,
  SC_KIO_050802_CLOTHING_ALREADY_REQUESTED:
    SC_KIO_050802_clothing_already_requested,
  SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED:
    SC_KIO_050802_travel_expenses_already_requested,
  SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING:
    SC_KIO_050603_is_user_illiterate_or_without_primary_schooling,
  // SC_KIO_050603_IS_USER_NOT_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING:
  //   SC_KIO_050603_is_user_not_illiterate_or_without_primary_schooling,
  SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE:
    SC_KIO_050301_can_request_private_correspondence,
  SC_KIO_050301_IS_ALLOWED_TO_REQUEST_CORRESPONDENCE:
    SC_KIO_050301_is_allowed_to_request_correspondence,
  SC_KIO_050301_QUESTION_CONTROL: SC_KIO_050301_questions_control,
  SC_KIO_051402_QUESTION_CONTROL: SC_KIO_051402_question_control,
  SC_KIO_050701_QUESTION_CONTROL: SC_KIO_050701_question_control,
  SC_KIO_051403_QUESTION_CONTROL: SC_KIO_051403_question_control,
  SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE:
  SC_KIO_051402_has_same_request_in_non_terminated_state,
  SC_KIO_051404_QUESTION_CONTROL: SC_KIO_051404_question_control,
  SC_KIO_051404_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE:
  SC_KIO_051404_has_same_request_in_non_terminated_state,
  SC_KIO_050302_AVAILABLE_USER_PACKAGE_TYPE_OPTIONS:
    SC_KIO_050302_available_user_package_type_options,
  SC_KIO_050302_SHOULD_DISPLAY_PERSONAL_BELONGINGS_PACKAGE_OPTION:
    SC_KIO_050302_should_display_personal_belongings_package_option,
  SC_KIO_050302_SHOULD_DISPLAY_OTHER_PERSONAL_BELONGINGS_OPTION:
    SC_KIO_050302_should_display_other_personal_belongings_option,
  SC_KIO_050302_SHOULD_DISPLAY_CLOTHING_PACKAGE_OPTION:
    SC_KIO_050302_should_display_clothing_package_option,
  SC_KIO_050302_SHOULD_DISPLAY_SHIRT_OPTION:
    SC_KIO_050302_should_display_shirt_option,
  SC_KIO_050302_SHOULD_DISPLAY_SHOES_OPTION:
    SC_KIO_050302_should_display_shoes_option,
  SC_KIO_050302_SHOULD_DISPLAY_UNDERPANTS_OPTION:
    SC_KIO_050302_should_display_underpants_option,
  SC_KIO_050302_SHOULD_DISPLAY_WINTER_JACKET_HAT_OPTION:
    SC_KIO_050302_should_display_winter_jacket_hat_option,
  SC_KIO_051403_SHOULD_SHOW_PRIORITY_QUESTION:
    SC_KIO_051403_should_show_priority_question,
  SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST:
  SC_KIO_051403_is_client_allowed_to_send_request,
  SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_JOB:
    SC_KIO_051001_can_request_shower_reason_job,
  SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_HEALTH:
    SC_KIO_051001_can_request_shower_reason_health,
  SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_OTHER:
    SC_KIO_051001_can_request_shower_reason_other,
  SC_KIO_051003_CLIENT_AVAILABLE_BALANCE:
    SC_KIO_051003_client_available_balance,
  SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST:
    SC_KIO_051003_client_personal_hygiene_needs_list,
  SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM:
    SC_KIO_051003_is_allowed_to_request_personal_hygiene_item,
  SC_KIO_051102_CIVILIAN_CLOTHES_LIST: SC_KIO_051102_civilian_clothes_list,
  SC_KIO_050101_CLOSE_PEOPLE_LIST: SC_KIO_050101_close_people_list,
  SC_KIO_050101_VISIT_REQUESTS_FOR_CURRENT_AND_NEXT_MONTH_LIST:
    SC_KIO_050101_visit_requests_for_current_and_next_month_list,
  SC_KIO_050101_CAN_REQUEST_EXTRAORDINARY_VISIT_ONLY:
    SC_KIO_050101_can_request_extraordinary_visit_only,
  SC_KIO_050101_IS_PHYSICAL_VISIT_AVAILABLE:
    SC_KIO_050101_is_physical_visit_available,
  SC_KIO_050101_IS_VIDEO_CALL_VISIT_AVAILABLE:
    SC_KIO_050101_is_video_call_visit_available,
  SC_KIO_050101_IS_UNDERAGE_KIDS_VIDEO_CALL_VISIT_AVAILABLE:
    SC_KIO_050101_is_underage_kids_video_call_visit_available,
  SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION:
    SC_KIO_050101_display_2_hours_visit_option,
  SC_KIO_050101_SHOULD_DISPLAY_INVITED_PERSON_IN_PRISON_WARNING:
    SC_KIO_050101_should_display_invited_person_in_prison_warning,
  SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS:
    SC_KIO_050802_has_already_requested_items_status,
  SC_KIO_050204_REQUESTS_THIS_MONTH_LIST:
    SC_KIO_050204_requests_this_month_list,
  SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON:
    SC_KIO_050204_can_transfer_funds_to_close_person,
  SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED:
    SC_KIO_050204_can_transfer_funds_to_other_person_or_harm_caused,
  SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_DESIGNATED_PERSON:
    SC_KIO_050204_can_transfer_funds_to_designated_person,
  SC_KIO_050204_DOES_CLIENT_HAVE_POSSIBLE_WITHDRAWAL_AVAILABLE:
    SC_KIO_050204_does_client_have_possible_withdrawal_available,
  SC_KIO_050204_DOES_CLIENT_HAVE_AVAILABLE_ACCOUNT_AVAILABLE:
    SC_KIO_050204_does_client_have_available_account_available,
  SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE:
  SC_KIO_050701_has_same_request_in_non_terminated_state,
  SC_KIO_050701_IS_ANY_CLOSE_PERSON_IN_PRISON: SC_KIO_050701_isAnyClosePersonInPrison,
  SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST: SC_KIO_050701_pravny_zastupcovia_klienta_list,
  SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST: SC_KIO_050502_zaslany_balik_osoby_list,
};

type CalculationType = {
  [key in CustomCalculationType]: ({
    userCalcData,
    answers,
  }: {
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => number | boolean | string | any;
};

export default customCalculations;

/**
 * Return models
 */

export interface CivilianClothes {
  civilianClothesType: string;
  civilianClothesName: string;
  countOfCurrentlyRegisteredItems?: number;
  maxCountOfRegisteredItems: number;
  estimatedWashingCostPerItem: number;
  clientMethodOfEnsuringHygienicSafety?: string;
}

export interface PersonalHygieneItem {
  typeCode: string;
  // TODO replace with value from counter (pass only typeId here and determine translation from counter)
  typeName: string;
  consumptionStandardInMonths: number;
  estimatedPrice: number;
  dateOfLastIssue?: string;
}

export interface SC_KIO_050101_ClosePerson {
  blizkaOsobaId: number | undefined;
  fyzickaOsobaId: number;
  firstname: string | undefined;
  surname: string | undefined;
  relationId: number | undefined;
  videoCallContact?: FyzickaOsobaKontakt | undefined;
  phoneCallContact?: FyzickaOsobaKontakt | undefined;
  phoneCallContact2?: FyzickaOsobaKontakt | undefined;
  isUnderage: boolean;
  // person is either accused or sentenced
  inPrison: boolean;
  isPossibleToContact?: boolean;
}

export interface SC_KIO_050502_ClosePerson {
  blizkaOsobaId: number | undefined;
  fyzickaOsobaId: number;
  firstname: string | undefined;
  surname: string | undefined;
  relationId: number | undefined;
  isUnderage: boolean;
  inPrison: boolean;
  isPossibleToContact?: boolean;
  adresaId?: number;
  stat?: string;
  kraj?: string;
  okres?: string;
  obec?: string;
  psc?: string;
  ulica?: string;
  supisneCislo?: string;
  orientacneCislo?: string;
}

export interface SC_KIO_050701_PravnyZastupca {
  id: number | undefined;
  fyzickaOsobaId: number;
  firstname: string | null | undefined;
  surname: string | null | undefined;
  phoneCallAsCivilian?: boolean;
  phoneCallContact?: FyzickaOsobaKontakt | undefined;
  phoneCallContact2?: FyzickaOsobaKontakt | undefined;
}

export interface SC_KIO_050802_AlreadyRequestedItemsStatus {
  ziadamOPoskytnutieOdevuObuvi: boolean;
  ziadostOStravu: boolean;
  cestovneCestovnyListok: boolean;
  trvalyPobyt: boolean;
  prechodnyPobyt: boolean;
  najblizsiZastupitelskyUrad: boolean;
  poHranicu: boolean;
}

export interface SC_KIO_050501_ElectricDevice {
  id: number | undefined;
  typeCode: string;
  controlValidUntil: string | undefined;
  ongoingInspection: boolean;
  nameOfOtherDevice?: string;
}

export interface SC_KIO_050302_PackageTypeAvailability {
  druhBalikaId: number;
  isValid: boolean;
  odpoved: number | undefined;
  porusenePodmienky: string[] | null | undefined;
}

/**
 * Helpers
 */

export const SC_KIO_050301_doesClientHaveEnoughMoneyOnAccountToBuyItemsOnHisOwn =
  (availableAccountBalance: number) => {
    if (availableAccountBalance >= 2) {
      return true;
    }
    return false;
  };
