import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../store/context/auth-context";
import { ScreenSaverPage } from "../pages";
import { useUserData } from "../store/context/userData-context";
import moment from "moment";
import useEnvConfig from "../store/context/useEnvConfig";
import {
  KEY_LOGOUT_TIMEOUT,
  KEY_SCREENSAVER_TIMEOUT,
} from "../store/context/envConfigContext";

const LAST_ACTIVITY_TIME = "LAST_ACTIVITY_TIME";

const events: string[] = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

interface AppActivityGuardProps {
  children: React.ReactElement;
}

const AppActivityGuard: React.FC<AppActivityGuardProps> = ({ children }) => {
  const { onLogout, isLoggedIn } = useContext(AuthContext);
  const { clearUserData } = useUserData();
  const [displayScreenSaver, setDisplayScreenSaver] = useState<boolean>(false);
  const { envConfig } = useEnvConfig();

  // Handle activity events listeners
  // When the component mounts, it adds an event listeners to the window.
  // Each time any of the event is triggered, i.e., on mouse move, click, scroll, keypress etc., the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the events is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    const activityCapturedHandler = () => {
      localStorage.setItem(LAST_ACTIVITY_TIME, Date.now().toString());
      if (displayScreenSaver) {
        console.debug("ScreenSaver was deactivated");
        setDisplayScreenSaver(false);
      }
    };

    for (const event of events) {
      window.addEventListener(event, activityCapturedHandler);
    }

    return () => {
      for (const event of events) {
        window.removeEventListener(event, activityCapturedHandler);
      }
    };
  }, [displayScreenSaver]);

  // Checking if user is active, otherwise logout is fired / screensaver is displayed
  useEffect(() => {
    const logoutAction = () => {
      if (window.location === window.parent.location) {
        console.debug("You have been logged out");
        clearUserData();
        onLogout();
      } else {
        window.parent.postMessage(
          {
            type: "logout",
            message: "logout",
          },
          "*"
        );
      }
    };

    const timer = setInterval(() => {
      const lastActivityTime = localStorage.getItem(LAST_ACTIVITY_TIME);

      const logoutTimeoutEnv = envConfig[KEY_LOGOUT_TIMEOUT];
      const screensaverTimeoutEnv = envConfig[KEY_SCREENSAVER_TIMEOUT];

      const logoutTimeoutEnvValid = !isNaN(
        Number.parseInt(logoutTimeoutEnv ?? "")
      );
      const screensaverTimeoutEnvValid = !isNaN(
        Number.parseInt(screensaverTimeoutEnv ?? "")
      );

      const logoutTimeoutValue = logoutTimeoutEnvValid
        ? Number.parseInt(logoutTimeoutEnv)
        : 5;
      const screensaverTimeoutValue = screensaverTimeoutEnvValid
        ? Number.parseInt(screensaverTimeoutEnv)
        : 10;

      const LOGOUT_THRESHOLD = moment
        .duration(logoutTimeoutValue, "minutes")
        .asMilliseconds();
      const SCREENSAVER_THRESHOLD = moment
        .duration(screensaverTimeoutValue, "minutes")
        .asMilliseconds();

      if (lastActivityTime) {
        // check if user should be logged out
        if (+lastActivityTime + LOGOUT_THRESHOLD < Date.now() && isLoggedIn) {
          console.debug(
            `User logged out due to inactivity [timeout ${logoutTimeoutValue} minutes]`
          );
          logoutAction();
        }

        // check if screenSaver should be shown
        if (
          +lastActivityTime + SCREENSAVER_THRESHOLD < Date.now() &&
          !displayScreenSaver
        ) {
          console.debug(
            `Screensaver activated due to inactivity [timeout ${screensaverTimeoutValue} minutes]`
          );
          setDisplayScreenSaver(true);
        }
      }
    }, 10000); // check if user is active (10s)

    return () => {
      clearInterval(timer);
    };
  }, [envConfig, displayScreenSaver, isLoggedIn, clearUserData, onLogout]);

  return displayScreenSaver ? <ScreenSaverPage /> : children;
};

export default AppActivityGuard;
