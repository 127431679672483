export enum TypZiadostiEnum {
  Z010101 = "Z010101",
  Z010102 = "Z010102",
  Z010201 = "Z010201",
  Z020001 = "Z020001",
  Z030001 = "Z030001",
  Z030002 = "Z030002",
  Z030003 = "Z030003",
  Z030004 = "Z030004",
  Z040001 = "Z040001",
  Z040002 = "Z040002",
  Z050001 = "Z050001",
  Z050002 = "Z050002",
  Z060001 = "Z060001",
  Z070101 = "Z070101",
  Z070102 = "Z070102",
  Z070201 = "Z070201",
  Z070301 = "Z070301",
  Z080001 = "Z080001",
  Z080101 = "Z080101",
  Z090001 = "Z090001",
  Z090101 = "Z090101",
  Z090102 = "Z090102",
  Z090301 = "Z090301",
  Z090401 = "Z090401",
  Z090501 = "Z090501",
  Z090502 = "Z090502",
  Z090601 = "Z090601",
  Z090701 = "Z090701",
  Z100101 = "Z100101",
  Z100201 = "Z100201",
  Z100301 = "Z100301",
  Z100401 = "Z100401",
  Z110101 = "Z110101",
  Z110102 = "Z110102",
  Z110201 = "Z110201",
  Z110202 = "Z110202",
  Z110301 = "Z110301",
  Z110302 = "Z110302",
  Z110401 = "Z110401",
  Z110402 = "Z110402",
  Z120001 = "Z120001",
  Z130001 = "Z130001",
  Z130002 = "Z130002",
  Z150001 = "Z150001",
  Z140001 = "Z140001",
  Z150002 = "Z150002",
  Z030005 = "Z030005"
}