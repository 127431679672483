import { defer, useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { EOO_Post } = await API_Clients();

  const data = await Promise.all([
    EOO_Post("/api/VazbyATresty/ListTrestData", {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }),
    EOO_Post("/api/VazbyATresty/ListVazbaData", {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [tresty klienta]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [väzby klienta]");
  }

  return {
    trestData: data[0],
    vazbaData: data[1],
  };
};

export const TrestyAVazbyTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface TrestyAVazbyTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const TrestyAVazbyTab = (props: TrestyAVazbyTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsTable
        data={{
          label: capitalize(tui("zoznam trestov")),
          header: [
            {
              value: capitalize(tui("spisová značka")),
              align: "left",
            },
            {
              value: capitalize(tui("roky")),
              align: "center",
              width: 90,
            },
            {
              value: capitalize(tui("mesiace")),
              align: "center",
              width: 90,
            },
            {
              value: capitalize(tui("dni")),
              align: "center",
              width: 90,
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
              width: 150,
            },
          ],
          body:
            props.data.trestData.data.records?.map((trest) => {
              const rd: RowData = {
                row: [
                  {
                    value: trest.spisovaZnacka ?? "",
                    align: "left",
                  },
                  {
                    value: trest.roky ?? "",
                    align: "center",
                    width: 90,
                  },
                  {
                    value: trest.mesiace ?? "",
                    align: "center",
                    width: 90,
                  },
                  {
                    value: trest.dni ?? "",
                    align: "center",
                    width: 90,
                  },
                  {
                    value: (
                      <ZvjsButton
                        text={capitalize(tui("detail"))}
                        zvjsVariant="secondaryAction"
                        startIcon={
                          <DetailIcon
                            style={{ fill: "white", width: 28, height: 28 }}
                          />
                        }
                        onClick={() => {
                          navigate(`Punishment/${trest.id}`);
                        }}
                      />
                    ),
                    align: "right",
                    width: 150,
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={{
          label: capitalize(tui("zoznam väzieb")),
          header: [
            {
              value: capitalize(tui("spisová značka")),
              align: "left",
            },
            {
              value: capitalize(tui("začiatok väzby")),
              align: "center",
              width: 200,
            },
            {
              value: capitalize(tui("aktívna")),
              align: "center",
              width: 90,
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
              width: 150,
            },
          ],
          body:
            props.data.vazbaData.data.records?.map((vazba) => {
              const rd: RowData = {
                row: [
                  {
                    value: vazba.spisovaZnacka ?? "",
                    align: "left",
                  },
                  {
                    value:
                      vazba.zaciatokVazby
                        ? new Date(vazba.zaciatokVazby).zvjsToString()
                        : "",
                    align: "center",
                    width: 200,
                  },
                  {
                    value: vazba.aktivna ? "áno" : "nie",
                    align: "center",
                    width: 90,
                  },
                  {
                    value: (
                      <ZvjsButton
                        text={capitalize(tui("detail"))}
                        zvjsVariant="secondaryAction"
                        startIcon={
                          <DetailIcon
                            style={{ fill: "white", width: 28, height: 28 }}
                          />
                        }
                        onClick={() => {
                          navigate(`Detention/${vazba.id}`);
                        }}
                      />
                    ),
                    align: "right",
                    width: 150,
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(TrestyAVazbyTab);
