import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050301",
  basicInfo: [
    {
      text: "ziadost.ziadostOodoslanieKorespondencie.zakladneInfo",
    },
  ],
  dataCalculations: {
    SC_KIO_050301_QUESTION_CONTROL: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050301_IS_ALLOWED_TO_REQUEST_CORRESPONDENCE: {
      calculateAt: Calculate.LIVE,
    },
  },
  counters: ["/api/CisDovodNaOdoslanieKorespondencie/List"],
  items: [
    {
      id: "SC_KIO_050301_NOT_ALLOWED_TO_SEND_REQUEST_LABELS",
      type: ZvjsCustomQuestionTypes.SC_KIO_050301_NOT_ALLOWED_TO_SEND_REQUEST_LABELS,
      title: "",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050301_IS_ALLOWED_TO_REQUEST_CORRESPONDENCE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "dovodOdoslaniaKorespondencie",
      title:
        "ziadost.ziadostOodoslanieKorespondencie.labelDovodOdoslaniaKorespondencie",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050301_IS_ALLOWED_TO_REQUEST_CORRESPONDENCE",
          conditionMetWhenValueIs: true,
        },
      ],
      options: {
        answerId: "dovodOdoslaniaKorespondencie",
        counterKey: "/api/CisDovodNaOdoslanieKorespondencie/List",
        textKey: "dovod",
        children: [
          {
            id: "LIST",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "URADNA",
          },
        ],
      },
    },
    {
      id: "pocetKorespondencie",
      title:
        "ziadost.ziadostOodoslanieKorespondencie.labelPocetSukromnejKorešpondencie",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodOdoslaniaKorespondencie",
          value: [{ dovodOdoslaniaKorespondencie: "LIST" }],
        },
      ],
      options: [
        {
          id: "1",
          label: "1",
        },
        {
          id: "2",
          label: "2",
        },
      ],
    },
    {
      id: "oznacenieKonania",
      title:
        "Označenie konania, v ktorom väznená osoba uplatňuje svoje právo účastníka konania a potrebuje k tomu odoslať korešpondenciu",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: false,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodOdoslaniaKorespondencie",
          value: [{ dovodOdoslaniaKorespondencie: "URADNA" }],
        },
      ],
    },
    {
      text: "ziadost.ziadostOodoslanieKorespondencie.napovedaTrestneKonanie",
      type: ZvjsItemTypes.LABEL,
      flag: ZvjsLabelFlagTypes.INFO,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodOdoslaniaKorespondencie",
          value: [{ dovodOdoslaniaKorespondencie: "URADNA" }],
        },
      ],
    },
    {
      id: "trestneKonanie",
      title: "Trestné konania",
      type: ZvjsCustomQuestionTypes.SC_KIO_050301_CRIMINAL_PROCEEDINGS_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodOdoslaniaKorespondencie",
          value: [{ dovodOdoslaniaKorespondencie: "URADNA" }],
        },
      ],
    },
  ],
};
