import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { ZvjsTable } from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { API_Clients } from "../../../store/context/dataApi/Data";
import moment from "moment";

const loadData = async () => {
  const { EOO_Post } = await API_Clients();

  const data = await Promise.all([
    EOO_Post("/api/DalsieOsobneUdaje/ListPobytKlienta", {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }),
  ]);

  const records = data[0]?.data?.records || [];

  const zakladneCislo = Number(localStorage.getItem("zakladneCislo"));
  const filteredPobyty = records?.filter((item) => item.zakladneCislo === zakladneCislo);

  return {
    pobytyVUstavoch: filteredPobyty,
  };
};

export const MiestoVykonuVazbyAVykonuTrestuLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface MiestoVykonuVazbyAVykonuTrestuProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const MiestoVykonuVazbyAVykonuTrestu = (
  props: MiestoVykonuVazbyAVykonuTrestuProps
) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              tui(
                "osobneUdaje.kategorieOsobnychUdajov.miestoVykonuVazbyVykonuTrestu"
              )
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={{
          label: capitalize(tui("osobneUdaje.pobytyVustavoch.pobytyVustavoch")),
          header: [
            {
              value: capitalize(tui("osobneUdaje.pobytyVustavoch.ustav")),
              align: "left",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("osobneUdaje.pobytyVustavoch.datumOd")),
              align: "left",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("osobneUdaje.pobytyVustavoch.casOdchodu")),
              align: "left",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("osobneUdaje.pobytyVustavoch.datumDo")),
              align: "left",
              width: "calc(100% / 4)",
            },
          ],
          body:
            props.data.pobytyVUstavoch?.map(
              (miestoVykonuVazbyAVykonuTrestu) => {
                const rd: RowData = {
                  row: [
                    {
                      value:
                        miestoVykonuVazbyAVykonuTrestu.ustavPrepusteniaSkratka ??
                        "",
                      align: "left",
                      width: "calc(100% / 4)",
                    },
                    {
                      value: new Date(
                        miestoVykonuVazbyAVykonuTrestu.datumNastupu ??
                          new Date()
                      ).zvjsToString(),
                      align: "left",
                      width: "calc(100% / 4)",
                    },
                    {
                      value: miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                        ? moment(
                            new Date(
                              miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                            )
                          ).format("H:mm")
                        : "",
                      align: "left",
                      width: "calc(100% / 4)",
                    },
                    {
                      value: miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                        ? new Date(
                            miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                          ).zvjsToString()
                        : "",
                      align: "left",
                      width: "calc(100% / 4)",
                    },
                  ],
                };
                return rd;
              }
            ) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(MiestoVykonuVazbyAVykonuTrestu);
