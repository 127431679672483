import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { Grid } from "@mui/material";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../../router/LoaderError";
import { isAccused, isSentenced } from "../../../../../../utils/dataFetchers";
import ZvjsCheckBoxNew from "../../../../../components/ZvjsCheckBoxNew";

interface CheckBoxQuestionProps {
  location: number[];
}

const SC_KIO_050701_isAnyClosePersonInPrison = async (): Promise<boolean> => {
  const { EOO_Post } = await API_Clients();

  const response = await EOO_Post(
    "/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
    {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }
  );

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  for (const record of response.data.records ?? []) {
    if (record.id && record.fyzickaOsobaId) {
      const klientListResponse = await EOO_Post("/api/Klient/List", {
        body: { filters: [{ fyzickaOsobaId: record.fyzickaOsobaId }] },
      });

      if (klientListResponse.error || !klientListResponse.response.ok) {
        throw new LoaderError();
      }

      for (const clientData of klientListResponse.data.records ?? []) {
        const isAccusedResult = await isAccused({
          klientId: clientData.id ?? NaN,
          ustavZvjsId: clientData.ustavZvjsId ?? NaN,
        });

        const isSentencedResult = await isSentenced({
          klientId: clientData.id ?? NaN,
          ustavZvjsId: clientData.ustavZvjsId ?? NaN,
        });

        if (isAccusedResult || isSentencedResult) {
          return true;
        }
      }
    }
  }

  return false;
};

const SingleCheckBoxDisabledQuestion: React.FC<CheckBoxQuestionProps> = ({
  location,
}) => {
  const { tuiz } = useUITranslation();

  const singleCheckBoxData = useSelector(
    questionnaireRedux.selectors.getSingleCheckBoxDisplayData(location)
  );

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const checkPrisonStatus = async () => {
      const result = await SC_KIO_050701_isAnyClosePersonInPrison();
      setIsChecked(result);
    };

    checkPrisonStatus();
  }, []);

  return (
    <Grid item xs>
      <ZvjsCheckBoxNew
        label={""}
        data={[
          {
            label: tuiz(singleCheckBoxData.title),
            key: singleCheckBoxData.id,
            checked: isChecked,
            disabled: true,
          },
        ]}
        offset={3}
      />
    </Grid>
  );
};

export default SingleCheckBoxDisabledQuestion;
