import { paths as auditing_v1_paths } from "../../../api/types/auditing_v1";
import { paths as cis_v1_paths } from "../../../api/types/cis_v1";
import { paths as edpoo_v1_paths } from "../../../api/types/edpoo_v1";
import { paths as eoo_v1_paths } from "../../../api/types/eoo_v1";
import { paths as epvvvt_v1_paths } from "../../../api/types/epvvvt_v1";
import { paths as er_v1_paths } from "../../../api/types/er_v1";
import { paths as ezoo_v1_paths } from "../../../api/types/ezoo_v1";
import { paths as feoo_v1_paths } from "../../../api/types/feoo_v1";
import { paths as hasura_v1_paths } from "../../../api/types/hasura_v1";
import { paths as rezervacia_knih_openapi_v1_paths } from "../../../api/types/rezervacia-knih-openapi_v1";
import { paths as share_int_v1_paths } from "../../../api/types/share-int_v1";
import { paths as sidkoo_v1_paths } from "../../../api/types/sidkoo_v1";
import { paths as szoo_v1_paths } from "../../../api/types/szoo_v1";
import { paths as zvjs_mp_v1_paths } from "../../../api/types/zvjs-mp_v1";

import { Module } from "../../../utils/module";
import { BaseApiCallHeaders } from "../../../utils/targetApi";

import { dbPromise } from "../../../services/database";
import { KEY_SVC_URL } from "../envConfigContext";

import createClient, {
  FetchResponse,
  Middleware,
  RequestOptions,
} from "openapi-fetch";

const host = async (): Promise<string | undefined> => {
  const database = await dbPromise;
  return database.get("EnvironmentVariables", KEY_SVC_URL);
};

export const MAX_NUMBER_OF_ITEMS = 999999;

export const API_Clients = async () => {
  const middleware: Middleware = {
    async onRequest({ request, options }) {
      let routeDescription = `Request: module[${request.headers.get(
        "X-Zvjs-Module"
      )}] method[${request.method}] api[${request.url}]`;

      if (request.method === "POST" || request.method === "PUT") {
        routeDescription += ` body[${await request.clone().text()}]`;
      }

      console.debug(routeDescription);
    },

    async onResponse({ request, response, options }) {
      let routeDescription = `Response: module[${request.headers.get(
        "X-Zvjs-Module"
      )}] method[${request.method}] api[${request.url}] response[${
        response.ok ? "ok" : "error"
      }] status[${response.status}/${response.statusText}]`;

      // if (request.method === "POST" || request.method === "PUT") {
      //   routeDescription += ` body[${JSON.stringify(await request.json())}]`;
      // }

      if (response.ok) {
        console.info(routeDescription);
      } else {
        routeDescription += ` error[${await response.clone().text()}]`;
        console.error(routeDescription);
      }
    },
  };

  const auditingClient = createClient<auditing_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.AUDITING,
    },
  });

  const cisClient = createClient<cis_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.CIS },
  });

  const edpooClient = createClient<edpoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.EDPOO },
  });

  const eooClient = createClient<eoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.EOO },
  });

  const epvvvtClient = createClient<epvvvt_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.EPVVVT,
    },
  });

  const erClient = createClient<er_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.ER,
    },
  });

  const ezooClient = createClient<ezoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.EZOO },
  });

  const feooClient = createClient<feoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.FEOO },
  });

  const hasuraClient = createClient<hasura_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.HASURA,
    },
  });

  const rezervaciaKnihClient = createClient<rezervacia_knih_openapi_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.ZVJS_MP,
    },
  });

  const shareIntClient = createClient<share_int_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.SHARE_INT,
    },
  });

  const sidkooClient = createClient<sidkoo_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.SIDKOO,
    },
  });

  const szooClient = createClient<szoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.SZOO },
  });

  const szooLocalClient = createClient<szoo_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.SZOO,
      "X-Zvjs-Szoo": "local",
    },
  });

  const zvjsMpClient = createClient<zvjs_mp_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.ZVJS_MP,
    },
  });

  auditingClient.use(middleware);
  cisClient.use(middleware);
  edpooClient.use(middleware);
  eooClient.use(middleware);
  epvvvtClient.use(middleware);
  erClient.use(middleware);
  ezooClient.use(middleware);
  feooClient.use(middleware);
  hasuraClient.use(middleware);
  rezervaciaKnihClient.use(middleware);
  shareIntClient.use(middleware);
  sidkooClient.use(middleware);
  szooClient.use(middleware);
  zvjsMpClient.use(middleware);

  const { GET: AUDITING_Get, POST: AUDITING_Post } = auditingClient;
  const { GET: CIS_Get, POST: CIS_Post } = cisClient;
  const { GET: EDPOO_Get, POST: EDPOO_Post } = edpooClient;
  const { GET: EOO_Get, POST: EOO_Post } = eooClient;
  const { GET: EPVVVT_Get, POST: EPVVVT_Post } = epvvvtClient;
  const { POST: ER_Post } = erClient;
  const { GET: EZOO_Get, POST: EZOO_Post } = ezooClient;
  const { GET: FEOO_Get, POST: FEOO_Post } = feooClient;
  const { GET: HASURA_Get, POST: HASURA_Post, PUT: HASURA_Put } = hasuraClient;
  const { GET: REZERVACIA_KNIH_Get, POST: REZERVACIA_KNIH_Post } =
    rezervaciaKnihClient;
  const { GET: SHARE_INT_Get, POST: SHARE_INT_Post } = shareIntClient;
  const { GET: SIDKOO_Get, POST: SIDKOO_Post } = sidkooClient;
  const { GET: SZOO_Get, POST: SZOO_Post } = szooClient;
  const { GET: SZOO_Local_Get, POST: SZOO_Local_Post } = szooLocalClient;
  const { GET: ZVJS_MP_Get, POST: ZVJS_MP_Post } = zvjsMpClient;

  return {
    AUDITING_Get,
    AUDITING_Post,
    CIS_Get,
    CIS_Post,
    EDPOO_Get,
    EDPOO_Post,
    EOO_Get,
    EOO_Post,
    EPVVVT_Get,
    EPVVVT_Post,
    ER_Post,
    EZOO_Get,
    EZOO_Post,
    FEOO_Get,
    FEOO_Post,
    HASURA_Get,
    HASURA_Post,
    HASURA_Put,
    REZERVACIA_KNIH_Get,
    REZERVACIA_KNIH_Post,
    SHARE_INT_Get,
    SHARE_INT_Post,
    SIDKOO_Get,
    SIDKOO_Post,
    SZOO_Get,
    SZOO_Post,
    SZOO_Local_Get,
    SZOO_Local_Post,
    ZVJS_MP_Get,
    ZVJS_MP_Post,
  };
};

/**
 * BaseAPI contains all available APIs
 */
type BaseAPI =
  | auditing_v1_paths
  | cis_v1_paths
  | edpoo_v1_paths
  | eoo_v1_paths
  | epvvvt_v1_paths
  | er_v1_paths
  | ezoo_v1_paths
  | feoo_v1_paths
  | hasura_v1_paths
  | rezervacia_knih_openapi_v1_paths
  | share_int_v1_paths
  | sidkoo_v1_paths
  | szoo_v1_paths
  | zvjs_mp_v1_paths;

/**
 * ModuleForAPI extracts module name for each API
 */
type ModuleForAPI<API extends BaseAPI> = API extends auditing_v1_paths
  ? Module.AUDITING
  : API extends cis_v1_paths
    ? Module.CIS
    : API extends edpoo_v1_paths
      ? Module.EDPOO
      : API extends eoo_v1_paths
        ? Module.EOO
        : API extends epvvvt_v1_paths
          ? Module.EPVVVT
          : API extends er_v1_paths
            ? Module.ER
            : API extends ezoo_v1_paths
              ? Module.EZOO
              : API extends feoo_v1_paths
                ? Module.FEOO
                : API extends hasura_v1_paths
                  ? Module.HASURA
                  : API extends rezervacia_knih_openapi_v1_paths
                    ? Module.ZVJS_MP
                    : API extends share_int_v1_paths
                      ? Module.SHARE_INT
                      : API extends sidkoo_v1_paths
                        ? Module.SIDKOO
                        : API extends szoo_v1_paths
                          ? Module.SZOO
                          : API extends zvjs_mp_v1_paths
                            ? Module.ZVJS_MP
                            : never;
/**
 * ModulePrefixAPIPath adds module name prefix to each API path
 */
type ModulePrefixAPIPath<T extends BaseAPI> = {
  [K in keyof T as `/${ModuleForAPI<T>}${K & string}`]: T[K];
};

/**
 * RequestOptionPrefixAPIPath adds request_options and module name prefix to each API path
 */
type RequestOptionPrefixAPIPath<T extends BaseAPI> = {
  [K in keyof T as `/request_options/${ModuleForAPI<T>}${K & string}`]: T[K];
};

/* eslint-disable @typescript-eslint/no-empty-interface */
interface MOD_auditing_v1_paths
  extends ModulePrefixAPIPath<auditing_v1_paths> {}
interface MOD_cis_v1_paths extends ModulePrefixAPIPath<cis_v1_paths> {}
interface MOD_edpoo_v1_paths extends ModulePrefixAPIPath<edpoo_v1_paths> {}
interface MOD_eoo_v1_paths extends ModulePrefixAPIPath<eoo_v1_paths> {}
interface MOD_epvvvt_v1_paths extends ModulePrefixAPIPath<epvvvt_v1_paths> {}
interface MOD_er_v1_paths extends ModulePrefixAPIPath<er_v1_paths> {}
interface MOD_ezoo_v1_paths extends ModulePrefixAPIPath<ezoo_v1_paths> {}
interface MOD_feoo_v1_paths extends ModulePrefixAPIPath<feoo_v1_paths> {}
interface MOD_hasura_v1_paths extends ModulePrefixAPIPath<hasura_v1_paths> {}
interface MOD_share_int_v1_paths
  extends ModulePrefixAPIPath<share_int_v1_paths> {}
interface MOD_rezervacia_knih_openapi_v1_paths
  extends ModulePrefixAPIPath<rezervacia_knih_openapi_v1_paths> {}
interface MOD_sidkoo_v1_paths extends ModulePrefixAPIPath<sidkoo_v1_paths> {}
interface MOD_szoo_v1_paths extends ModulePrefixAPIPath<szoo_v1_paths> {}
interface MOD_zvjs_mp_v1_paths extends ModulePrefixAPIPath<zvjs_mp_v1_paths> {}
/*  eslint-enable @typescript-eslint/no-empty-interface */

/**
 * MOD_OpenAPI contains all available APIs with module name prefix
 */
type MOD_OpenAPI = MOD_auditing_v1_paths &
  MOD_cis_v1_paths &
  MOD_edpoo_v1_paths &
  MOD_eoo_v1_paths &
  MOD_epvvvt_v1_paths &
  MOD_er_v1_paths &
  MOD_ezoo_v1_paths &
  MOD_feoo_v1_paths &
  MOD_hasura_v1_paths &
  MOD_rezervacia_knih_openapi_v1_paths &
  MOD_share_int_v1_paths &
  MOD_sidkoo_v1_paths &
  MOD_szoo_v1_paths &
  MOD_zvjs_mp_v1_paths;

/* eslint-disable @typescript-eslint/no-empty-interface */
interface REQ_auditing_v1_paths
  extends RequestOptionPrefixAPIPath<auditing_v1_paths> {}
interface REQ_cis_v1_paths extends RequestOptionPrefixAPIPath<cis_v1_paths> {}
interface REQ_edpoo_v1_paths
  extends RequestOptionPrefixAPIPath<edpoo_v1_paths> {}
interface REQ_eoo_v1_paths extends RequestOptionPrefixAPIPath<eoo_v1_paths> {}
interface REQ_epvvvt_v1_paths
  extends RequestOptionPrefixAPIPath<epvvvt_v1_paths> {}
interface REQ_er_v1_paths extends RequestOptionPrefixAPIPath<er_v1_paths> {}
interface REQ_ezoo_v1_paths extends RequestOptionPrefixAPIPath<ezoo_v1_paths> {}
interface REQ_feoo_v1_paths extends RequestOptionPrefixAPIPath<feoo_v1_paths> {}
interface REQ_hasura_v1_paths
  extends RequestOptionPrefixAPIPath<hasura_v1_paths> {}
interface REQ_rezervaia_knih_openapi_v1_paths
  extends RequestOptionPrefixAPIPath<rezervacia_knih_openapi_v1_paths> {}
interface REQ_share_int_v1_paths
  extends RequestOptionPrefixAPIPath<share_int_v1_paths> {}
interface REQ_sidkoo_v1_paths
  extends RequestOptionPrefixAPIPath<sidkoo_v1_paths> {}
interface REQ_szoo_v1_paths extends RequestOptionPrefixAPIPath<szoo_v1_paths> {}
interface REQ_zvjs_mp_v1_paths
  extends RequestOptionPrefixAPIPath<zvjs_mp_v1_paths> {}
/* eslint-enable @typescript-eslint/no-empty-interface */

/**
 * REQ_OpenAPI contains all available APIs with module name prefix and request_options prefix
 */
type REQ_OpenAPI = REQ_auditing_v1_paths &
  REQ_cis_v1_paths &
  REQ_edpoo_v1_paths &
  REQ_eoo_v1_paths &
  REQ_epvvvt_v1_paths &
  REQ_er_v1_paths &
  REQ_ezoo_v1_paths &
  REQ_feoo_v1_paths &
  REQ_hasura_v1_paths &
  REQ_rezervaia_knih_openapi_v1_paths &
  REQ_share_int_v1_paths &
  REQ_sidkoo_v1_paths &
  REQ_szoo_v1_paths &
  REQ_zvjs_mp_v1_paths;

/**
 * REQ_OpenAPI_KEYS contains all available APIs keys prefixed with request_options and module name
 */
type REQ_OpenAPI_KEYS = keyof REQ_OpenAPI;

/**
 * SafeExtract_REQ_OpenAPI_KEYS extracts only those APIs which are available in the application
 */
type SafeExtract_REQ_OpenAPI_KEYS<
  REQ_OpenAPI_KEYS,
  U extends REQ_OpenAPI_KEYS,
> = Extract<U, REQ_OpenAPI_KEYS>;

/**
 * DataRequestOptionsInputTypeList contains APIs keys prefixed with request_options and module name
 * that are used in application
 */
type DataRequestOptionsInputTypeList = SafeExtract_REQ_OpenAPI_KEYS<
  REQ_OpenAPI_KEYS,
  | "/request_options/auditing/api/AuditLog/Create"
  | "/request_options/cis/api/CisDochodokDruh/List"
  | "/request_options/cis/api/CisDovodCastejsiehoSprchovania/List"
  | "/request_options/cis/api/CisDovodMimoriadnejNavstevy/List"
  | "/request_options/cis/api/CisDovodNaOdoslanieKorespondencie/List"
  | "/request_options/cis/api/CisDovodPodaniaZiadostiElektrospotrebic/List"
  | "/request_options/cis/api/CisDovodPodaniaZiadosti/List"
  | "/request_options/cis/api/CisDovodPozadovanejObmeny/List"
  | "/request_options/cis/api/CisDovodZiadostiOrozhovor/List"
  | "/request_options/cis/api/CisDovodZiadostiSprchovanie/List"
  | "/request_options/cis/api/CisDovodZiadosti/List"
  | "/request_options/cis/api/CisDruhBalika/List"
  | "/request_options/cis/api/CisDruhElektrospotrebica/List"
  | "/request_options/cis/api/CisDruhNedostatkuUpresnenie/List"
  | "/request_options/cis/api/CisFormaRealizacieNavstevy/List"
  | "/request_options/cis/api/CisFyzickePrejavyProblemov/List"
  | "/request_options/cis/api/CisKategoriaZiadosti/List"
  | "/request_options/cis/api/CisKrajina/List"
  | "/request_options/cis/api/CisMiestoNedostatku/List"
  | "/request_options/cis/api/CisOblastiPsychickychProblemov/List"
  | "/request_options/cis/api/CisOblastSocialnehoPoradenstva/List"
  | "/request_options/cis/api/CisOblastZdravotnychProblemov/List"
  | "/request_options/cis/api/CisOdievanie/List"
  | "/request_options/cis/api/CisOdpovedPreKlienta/List"
  | "/request_options/cis/api/CisPodkategoriaZiadosti/List"
  | "/request_options/cis/api/CisPodnetNaOchranu/List"
  | "/request_options/cis/api/CisPreventivneZdravPrehliadky/List"
  | "/request_options/cis/api/CisPrijimaniePenazProstriedkov/List"
  | "/request_options/cis/api/CisPsychickyStav/List"
  | "/request_options/cis/api/CisVystrojneSucasti/List"
  | "/request_options/cis/api/CisSpanok/List"
  | "/request_options/cis/api/CisSposobOdoslaniaPozvanky/List"
  | "/request_options/cis/api/CisSposobOdovzdaniaCeniny/List"
  | "/request_options/cis/api/CisSposobZabezpeceniaHygNezavadnosti/List"
  | "/request_options/cis/api/CisStavZiadosti/List"
  | "/request_options/cis/api/CisStrach/List"
  | "/request_options/cis/api/CisStravovanie/List"
  | "/request_options/cis/api/CisStupenVzdelania/List"
  | "/request_options/cis/api/CisTrvanieNavstevy/List"
  | "/request_options/cis/api/CisTypBlizkejOsoby/List"
  | "/request_options/cis/api/CisTypOdoslaniaProstriedkov/List"
  | "/request_options/cis/api/CisTypOsobnejVeci/List"
  | "/request_options/cis/api/CisTypPremiestnenia/List"
  | "/request_options/cis/api/CisTypZiadosti/List"
  | "/request_options/cis/api/CisUbytovanie/List"
  | "/request_options/cis/api/CisUIrozhranieTextacie/List"
  | "/request_options/cis/api/CisUIrozhranieZiadosti/List"
  | "/request_options/cis/api/CisUpresnenieOblastiZdravotnychProblemov/List"
  | "/request_options/cis/api/CisUstavZvjs/List"
  | "/request_options/cis/api/CisVeciOsobnejPotreby/List"
  | "/request_options/cis/api/CisVeritelTyp/List"
  | "/request_options/cis/api/CisZabezpeceniePrav/List"
  | "/request_options/cis/api/CisZdravotnaStarostlivost/List"
  | "/request_options/cis/api/CisZdrojNapajania/List"
  | "/request_options/cis/api/CisZdrojZiadosti/List"
  | "/request_options/cis/api/CisZiadosti/List"
  | "/request_options/cis/api/DalsiePotrebyOh/List"
  | "/request_options/cis/api/DruhStravy/List"
  | "/request_options/cis/api/StavOsobnejVeci/List"
  | "/request_options/edpoo/api/OsobneVeci/ListElektrospotrebic"
  | "/request_options/edpoo/api/OsobneVeci/ListOsobnaVec"
  | "/request_options/edpoo/api/OsobneVeci/ListOsobnyDoklad"
  | "/request_options/edpoo/api/OsobneVeci/ListZbranAStrelivo"
  | "/request_options/edpoo/api/SkladDpoh/ListStavSkladu"
  | "/request_options/edpoo/api/Stravovanie/List"
  | "/request_options/eoo/api/Administracia/PravnyZastupcaData"
  | "/request_options/eoo/api/Adresa/CreateAdresa"
  | "/request_options/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj"
  | "/request_options/eoo/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam"
  | "/request_options/eoo/api/DalsieOsobneUdaje/ListPobytKlienta"
  | "/request_options/eoo/api/DalsieOsobneUdaje/TrestneParagrafyTrestu"
  | "/request_options/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta"
  | "/request_options/eoo/api/FyzickaOsoba/CreateAdresaOsoba"
  | "/request_options/eoo/api/FyzickaOsoba/CreateFyzickaOsoba"
  | "/request_options/eoo/api/FyzickaOsoba/CreateKontakt"
  | "/request_options/eoo/api/FyzickaOsoba/ListAdresaOsoba"
  | "/request_options/eoo/api/FyzickaOsoba/ListKontakt"
  | "/request_options/eoo/api/FyzickaOsoba/UpdateAdresaOsoba"
  | "/request_options/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba"
  | "/request_options/eoo/api/FyzickaOsoba/UpdateKontakt"
  | "/request_options/eoo/api/Klient/List"
  | "/request_options/eoo/api/Klient/ListRodinnyStavKlientaData"
  | "/request_options/eoo/api/Klient/ListTrestneKonanieData"
  | "/request_options/eoo/api/Klient/ListNepravoplatnyTrestOosData"
  | "/request_options/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData"
  | "/request_options/eoo/api/PravnickaOsoba/List"
  | "/request_options/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList"
  | "/request_options/eoo/api/Rozhodnutie/ListRozhodnutieData"
  | "/request_options/eoo/api/VazbyATresty/DetailTDVData"
  | "/request_options/eoo/api/VazbyATresty/DetailTrestData"
  | "/request_options/eoo/api/VazbyATresty/DetailVazbaData"
  | "/request_options/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData"
  | "/request_options/eoo/api/VazbyATresty/ListTrestData"
  | "/request_options/eoo/api/VazbyATresty/ListVazbaData"
  | "/request_options/epvvvt/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory"
  | "/request_options/epvvvt/api/interfaces/Epvvvt/ListPoucenia"
  | "/request_options/epvvvt/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho"
  | "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky"
  | "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne"
  | "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit"
  | "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania"
  | "/request_options/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti"
  | "/request_options/er/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta"
  | "/request_options/er/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta"
  | "/request_options/er/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi"
  | "/request_options/er/api/interfaces/Er/ListZoznamPoruseniKDisciplinarnemuTrestu"
  | "/request_options/ezoo/api/Integracie/ListKlientNaPracovisku"
  | "/request_options/feoo/api/Blokacia/List"
  | "/request_options/feoo/api/Codelist/ListBlokaciaTyp"
  | "/request_options/feoo/api/Codelist/ListDiferenciacnaSkupina"
  | "/request_options/feoo/api/Codelist/ListStupenStrazenia"
  | "/request_options/feoo/api/Codelist/ListTypPohybu"
  | "/request_options/feoo/api/Codelist/ListTypVeritela"
  | "/request_options/feoo/api/integracie/Kiosk/ListCeniny"
  | "/request_options/feoo/api/integracie/Kiosk/ListCudziaMena"
  | "/request_options/feoo/api/integracie/Kiosk/ListStavKontaAKreditu"
  | "/request_options/feoo/api/integracie/Kiosk/ListVyplatnaPaska"
  | "/request_options/feoo/api/KlientUni/List"
  | "/request_options/feoo/api/Pohladavka/List"
  | "/request_options/feoo/api/Pohladavka/ListSplatkyPohladavky"
  | "/request_options/feoo/api/PohybyNaKonte/List"
  | "/request_options/feoo/api/VyzivovaciaPovinnost/List"
  | "/request_options/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti"
  | "/request_options/hasura/api/rest/get-kiosk-notifikacie-o-suboroch"
  | "/request_options/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch"
  | "/request_options/hasura/api/rest/v1/kiosk-ziadost-create/{id}"
  | "/request_options/share_int/api/interfaces/Cis/ListEpvvvtCisOraNeformalneStandVzdelavA"
  | "/request_options/share_int/api/interfaces/Cis/ListEpvvvtCisProgramZaobOblastiProgramuZaobA"
  | "/request_options/share_int/api/interfaces/Cis/ListEpvvvtCisProgramZaobchadzaniaStavHodnoteniaPzA"
  | "/request_options/share_int/api/interfaces/Cis/ListSzooCisDruhCivilnychVeciA"
  | "/request_options/share_int/api/interfaces/Cis/ListSzooCisResocializacnyProgramA"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListKlientZaradenyDoVo"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListOraVolnocasovaAktivita"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListOsobaSPovolenimNaTelefonovanie"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListZoznamKruzkov"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListZoznamSkupinovychAktivit"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListZoznamSpecializovanychResocializacnychProgramov"
  | "/request_options/share_int/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev"
  | "/request_options/sidkoo/api/IdKarta/ListIdKartaAktivna"
  | "/request_options/szoo/api/interfaces/Cis/ListEdpoVystrojnaSucastA"
  | "/request_options/szoo/api/interfaces/Cis/ListKioCisVecZastupovanaAdvokatomA"
  | "/request_options/szoo/api/interfaces/Szoo/ListZoznamZiadostiKlienta"
  | "/request_options/szoo/api/Ziadosti/Create"
  | "/request_options/szoo/api/Ziadosti/SpatVzatie"
  | "/request_options/zvjs_mp/bezpecny-internet/list"
  | "/request_options/zvjs_mp/jedalnyListok/list"
  | "/request_options/zvjs_mp/rezervacia"
  | "/request_options/zvjs_mp/rezervacia/list"
  | "/request_options/zvjs_mp/rezervacia/{id}/odoslat"
>;

/**
 * DataRequestOptionsType contains request options for each API
 * that are used in application.
 * Note, that some APIs have different request options for different methods
 */
export type DataRequestOptionsType<T extends DataRequestOptionsInputTypeList> =
  T extends "/request_options/hasura/api/rest/v1/kiosk-ziadost-create/{id}"
    ? RequestOptions<REQ_OpenAPI[T]["put"]>
    : RequestOptions<REQ_OpenAPI[T]["post"]>;

/**
 * DataRequestOptionsType contains request options for each API
 * @description elements of this array are stored in database
 */
export const DataRequestOptionsArray: Array<DataRequestOptionsInputTypeList> = [
  "/request_options/auditing/api/AuditLog/Create",
  "/request_options/eoo/api/Adresa/CreateAdresa",
  "/request_options/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj",
  "/request_options/eoo/api/FyzickaOsoba/CreateAdresaOsoba",
  "/request_options/eoo/api/FyzickaOsoba/CreateFyzickaOsoba",
  "/request_options/eoo/api/FyzickaOsoba/CreateKontakt",
  "/request_options/eoo/api/FyzickaOsoba/UpdateAdresaOsoba",
  "/request_options/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba",
  "/request_options/eoo/api/FyzickaOsoba/UpdateKontakt",
  "/request_options/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch",
  "/request_options/hasura/api/rest/v1/kiosk-ziadost-create/{id}",
  "/request_options/szoo/api/Ziadosti/Create",
  "/request_options/szoo/api/Ziadosti/SpatVzatie",
  "/request_options/zvjs_mp/rezervacia",
  "/request_options/zvjs_mp/rezervacia/{id}/odoslat",
];

/**
 * MOD_OpenAPI_KEYS contains all available APIs keys prefixed with module name
 */
type MOD_OpenAPI_KEYS = keyof MOD_OpenAPI;

/**
 * SafeExtract_MOD_OpenAPI_KEYS extracts only those APIs which are available in the application
 */
type SafeExtract_MOD_OpenAPI_KEYS<
  MOD_OpenAPI_KEYS,
  U extends MOD_OpenAPI_KEYS,
> = Extract<U, MOD_OpenAPI_KEYS>;

/**
 * DataAPI_GET contains all available APIs - GET method
 */
type DataAPI_GET = SafeExtract_MOD_OpenAPI_KEYS<
  MOD_OpenAPI_KEYS,
  | "/edpoo/api/Ciselnik/StavVeci"
  | "/eoo/api/Ciselnik/DruhAdresy"
  | "/eoo/api/Ciselnik/StupenDosiahnutehoVzdelania"
  | "/eoo/api/FyzickaOsoba/DetailData"
  | "/eoo/api/Klient/DetailData"
  | "/eoo/api/Klient/DetailZakladneUdajeKlientaData"
  | "/eoo/api/Klient/XDetailOstatneUdajeData"
  | "/feoo/api/integracie/Kiosk/GetZivotneMinimum"
  | "/feoo/api/Klient/Get"
  | "/feoo/api/KlientUni/Get"
  | "/hasura/api/rest/getcisprevadzky"
  | "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-a"
  | "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-norma-a"
  | "/hasura/api/rest/v1/kiosk-ziadost-by-id/{id}"
  | "/szoo/api/Kio/ClientRequestValidate"
  | "/szoo/api/Ziadosti/Get"
  | "/szoo/api/Ziadosti/GetOdpovedPreKlienta"
>;

/**
 * DataAPI_POST contains all available APIs - POST method
 */
type DataAPI_POST = SafeExtract_MOD_OpenAPI_KEYS<
  MOD_OpenAPI_KEYS,
  | "/auditing/api/AuditLog/Create"
  | "/cis/api/CisDochodokDruh/List"
  | "/cis/api/CisDovodCastejsiehoSprchovania/List"
  | "/cis/api/CisDovodMimoriadnejNavstevy/List"
  | "/cis/api/CisDovodNaOdoslanieKorespondencie/List"
  | "/cis/api/CisDovodPodaniaZiadostiElektrospotrebic/List"
  | "/cis/api/CisDovodPodaniaZiadosti/List"
  | "/cis/api/CisDovodPozadovanejObmeny/List"
  | "/cis/api/CisDovodZiadostiOrozhovor/List"
  | "/cis/api/CisDovodZiadostiSprchovanie/List"
  | "/cis/api/CisDovodZiadosti/List"
  | "/cis/api/CisDruhBalika/List"
  | "/cis/api/CisDruhElektrospotrebica/List"
  | "/cis/api/CisDruhNedostatkuUpresnenie/List"
  | "/cis/api/CisFormaRealizacieNavstevy/List"
  | "/cis/api/CisFyzickePrejavyProblemov/List"
  | "/cis/api/CisKategoriaZiadosti/List"
  | "/cis/api/CisKrajina/List"
  | "/cis/api/CisMiestoNedostatku/List"
  | "/cis/api/CisOblastiPsychickychProblemov/List"
  | "/cis/api/CisOblastSocialnehoPoradenstva/List"
  | "/cis/api/CisOblastZdravotnychProblemov/List"
  | "/cis/api/CisOdievanie/List"
  | "/cis/api/CisOdpovedPreKlienta/List"
  | "/cis/api/CisPodkategoriaZiadosti/List"
  | "/cis/api/CisPodnetNaOchranu/List"
  | "/cis/api/CisPreventivneZdravPrehliadky/List"
  | "/cis/api/CisPrijimaniePenazProstriedkov/List"
  | "/cis/api/CisPsychickyStav/List"
  | "/cis/api/CisSpanok/List"
  | "/cis/api/CisSposobOdoslaniaPozvanky/List"
  | "/cis/api/CisSposobOdovzdaniaCeniny/List"
  | "/cis/api/CisSposobZabezpeceniaHygNezavadnosti/List"
  | "/cis/api/CisStavZiadosti/List"
  | "/cis/api/CisStrach/List"
  | "/cis/api/CisStravovanie/List"
  | "/cis/api/CisStupenVzdelania/List"
  | "/cis/api/CisTrvanieNavstevy/List"
  | "/cis/api/CisTypBlizkejOsoby/List"
  | "/cis/api/CisTypOdoslaniaProstriedkov/List"
  | "/cis/api/CisTypOsobnejVeci/List"
  | "/cis/api/CisTypPremiestnenia/List"
  | "/cis/api/CisTypZiadosti/List"
  | "/cis/api/CisUbytovanie/List"
  | "/cis/api/CisUIrozhranieTextacie/List"
  | "/cis/api/CisUIrozhranieZiadosti/List"
  | "/cis/api/CisUpresnenieOblastiZdravotnychProblemov/List"
  | "/cis/api/CisUstavZvjs/List"
  | "/cis/api/CisVeciOsobnejPotreby/List"
  | "/cis/api/CisVeritelTyp/List"
  | "/cis/api/CisVystrojneSucasti/List"
  | "/cis/api/CisZabezpeceniePrav/List"
  | "/cis/api/CisZdravotnaStarostlivost/List"
  | "/cis/api/CisZdrojNapajania/List"
  | "/cis/api/CisZdrojZiadosti/List"
  | "/cis/api/CisZiadosti/List"
  | "/cis/api/DalsiePotrebyOh/List"
  | "/cis/api/DruhStravy/List"
  | "/cis/api/StavOsobnejVeci/List"
  | "/edpoo/api/OsobneVeci/ListElektrospotrebic"
  | "/edpoo/api/OsobneVeci/ListOsobnaVec"
  | "/edpoo/api/OsobneVeci/ListOsobnyDoklad"
  | "/edpoo/api/OsobneVeci/ListZbranAStrelivo"
  | "/edpoo/api/SkladDpoh/ListStavSkladu"
  | "/edpoo/api/Stravovanie/List"
  | "/eoo/api/Administracia/PravnyZastupcaData"
  | "/eoo/api/Adresa/CreateAdresa"
  | "/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj"
  | "/eoo/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam"
  | "/eoo/api/DalsieOsobneUdaje/ListPobytKlienta"
  | "/eoo/api/DalsieOsobneUdaje/TrestneParagrafyTrestu"
  | "/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta"
  | "/eoo/api/FyzickaOsoba/CreateAdresaOsoba"
  | "/eoo/api/FyzickaOsoba/CreateFyzickaOsoba"
  | "/eoo/api/FyzickaOsoba/CreateKontakt"
  | "/eoo/api/FyzickaOsoba/ListAdresaOsoba"
  | "/eoo/api/FyzickaOsoba/ListKontakt"
  | "/eoo/api/FyzickaOsoba/UpdateAdresaOsoba"
  | "/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba"
  | "/eoo/api/FyzickaOsoba/UpdateKontakt"
  | "/eoo/api/Klient/List"
  | "/eoo/api/Klient/ListRodinnyStavKlientaData"
  | "/eoo/api/Klient/ListTrestneKonanieData"
  | "/eoo/api/Klient/ListNepravoplatnyTrestOosData"
  | "/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData"
  | "/eoo/api/PravnickaOsoba/List"
  | "/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList"
  | "/eoo/api/Rozhodnutie/ListRozhodnutieData"
  | "/eoo/api/VazbyATresty/DetailTDVData"
  | "/eoo/api/VazbyATresty/DetailTrestData"
  | "/eoo/api/VazbyATresty/DetailVazbaData"
  | "/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData"
  | "/eoo/api/VazbyATresty/ListTrestData"
  | "/eoo/api/VazbyATresty/ListVazbaData"
  | "/epvvvt/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory"
  | "/epvvvt/api/interfaces/Epvvvt/ListPoucenia"
  | "/epvvvt/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho"
  | "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky"
  | "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne"
  | "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit"
  | "/epvvvt/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania"
  | "/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti"
  | "/er/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta"
  | "/er/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta"
  | "/er/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi"
  | "/er/api/interfaces/Er/ListZoznamPoruseniKDisciplinarnemuTrestu"
  | "/ezoo/api/Integracie/ListKlientNaPracovisku"
  | "/feoo/api/Blokacia/List"
  | "/feoo/api/Codelist/ListBlokaciaTyp"
  | "/feoo/api/Codelist/ListDiferenciacnaSkupina"
  | "/feoo/api/Codelist/ListStupenStrazenia"
  | "/feoo/api/Codelist/ListTypPohybu"
  | "/feoo/api/Codelist/ListTypVeritela"
  | "/feoo/api/integracie/Kiosk/ListCeniny"
  | "/feoo/api/integracie/Kiosk/ListCudziaMena"
  | "/feoo/api/integracie/Kiosk/ListStavKontaAKreditu"
  | "/feoo/api/integracie/Kiosk/ListVyplatnaPaska"
  | "/feoo/api/KlientUni/List"
  | "/feoo/api/Pohladavka/List"
  | "/feoo/api/Pohladavka/ListSplatkyPohladavky"
  | "/feoo/api/PohybyNaKonte/List"
  | "/feoo/api/VyzivovaciaPovinnost/List"
  | "/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti"
  | "/hasura/api/rest/get-kiosk-notifikacie-o-suboroch"
  | "/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch"
  | "/share_int/api/interfaces/Cis/ListEpvvvtCisOraNeformalneStandVzdelavA"
  | "/share_int/api/interfaces/Cis/ListEpvvvtCisProgramZaobOblastiProgramuZaobA"
  | "/share_int/api/interfaces/Cis/ListEpvvvtCisProgramZaobchadzaniaStavHodnoteniaPzA"
  | "/share_int/api/interfaces/Cis/ListSzooCisDruhCivilnychVeciA"
  | "/share_int/api/interfaces/Cis/ListSzooCisResocializacnyProgramA"
  | "/share_int/api/interfaces/Epvvvt/ListKlientZaradenyDoVo"
  | "/share_int/api/interfaces/Epvvvt/ListOraVolnocasovaAktivita"
  | "/share_int/api/interfaces/Epvvvt/ListOsobaSPovolenimNaTelefonovanie"
  | "/share_int/api/interfaces/Epvvvt/ListZoznamKruzkov"
  | "/share_int/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie"
  | "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie"
  | "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta"
  | "/share_int/api/interfaces/Epvvvt/ListZoznamSkupinovychAktivit"
  | "/share_int/api/interfaces/Epvvvt/ListZoznamSpecializovanychResocializacnychProgramov"
  | "/share_int/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev"
  | "/sidkoo/api/IdKarta/ListIdKartaAktivna"
  | "/szoo/api/interfaces/Cis/ListEdpoVystrojnaSucastA"
  | "/szoo/api/interfaces/Cis/ListKioCisVecZastupovanaAdvokatomA"
  | "/szoo/api/interfaces/Szoo/ListZoznamZiadostiKlienta"
  | "/szoo/api/Ziadosti/Create"
  | "/szoo/api/Ziadosti/SpatVzatie"
  | "/zvjs_mp/administracia-tabletu"
  | "/zvjs_mp/bezpecny-internet/list"
  | "/zvjs_mp/jedalnyListok/list"
  | "/zvjs_mp/rezervacia"
  | "/zvjs_mp/rezervacia/list"
  | "/zvjs_mp/rezervacia/{id}/odoslat"
>;

/**
 * DataAPI_PUT contains all available APIs - PUT method
 */
type DataAPI_PUT = SafeExtract_MOD_OpenAPI_KEYS<
  MOD_OpenAPI_KEYS,
  "/hasura/api/rest/v1/kiosk-ziadost-create/{id}"
>;

/**
 * DataAPI contains all available APIs
 */
export type DataAPI = DataAPI_GET | DataAPI_POST | DataAPI_PUT;

/**
 * DataResultType contains response type for each API used in application
 * @description type is used in definition of database schema
 */
export type DataResultType<T extends DataAPI> = T extends DataAPI_GET
  ? FetchResponse<MOD_OpenAPI[T]["get"], unknown, "application/json">
  : T extends DataAPI_POST
    ? FetchResponse<MOD_OpenAPI[T]["post"], unknown, "application/json">
    : T extends DataAPI_PUT
      ? FetchResponse<MOD_OpenAPI[T]["put"], unknown, "application/json">
      : never;

/**
 * SafeExclude_MOD_OpenAPI_KEYS excludes some APIs which are available in the application
 */
type SafeExclude_MOD_OpenAPI_KEYS<
  MOD_OpenAPI_KEYS,
  U extends MOD_OpenAPI_KEYS,
> = Exclude<MOD_OpenAPI_KEYS, U>;

/**
 * DataAPIDb contains all available APIs to be stored in database
 * we exclude "create" APIs, because for those we need to store request options
 * @see DataRequestOptionsArray
 */
type DataAPIDb = SafeExclude_MOD_OpenAPI_KEYS<
  DataAPI,
  | "/auditing/api/AuditLog/Create"
  | "/eoo/api/Adresa/CreateAdresa"
  | "/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj"
  | "/eoo/api/FyzickaOsoba/CreateAdresaOsoba"
  | "/eoo/api/FyzickaOsoba/CreateFyzickaOsoba"
  | "/eoo/api/FyzickaOsoba/CreateKontakt"
  | "/eoo/api/FyzickaOsoba/UpdateAdresaOsoba"
  | "/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba"
  | "/eoo/api/FyzickaOsoba/UpdateKontakt"
  | "/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch"
  | "/hasura/api/rest/v1/kiosk-ziadost-create/{id}"
  | "/szoo/api/Ziadosti/Create"
  | "/szoo/api/Ziadosti/SpatVzatie"
  | "/zvjs_mp/administracia-tabletu"
  | "/zvjs_mp/rezervacia"
  | "/zvjs_mp/rezervacia/{id}/odoslat"
>;

/**
 * DataArray contains all available APIs
 * @description elements of this array are stored in database
 */
export const DataArray: Array<DataAPIDb> = [
  "/cis/api/CisDochodokDruh/List",
  "/cis/api/CisDovodCastejsiehoSprchovania/List",
  "/cis/api/CisDovodMimoriadnejNavstevy/List",
  "/cis/api/CisDovodNaOdoslanieKorespondencie/List",
  "/cis/api/CisDovodPodaniaZiadostiElektrospotrebic/List",
  "/cis/api/CisDovodPodaniaZiadosti/List",
  "/cis/api/CisDovodPozadovanejObmeny/List",
  "/cis/api/CisDovodZiadostiOrozhovor/List",
  "/cis/api/CisDovodZiadostiSprchovanie/List",
  "/cis/api/CisDovodZiadosti/List",
  "/cis/api/CisDruhBalika/List",
  "/cis/api/CisDruhElektrospotrebica/List",
  "/cis/api/CisDruhNedostatkuUpresnenie/List",
  "/cis/api/CisFormaRealizacieNavstevy/List",
  "/cis/api/CisFyzickePrejavyProblemov/List",
  "/cis/api/CisKategoriaZiadosti/List",
  "/cis/api/CisKrajina/List",
  "/cis/api/CisMiestoNedostatku/List",
  "/cis/api/CisOblastiPsychickychProblemov/List",
  "/cis/api/CisOblastSocialnehoPoradenstva/List",
  "/cis/api/CisOblastZdravotnychProblemov/List",
  "/cis/api/CisOdievanie/List",
  "/cis/api/CisOdpovedPreKlienta/List",
  "/cis/api/CisPodkategoriaZiadosti/List",
  "/cis/api/CisPodnetNaOchranu/List",
  "/cis/api/CisPreventivneZdravPrehliadky/List",
  "/cis/api/CisPrijimaniePenazProstriedkov/List",
  "/cis/api/CisPsychickyStav/List",
  "/cis/api/CisSpanok/List",
  "/cis/api/CisSposobOdoslaniaPozvanky/List",
  "/cis/api/CisSposobOdovzdaniaCeniny/List",
  "/cis/api/CisSposobZabezpeceniaHygNezavadnosti/List",
  "/cis/api/CisStavZiadosti/List",
  "/cis/api/CisStrach/List",
  "/cis/api/CisStravovanie/List",
  "/cis/api/CisStupenVzdelania/List",
  "/cis/api/CisTrvanieNavstevy/List",
  "/cis/api/CisTypBlizkejOsoby/List",
  "/cis/api/CisTypOdoslaniaProstriedkov/List",
  "/cis/api/CisTypOsobnejVeci/List",
  "/cis/api/CisTypPremiestnenia/List",
  "/cis/api/CisTypZiadosti/List",
  "/cis/api/CisUbytovanie/List",
  "/cis/api/CisUIrozhranieTextacie/List",
  "/cis/api/CisUIrozhranieZiadosti/List",
  "/cis/api/CisUpresnenieOblastiZdravotnychProblemov/List",
  "/cis/api/CisUstavZvjs/List",
  "/cis/api/CisVeciOsobnejPotreby/List",
  "/cis/api/CisVeritelTyp/List",
  "/cis/api/CisVystrojneSucasti/List",
  "/cis/api/CisZabezpeceniePrav/List",
  "/cis/api/CisZdravotnaStarostlivost/List",
  "/cis/api/CisZdrojNapajania/List",
  "/cis/api/CisZdrojZiadosti/List",
  "/cis/api/CisZiadosti/List",
  "/cis/api/DalsiePotrebyOh/List",
  "/cis/api/DruhStravy/List",
  "/cis/api/StavOsobnejVeci/List",
  "/edpoo/api/Ciselnik/StavVeci",
  "/edpoo/api/OsobneVeci/ListElektrospotrebic",
  "/edpoo/api/OsobneVeci/ListOsobnaVec",
  "/edpoo/api/OsobneVeci/ListOsobnyDoklad",
  "/edpoo/api/OsobneVeci/ListZbranAStrelivo",
  "/edpoo/api/SkladDpoh/ListStavSkladu",
  "/edpoo/api/Stravovanie/List",
  "/eoo/api/Administracia/PravnyZastupcaData",
  "/eoo/api/Ciselnik/DruhAdresy",
  "/eoo/api/Ciselnik/StupenDosiahnutehoVzdelania",
  "/eoo/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
  "/eoo/api/DalsieOsobneUdaje/ListPobytKlienta",
  "/eoo/api/DalsieOsobneUdaje/TrestneParagrafyTrestu",
  "/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta",
  "/eoo/api/FyzickaOsoba/DetailData",
  "/eoo/api/FyzickaOsoba/ListAdresaOsoba",
  "/eoo/api/FyzickaOsoba/ListKontakt",
  "/eoo/api/Klient/DetailData",
  "/eoo/api/Klient/DetailZakladneUdajeKlientaData",
  "/eoo/api/Klient/List",
  "/eoo/api/Klient/ListRodinnyStavKlientaData",
  "/eoo/api/Klient/ListTrestneKonanieData",
  "/eoo/api/Klient/ListNepravoplatnyTrestOosData",
  "/eoo/api/Klient/XDetailOstatneUdajeData",
  "/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData",
  "/eoo/api/PravnickaOsoba/List",
  "/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList",
  "/eoo/api/Rozhodnutie/ListRozhodnutieData",
  "/eoo/api/VazbyATresty/DetailTDVData",
  "/eoo/api/VazbyATresty/DetailTrestData",
  "/eoo/api/VazbyATresty/DetailVazbaData",
  "/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData",
  "/eoo/api/VazbyATresty/ListTrestData",
  "/eoo/api/VazbyATresty/ListVazbaData",
  "/epvvvt/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory",
  "/epvvvt/api/interfaces/Epvvvt/ListPoucenia",
  "/epvvvt/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania",
  "/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti",
  "/er/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta",
  "/er/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta",
  "/er/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi",
  "/er/api/interfaces/Er/ListZoznamPoruseniKDisciplinarnemuTrestu",
  "/ezoo/api/Integracie/ListKlientNaPracovisku",
  "/feoo/api/Blokacia/List",
  "/feoo/api/Codelist/ListBlokaciaTyp",
  "/feoo/api/Codelist/ListDiferenciacnaSkupina",
  "/feoo/api/Codelist/ListStupenStrazenia",
  "/feoo/api/Codelist/ListTypPohybu",
  "/feoo/api/Codelist/ListTypVeritela",
  "/feoo/api/integracie/Kiosk/GetZivotneMinimum",
  "/feoo/api/integracie/Kiosk/ListCeniny",
  "/feoo/api/integracie/Kiosk/ListCudziaMena",
  "/feoo/api/integracie/Kiosk/ListStavKontaAKreditu",
  "/feoo/api/integracie/Kiosk/ListVyplatnaPaska",
  "/feoo/api/Klient/Get",
  "/feoo/api/KlientUni/Get",
  "/feoo/api/KlientUni/List",
  "/feoo/api/Pohladavka/List",
  "/feoo/api/Pohladavka/ListSplatkyPohladavky",
  "/feoo/api/PohybyNaKonte/List",
  "/feoo/api/VyzivovaciaPovinnost/List",
  "/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti",
  "/hasura/api/rest/getcisprevadzky",
  "/hasura/api/rest/get-kiosk-notifikacie-o-suboroch",
  "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-a",
  "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-norma-a",
  "/hasura/api/rest/v1/kiosk-ziadost-by-id/{id}",
  "/share_int/api/interfaces/Cis/ListEpvvvtCisOraNeformalneStandVzdelavA",
  "/share_int/api/interfaces/Cis/ListEpvvvtCisProgramZaobOblastiProgramuZaobA",
  "/share_int/api/interfaces/Cis/ListEpvvvtCisProgramZaobchadzaniaStavHodnoteniaPzA",
  "/share_int/api/interfaces/Cis/ListSzooCisDruhCivilnychVeciA",
  "/share_int/api/interfaces/Cis/ListSzooCisResocializacnyProgramA",
  "/share_int/api/interfaces/Epvvvt/ListKlientZaradenyDoVo",
  "/share_int/api/interfaces/Epvvvt/ListOraVolnocasovaAktivita",
  "/share_int/api/interfaces/Epvvvt/ListOsobaSPovolenimNaTelefonovanie",
  "/share_int/api/interfaces/Epvvvt/ListZoznamKruzkov",
  "/share_int/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie",
  "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie",
  "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta",
  "/share_int/api/interfaces/Epvvvt/ListZoznamSkupinovychAktivit",
  "/share_int/api/interfaces/Epvvvt/ListZoznamSpecializovanychResocializacnychProgramov",
  "/share_int/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev",
  "/sidkoo/api/IdKarta/ListIdKartaAktivna",
  "/szoo/api/interfaces/Cis/ListEdpoVystrojnaSucastA",
  "/szoo/api/interfaces/Cis/ListKioCisVecZastupovanaAdvokatomA",
  "/szoo/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
  "/szoo/api/Kio/ClientRequestValidate",
  "/szoo/api/Ziadosti/Get",
  "/szoo/api/Ziadosti/GetOdpovedPreKlienta",
  "/zvjs_mp/bezpecny-internet/list",
  "/zvjs_mp/jedalnyListok/list",
  "/zvjs_mp/rezervacia/list",
];

// COMPATIBILITY TYPES

type CiselnikDataAPI = Extract<DataAPI, `/cis/${string}`>;
export type Ciselnik = CiselnikDataAPI extends `/cis${infer Rest}`
  ? Rest
  : never;

export type CiselnikListResultType<T extends Ciselnik> = Awaited<
  DataResultType<`/cis${T}`>
>;

export const CiselnikyArray: Ciselnik[] = [
  "/api/CisDochodokDruh/List",
  "/api/CisDovodCastejsiehoSprchovania/List",
  "/api/CisDovodMimoriadnejNavstevy/List",
  "/api/CisDovodNaOdoslanieKorespondencie/List",
  "/api/CisDovodPodaniaZiadostiElektrospotrebic/List",
  "/api/CisDovodPodaniaZiadosti/List",
  "/api/CisDovodPozadovanejObmeny/List",
  "/api/CisDovodZiadostiOrozhovor/List",
  "/api/CisDovodZiadostiSprchovanie/List",
  "/api/CisDovodZiadosti/List",
  "/api/CisDruhBalika/List",
  "/api/CisDruhElektrospotrebica/List",
  "/api/CisDruhNedostatkuUpresnenie/List",
  "/api/CisFormaRealizacieNavstevy/List",
  "/api/CisFyzickePrejavyProblemov/List",
  "/api/CisKategoriaZiadosti/List",
  "/api/CisKrajina/List",
  "/api/CisMiestoNedostatku/List",
  "/api/CisOblastiPsychickychProblemov/List",
  "/api/CisOblastSocialnehoPoradenstva/List",
  "/api/CisOblastZdravotnychProblemov/List",
  "/api/CisOdievanie/List",
  "/api/CisOdpovedPreKlienta/List",
  "/api/CisPodkategoriaZiadosti/List",
  "/api/CisPodnetNaOchranu/List",
  "/api/CisPreventivneZdravPrehliadky/List",
  "/api/CisPrijimaniePenazProstriedkov/List",
  "/api/CisPsychickyStav/List",
  "/api/CisSpanok/List",
  "/api/CisSposobOdoslaniaPozvanky/List",
  "/api/CisSposobOdovzdaniaCeniny/List",
  "/api/CisSposobZabezpeceniaHygNezavadnosti/List",
  "/api/CisStavZiadosti/List",
  "/api/CisStrach/List",
  "/api/CisStravovanie/List",
  "/api/CisStupenVzdelania/List",
  "/api/CisTrvanieNavstevy/List",
  "/api/CisTypBlizkejOsoby/List",
  "/api/CisTypOdoslaniaProstriedkov/List",
  "/api/CisTypOsobnejVeci/List",
  "/api/CisTypPremiestnenia/List",
  "/api/CisTypZiadosti/List",
  "/api/CisUbytovanie/List",
  "/api/CisUIrozhranieTextacie/List",
  "/api/CisUIrozhranieZiadosti/List",
  "/api/CisUpresnenieOblastiZdravotnychProblemov/List",
  "/api/CisUstavZvjs/List",
  "/api/CisVeciOsobnejPotreby/List",
  "/api/CisVeritelTyp/List",
  "/api/CisVystrojneSucasti/List",
  "/api/CisZabezpeceniePrav/List",
  "/api/CisZdravotnaStarostlivost/List",
  "/api/CisZdrojNapajania/List",
  "/api/CisZdrojZiadosti/List",
  "/api/CisZiadosti/List",
  "/api/DalsiePotrebyOh/List",
  "/api/DruhStravy/List",
  "/api/StavOsobnejVeci/List",
];
