import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050401",
  basicInfo: [
    {
      text: "ziadost.ziadostOposkytnutieZdravStarostlivosti.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050401_isAvailableToUser",
  dataCalculations: {
    IS_FEMALE: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  counters: [
    "/api/CisOblastZdravotnychProblemov/List",
    "/api/CisUpresnenieOblastiZdravotnychProblemov/List",
    "/api/CisPreventivneZdravPrehliadky/List",
  ],
  items: [
    {
      type: ZvjsItemTypes.LABEL,
      flag: ZvjsLabelFlagTypes.HINT,
      text: "ziadost.ziadostOposkytnutieZdravStarostlivosti.napovedaDoplatkyLiekovPomocky",
    },
    {
      id: "oblastZdravotnychProblemov",
      title:
        "ziadost.ziadostOposkytnutieZdravStarostlivosti.labelZdravotneProblemy",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      hintText: "ziadost.ziadostOposkytnutieZdravStarostlivosti.napovedaLekar",
      options: {
        answerId: "oblastZdravotnychProblemovCis",
        counterKey: "/api/CisOblastZdravotnychProblemov/List",
        textKey: "nazov",
        children: [
          {
            id: "1",
            options: {
              answerId: "bolest",
              counterKey: "/api/CisUpresnenieOblastiZdravotnychProblemov/List",
              textKey: "nazov",
              children: [
                { id: "1" },
                { id: "2" },
                { id: "3" },
                { id: "4" },
                {
                  id: "5",
                  conditions: [
                    {
                      type: ZvjsConditionTypes.FUNCTION,
                      conFunc: "IS_FEMALE",
                      conditionMetWhenValueIs: true,
                    },
                  ],
                },
                { id: "6" },
                { id: "7" },
                { id: "8" },
                { id: "9" },
                { id: "10" },
              ],
            },
          },
          { id: "2" },
          { id: "3" },
          {
            id: "4",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "IS_FEMALE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          { id: "5" },
          { id: "6" },
          { id: "7" },
          { id: "8" },
          { id: "9" },
          { id: "10" },
          { id: "11" },
          { id: "12" },
          { id: "13" },
          { id: "14" },
        ],
      },
    },
    {
      id: "popisInychProblemov",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "oblastZdravotnychProblemov",
          value: [{ oblastZdravotnychProblemovCis: "14" }],
        },
      ],
    },
    {
      id: "popisBolestiInejCastiTela",
      title: "popis bolesti inej časti tela",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "oblastZdravotnychProblemov",
          value: [{ oblastZdravotnychProblemovCis: "1", bolest: "10" }],
        },
      ],
    },
    {
      id: "preventivnaPrehliadka",
      title:
        "ziadost.ziadostOposkytnutieZdravStarostlivosti.labelPreventivnePrehliadky",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      options: {
        counterKey: "/api/CisPreventivneZdravPrehliadky/List",
        textKey: "nazov",
        optValues: [
          { id: "VSEOB" },
          { id: "ZUBAR" },
          {
            id: "GYNEK",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "IS_FEMALE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
        ],
      },
    },
    {
      id: "ockovanie",
      title: "ziadost.ziadostOposkytnutieZdravStarostlivosti.labelOckovanie",
      type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
    },
  ],
};
