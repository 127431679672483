import { createSelector } from "@reduxjs/toolkit";
import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { findItem } from "../../../../../../redux/slice";
import { selectSelf } from "../../../../../../redux/selectors";
import {
  SC_KIO_050502_ClosePerson
} from "../../../../utils/calculations/custom";
import { getTranslationByLanguageId } from "../../../../../../../../utils/helpers";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import i18n from "i18next";
import { CiselnikListResultType } from "../../../../../../../../store/context/dataApi/Data";
import { DetailZakladneUdajeKlientaData } from "../../../../../../../specs/swaggerTypes";
import { PersonRelation } from "./SC_KIO_050502_PersonReceivingPackageGrid";
import {
  SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST
} from "../../../../../../requestTemplates/SC_KIO_050502_Ziadost o vydanie veci ulozenych v uschove alebo veci v uzivani";

/**
 * Selectors
 */
const getPersonReceivingPackageGridDisplayData = (
  location: number[],
  newList: PersonRelation[]
) =>
  createSelector(selectSelf, (state: State) => {
    const { getFallbackJazyk } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const combinedPeople = [
      ...(state.userCalcData[
        SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST
      ] as SC_KIO_050502_ClosePerson[]),
    ];

    return {
      id: question.id,
      title: question.title,
      peopleList: combinedPeople.map((person) => {
        console.log(person.adresaId);
        return {
          id: person.fyzickaOsobaId,
          firstname: person.firstname,
          surname: person.surname,
          relationText:
            state.counters["/api/CisTypBlizkejOsoby/List"]?.records &&
            person.relationId
              ? getTranslationByLanguageId(
                state.counters["/api/CisTypBlizkejOsoby/List"].records,
                getCiselnikJazyk(i18n.language),
                getFallbackJazyk(),
                person.relationId,
                "nazov"
              )
              : undefined,
          isPossibleToContact: person.isPossibleToContact,
          adresaId: person.adresaId,
          stat: person.stat,
          kraj: person.kraj,
          okres: person.okres,
          obec: person.obec,
          psc: person.psc,
          ulica: person.ulica,
          orientacneCislo: person.orientacneCislo,
          supisneCislo: person.supisneCislo,
        } as SC_KIO_050502_PersonReceivingPackageDisplayData;
      }),
    };
  });

const getPersonReceivingPackageList = () =>
  createSelector(selectSelf, (state: State): SC_KIO_050502_ClosePerson[] => {
    return state.userCalcData[
      SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST
    ] as SC_KIO_050502_ClosePerson[];
  });

const getPersonReceivingPackageRelationTypeCounter = () =>
  createSelector(
    selectSelf,
    (
      state: State
    ): CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"] => {
      if (state.counters["/api/CisTypBlizkejOsoby/List"]) {
        return state.counters["/api/CisTypBlizkejOsoby/List"];
      }
      return {};
    }
  );

const getPersonReceivingPackageBasicClientData = () =>
  createSelector(selectSelf, (state: State): DetailZakladneUdajeKlientaData => {
    return state.userCalcData["BASIC_CLIENT_DATA"];
  });

export const allSelectors = {
  getPersonReceivingPackageGridDisplayData,
  getPersonReceivingPackageList,
  getPersonReceivingPackageRelationTypeCounter,
  getPersonReceivingPackageBasicClientData,
};

export interface SC_KIO_050502_PersonReceivingPackageDisplayData {
  id: number;
  adresaId: number | undefined;
  firstname: string | undefined;
  surname: string | undefined;
  relationText: string | undefined;
  isPossibleToContact: boolean;
  stat: string | undefined;
  kraj: string | undefined;
  okres: string | undefined;
  obec: string | undefined;
  psc: string | undefined;
  ulica: string | undefined;
  orientacneCislo: string | undefined;
  supisneCislo: string | undefined;
}
