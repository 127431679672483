import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(
    `OdoslanaKorespondenciaTab loadData datumOd[${datumOd}] datumDo[${datumDo}]`
  );

  const { SHARE_INT_Post } = await API_Clients();

  const odoslanaKorespondenciaList = await SHARE_INT_Post(
    "/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie",
    {
      body: {
        klientObjectId: {
          eq: localStorage.getItem("klientObjectId") ?? "faultyId",
        },
      },
    }
  );

  if (odoslanaKorespondenciaList.error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [odoslaná korešpondencia]"
    );
  }

  let filteredData = odoslanaKorespondenciaList.data;
  if (datumOd) {
    const startOfDay = new Date(datumOd);
    startOfDay.setHours(0, 0, 0, 0); // Set to the start of the day
    filteredData = filteredData.filter(
      (item) =>
        item.datumOdoslania &&
        new Date(item.datumOdoslania) >= startOfDay
    );
  }

  if (datumDo) {
    const endOfDay = new Date(datumDo);
    endOfDay.setHours(23, 59, 59, 999); // Set to the end of the day
    filteredData = filteredData.filter(
      (item) =>
        item.datumOdoslania &&
        new Date(item.datumOdoslania) <= endOfDay
    );
  }

  const { EOO_Get, EOO_Post } = await API_Clients();

  const zoznamOdoslanejKorespondencie = await Promise.all(
    filteredData.map(async (item) => {
      try {
        let prijimatelNazov = "";

        if (!!item.adresatFyzickaOsobaId) {
          const prijimatelDetail = await EOO_Get(
            "/api/FyzickaOsoba/DetailData",
            {
              params: {
                query: {
                  request: item.adresatFyzickaOsobaId,
                },
              },
            }
          );

          const fyzickaOsobaData = prijimatelDetail?.data?.data;
          if (fyzickaOsobaData) {
            prijimatelNazov =
              `${fyzickaOsobaData.meno || ""} ${fyzickaOsobaData.priezvisko || ""}`.trim();
          }
        } else if (!!item.adresatPravnickaOsobaId) {
          const pravnickeOsoby = await EOO_Post("/api/PravnickaOsoba/List", {
            body: {},
          });

          const matchingPravnickaOsoba = pravnickeOsoby?.data?.records?.find(
            (record) => record.id === item.adresatPravnickaOsobaId
          );

          if (matchingPravnickaOsoba) {
            prijimatelNazov = matchingPravnickaOsoba.nazov || "";
          }
        }

        return {
          ...item,
          prijimatelNazov: prijimatelNazov,
        };
      } catch (error) {
        console.error("Error processing item:", item, error);
        return {
          ...item,
          prijimatelNazov: "",
        };
      }
    })
  );

  return {
    zoznamOdoslanejKorespondencie,
  };
};

export const OdoslanaKorespondenciaTabLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

export interface OdoslanaKorespondenciaTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const OdoslanaKorespondenciaTab = (props: OdoslanaKorespondenciaTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(
      `OdoslanaKorespondencia onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSearchBar
        title={tui("vyhľadať korešpondenciu")}
        type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
        onSubmit={onSearch}
      />
      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("dátum odoslania")),
              align: "left",
              width: "26%",
            },
            {
              value: capitalize(tui("typ korešpondencie")),
              align: "left",
              width: "37%",
            },
            {
              value: capitalize(tui("adresát")),
              align: "left",
              width: "37%",
            },
          ],
          body:
            props.data.zoznamOdoslanejKorespondencie.map(
              (odoslanaKorespondencia) => {
                const rd: RowData = {
                  row: [
                    {
                      value: new Date(
                        odoslanaKorespondencia?.datumOdoslania ?? new Date()
                      ).zvjsToString(),
                      align: "left",
                      width: "26%",
                    },
                    {
                      value:
                        odoslanaKorespondencia?.typKorespondencieNazov ?? "",
                      align: "left",
                      width: "37%",
                    },
                    {
                      value: odoslanaKorespondencia?.prijimatelNazov ?? "",
                      align: "left",
                      width: "37%",
                    },
                  ],
                };
                return rd;
              }
            ) ?? [],
        }}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(OdoslanaKorespondenciaTab);
