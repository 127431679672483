import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import ZvjsSummaryTable, {
  ZvjsSummaryTableComponent,
} from "../../../../common/components/ZvjsSummaryTable";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { MAX_NUMBER_OF_ITEMS } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const pohyb = searchParams.get("pohyb")
    ? decodeURIComponent(searchParams.get("pohyb") || "")
    : undefined;
  const typPohybu = pohyb ? Number(pohyb) : undefined;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(
    `KontoTab loadData pohyb[${pohyb}] datumOd[${datumOd}] datumDo[${datumDo}]`
  );

  const { FEOO_Get, FEOO_Post } = await API_Clients();

  const data = await Promise.all([
    FEOO_Post("/api/Codelist/ListTypPohybu", {
      body: {
        filters: [],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    FEOO_Get("/api/Klient/Get", {
      params: {
        query: {
          id: localStorage.getItem("klientObjectId") ?? undefined,
        },
      },
    }),
    FEOO_Post("/api/integracie/Kiosk/ListStavKontaAKreditu", {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [číselník typ pohybu]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [feoo klient]");
  }

  if (data[2].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [stav konta a kreditu klienta]"
    );
  }

  const pohybyKonto = await FEOO_Post("/api/PohybyNaKonte/List", {
    body: {
      filters: [
        {
          zakladnyFilter: {
            zakladneCislo:
              data[1].data?.data?.zakladneUdajeKlienta?.zakladneCislo,
            datumOD: datumOd,
            datumDO: datumDo,
            typPohybuID: typPohybu,
          },
        },
      ],
    },
  });

  if (pohybyKonto.error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [pohyby na konte klienta]"
    );
  }

  return {
    typPohybu: data[0],
    stavKontaAKreditu: data[2],
    pohybyKonto: pohybyKonto,
  };
};

export const KontoTabLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

export interface KontoTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const KontoTab = (props: KontoTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (dateFrom: Date, dateTo: Date, pohyb: string) => {
    console.debug(
      `Konto onSearch pohyb[${pohyb}] dateFrom[${dateFrom}] dateTo[${dateTo}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(pohyb !== "" && {
            pohyb: encodeURIComponent(pohyb),
          }),
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable header={tui("konto")}>
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaVreckove")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.celkovyZostatokV?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.moznaSumaBufet")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.moznaSumaNaNakup?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaSumaBufet")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaOsobne")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.celkovyZostatokO?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("disponibilné konto")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.disponibilnyZostatok?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaTelefonovanie")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaSpolu")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.stavKontaSpolu?.toFixed(2) ?? 0
          } EUR`}
          isBorder={false}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaCelkovaSuma")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.sumaIneCerpanie")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.moznaSumaNaCerpanie?.toFixed(2) ?? 0
          } EUR`}
          isBorder={false}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaIneCerpanie")}
        >
          {/*TODO add request link here - but find correct request which is supposed to be here*/}
          {/*<Link to={""}>*/}
          {/*  {capitalize(*/}
          {/*    t(*/}
          {/*      "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.Account.request"*/}
          {/*    )*/}
          {/*  )}*/}
          {/*</Link>*/}
        </ZvjsSummaryTableComponent>
      </ZvjsSummaryTable>

      <ZvjsSearchBar
        title={tui("vyhľadať v pohyboch")}
        type={ZvjsSearchBarVariant.VARIANT_B_DpDpCo}
        onSubmit={onSearch}
        comboboxLabel={capitalize(tui("osobneUdaje.kontoAkredit.pohyb"))}
        items={
          props.data.typPohybu.data.records?.map((item) => {
            return { value: item.id || -1, text: item.nazov || "" };
          }) || []
        }
      />
      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(
                tui(
                  // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
                  "osobneUdaje.blokacie.datumPohybu"
                )
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui(
                  "osobneUdaje.kontoAkredit.pohyb"
                )
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui(
                  // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
                  "osobneUdaje.blokacie.nazovPohybu"
                )
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui(
                  // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
                  "osobneUdaje.blokacie.sumaPohybu"
                )
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui(
                  // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
                  "osobneUdaje.blokacie.typKonta"
                )
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui(
                  // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
                  "osobneUdaje.blokacie.poznamkaPohyb"
                )
              ),
              align: "left",
            },
          ],
          body:
            props.data.pohybyKonto.data?.records
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((pohybKonto) => {
                const rd: RowData = {
                  row: [
                    {
                      value: pohybKonto.datumPohybuNaKonte
                        ? new Date(pohybKonto.datumPohybuNaKonte).zvjsToString()
                        : "",
                      align: "left",
                    },
                    {
                      value: pohybKonto.typPohybu ?? "",
                      align: "left",
                    },
                    {
                      value: pohybKonto.druhPohybu ?? "",
                      align: "left",
                    },
                    {
                      value: pohybKonto.suma
                        ? pohybKonto.suma?.toFixed(2) + " EUR"
                        : "",
                      align: "left",
                    },
                    {
                      value: pohybKonto.typKonta ?? "",
                      align: "left",
                    },
                    {
                      value: pohybKonto.poznamka ?? "",
                      align: "left",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(KontoTab);
