import React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import ClearIcon from "@mui/icons-material/Clear";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { useModal } from "../../../../../../store/context/modal-context";
import {
  ZvjsButton,
  ZvjsSelect,
  ZvjsTextField,
} from "../../../../../components";
import {
  getTranslationByLanguageCode,
  isSubmitting,
} from "../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../locales/i18n";
import i18n from "i18next";
import { Params, useNavigation } from "react-router-dom";
import { ActionResultTypes } from "../../../../../../router/ActionResult";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import {
  EooCiselnikTypKontaktu_Mobil_ID,
  EooCiselnikTypKontaktu_Telefon_ID
} from "../../../../../specs/countersValues";
import { SC_KIO_050101_ClosePerson } from "../../utils/calculations/custom";
import { CiselnikListResultType } from "../../../../../../store/context/dataApi/Data";
import {
  RequestActionsType,
  RequestActionResult, editOption,
} from "../../../../Request";
import {
  SC_KIO_050701_PhoneCallCiviliansDisplayData
} from "../questions/customQuestions/SC_KIO_050701_PhoneCallCiviliansGrid/redux";

interface EditPersonContactSubmitData {
  formData: PersonFormData;
  closePersonData: SC_KIO_050101_ClosePerson;
}
export async function SC_KIO_050701_editPersonContact({
  params,
  data,
}: {
  params: Params;
  data: unknown;
}): Promise<RequestActionResult> {
  const { EOO_Post } = await API_Clients();

  const dataToEditPersonContact = data as EditPersonContactSubmitData;

  console.log(dataToEditPersonContact.closePersonData);

  // Handle phoneCallContact
  if (dataToEditPersonContact.closePersonData.phoneCallContact) {
    // Update existing phoneCallContact
    const toSubmitPhoneCallContact = dataToEditPersonContact.closePersonData.phoneCallContact;
    toSubmitPhoneCallContact.data = dataToEditPersonContact.formData.phoneCallContact;

    console.log(toSubmitPhoneCallContact);
    const resultUpdatedPhoneCallContact = await EOO_Post(
      "/api/FyzickaOsoba/UpdateKontakt",
      {
        body: toSubmitPhoneCallContact,
      }
    );

    if (resultUpdatedPhoneCallContact.error || !resultUpdatedPhoneCallContact.response.ok) {
      if (
        resultUpdatedPhoneCallContact.response.status === 503 &&
        resultUpdatedPhoneCallContact.response.headers.get("X-Zvjs-Offline") === "offline"
      ) {
        return {
          type: ActionResultTypes.SUCCESS,
          action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
          message:
            "Požiadavka na zmenu údajov bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.",
        };
      }

      return {
        type: ActionResultTypes.ERROR,
        message: "Kontakt na videohovor sa nepodarilo upraviť",
        action: RequestActionsType.SC_KIO_050101_EDIT_PERSON_CONTACT,
      };
    } else {
      dataToEditPersonContact.closePersonData.phoneCallContact = toSubmitPhoneCallContact;
    }
  } else {
    // Create new phoneCallContact
    const resultNovyKontakt = await EOO_Post(
      "/api/FyzickaOsoba/CreateKontakt",
      {
        body: {
          typId: EooCiselnikTypKontaktu_Mobil_ID,
          osobaId: dataToEditPersonContact.closePersonData.fyzickaOsobaId,
          data: dataToEditPersonContact.formData.phoneCallContact,
          platnostOd: new Date().toISOString(),
          primarny: true,
        },
      }
    );

    if (resultNovyKontakt.error || !resultNovyKontakt.response.ok) {
      if (
        resultNovyKontakt.response.status === 503 &&
        resultNovyKontakt.response.headers.get("X-Zvjs-Offline") === "offline"
      ) {
        return {
          type: ActionResultTypes.SUCCESS,
          action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
          message:
            "Požiadavka na zmenu údajov bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.",
        };
      }

      return {
        type: ActionResultTypes.ERROR,
        message: "Kontakt na videohovor sa nepodarilo vytvoriť",
        action: RequestActionsType.SC_KIO_050101_EDIT_PERSON_CONTACT,
      };
    } else {
      dataToEditPersonContact.closePersonData.phoneCallContact = {
        data: dataToEditPersonContact.formData.phoneCallContact,
        typId: EooCiselnikTypKontaktu_Mobil_ID,
        osobaId: dataToEditPersonContact.closePersonData.fyzickaOsobaId,
      };
    }
  }

  // Handle phoneCallContact2
  if (dataToEditPersonContact.closePersonData.phoneCallContact2) {
    // Update existing phoneCallContact2
    const toSubmitPhoneCallContact2 = dataToEditPersonContact.closePersonData.phoneCallContact2;
    toSubmitPhoneCallContact2.data = dataToEditPersonContact.formData.phoneCallContact2;

    const resultUpdatedPhoneCallContact2 = await EOO_Post(
      "/api/FyzickaOsoba/UpdateKontakt",
      {
        body: toSubmitPhoneCallContact2,
      }
    );

    if (resultUpdatedPhoneCallContact2.error || !resultUpdatedPhoneCallContact2.response.ok) {
      if (
        resultUpdatedPhoneCallContact2.response.status === 503 &&
        resultUpdatedPhoneCallContact2.response.headers.get("X-Zvjs-Offline") === "offline"
      ) {
        return {
          type: ActionResultTypes.SUCCESS,
          action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
          message:
            "Požiadavka na zmenu údajov bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.",
        };
      }

      return {
        type: ActionResultTypes.ERROR,
        message: "Kontakt na telefonický hovor sa nepodarilo upraviť",
        action: RequestActionsType.SC_KIO_050101_EDIT_PERSON_CONTACT,
      };
    } else {
      dataToEditPersonContact.closePersonData.phoneCallContact2 = toSubmitPhoneCallContact2;
    }
  } else if (dataToEditPersonContact.closePersonData.phoneCallContact2 === undefined && dataToEditPersonContact.formData.phoneCallContact2 !== "") {
    // Create new phoneCallContact2
    const resultNovyKontakt2 = await EOO_Post(
      "/api/FyzickaOsoba/CreateKontakt",
      {
        body: {
          typId: EooCiselnikTypKontaktu_Telefon_ID,
          osobaId: dataToEditPersonContact.closePersonData.fyzickaOsobaId,
          data: dataToEditPersonContact.formData.phoneCallContact2,
          platnostOd: new Date().toISOString(),
          primarny: true,
        },
      }
    );

    if (resultNovyKontakt2.error || !resultNovyKontakt2.response.ok) {
      if (
        resultNovyKontakt2.response.status === 503 &&
        resultNovyKontakt2.response.headers.get("X-Zvjs-Offline") === "offline"
      ) {
        return {
          type: ActionResultTypes.SUCCESS,
          action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
          message:
            "Požiadavka na zmenu údajov bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.",
        };
      }

      return {
        type: ActionResultTypes.ERROR,
        message: "Kontakt na telefonický hovor sa nepodarilo vytvoriť",
        action: RequestActionsType.SC_KIO_050101_EDIT_PERSON_CONTACT,
      };
    } else {
      dataToEditPersonContact.closePersonData.phoneCallContact2 = {
        data: dataToEditPersonContact.formData.phoneCallContact2,
        typId: EooCiselnikTypKontaktu_Telefon_ID,
        osobaId: dataToEditPersonContact.closePersonData.fyzickaOsobaId,
      };
    }
  }

  await editOption(params.requestCode, {
    blizkaOsobaId: dataToEditPersonContact.closePersonData?.blizkaOsobaId,
    fyzickaOsobaId: dataToEditPersonContact.closePersonData?.fyzickaOsobaId,
    firstname: dataToEditPersonContact.closePersonData?.firstname,
    surname: dataToEditPersonContact.closePersonData?.surname,
    relationId: dataToEditPersonContact.closePersonData?.relationId,
    videoCallContact: dataToEditPersonContact.closePersonData?.videoCallContact,
    phoneCallContact: dataToEditPersonContact.closePersonData?.phoneCallContact,
    phoneCallContact2: dataToEditPersonContact.closePersonData?.phoneCallContact2,
    isUnderage: dataToEditPersonContact.closePersonData?.isUnderage,
    isPossibleToContact: dataToEditPersonContact.closePersonData?.isPossibleToContact,
    inPrison: dataToEditPersonContact.closePersonData?.inPrison,
  });

  return {
    type: ActionResultTypes.SUCCESS,
    action: RequestActionsType.SC_KIO_050101_EDIT_PERSON_CONTACT,
  };
}

const generateSchema = (
  tuiz: (key: string) => string,
  closePersonData: SC_KIO_050101_ClosePerson,
  closePersonDisplayData?: SC_KIO_050701_PhoneCallCiviliansDisplayData
) =>
  yup
    .object({
      firstname: yup.string().default(closePersonDisplayData?.firstname ?? ""), // todo add translation
      surname: yup.string().default(closePersonDisplayData?.surname ?? ""), // todo add translation
      // default value needs to be passed to ZvjsSelect component also directly
      // (yup default holds value stored in react-hook-form, value passed to ZvjsSelect is shown as default value in ui (basically placeholder))
      relation: yup.number().default(closePersonData?.relationId), // todo add translation
      phoneCallContact: yup
        .string()
        .required(capitalize(tuiz("Napíšte telefónne číslo")))
        .default(closePersonDisplayData?.phoneCallContact?.data ?? ""), // todo add translation
      phoneCallContact2: yup
        .string()
        .notRequired()
        .default(closePersonDisplayData?.phoneCallContact2?.data ?? ""), // todo add translation
    })
    .required();
type PersonFormData = yup.InferType<ReturnType<typeof generateSchema>>;

interface SC_KIO_050701_PersonFormProps {
  closePersonData: SC_KIO_050101_ClosePerson;
  closePersonDisplayData?: SC_KIO_050701_PhoneCallCiviliansDisplayData;
  onSubmit: (toSubmit: unknown) => void;
  closePersonRelationCounter: CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"];
}

const SC_KIO_050701_EditPersonCallForm: React.FC<
  SC_KIO_050701_PersonFormProps
> = ({
  closePersonData,
  closePersonDisplayData,
  onSubmit,
  closePersonRelationCounter,
}) => {
  const { closeModal } = useModal();
  const { tuiz, getFallbackJazyk } = useUITranslation();
  const navigation = useNavigation();

  const schema = generateSchema(tuiz, closePersonData, closePersonDisplayData);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(undefined),
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (formData: PersonFormData) => {
    const toSubmit: EditPersonContactSubmitData = {
      formData: formData,
      closePersonData: closePersonData,
    };
    onSubmit(toSubmit);
  };

  console.log(closePersonData);

  return (
    <Stack width={"70vw"} maxHeight={"80vh"} overflow={"auto"}>
      {/* todo add translation */}
      <Typography variant={"h2"} mb={2}>
        {/*TODO add translation*/}
        {capitalize(tuiz("Zmena údajov"))}
      </Typography>{" "}
      <form
        style={{ width: "20 rem" }}
        onSubmit={handleSubmit(handleOnSubmit)}
        // TODO think about how to make live validation work - low priority
        // onBlur={handleSubmit(dummyFunction)}
      >
        <Stack spacing={1}>
          {/*/!* todo add translation *!/*/}
          <ZvjsTextField
            label={capitalize(tuiz("Meno"))}
            register={{ ...register("firstname") }}
            error={errors.firstname !== undefined}
            validationErrorMessage={errors.firstname?.message}
            disabled
          />
          <ZvjsTextField
            label={capitalize(tuiz("Priezvisko"))}
            register={{ ...register("surname") }}
            error={errors.surname !== undefined}
            validationErrorMessage={errors.surname?.message}
            disabled
          />
          <ZvjsSelect
            text={capitalize(tuiz("Vzťah k osobe"))}
            register={{ ...register("relation") }}
            items={
              closePersonRelationCounter?.records
                ?.filter((relation) => relation.id)
                .map((relation) => {
                  if (relation.id && relation.kod) {
                    return {
                      value: relation.id,
                      text: getTranslationByLanguageCode(
                        closePersonRelationCounter?.records ?? [],
                        getCiselnikJazyk(i18n.language),
                        getFallbackJazyk(),
                        relation.kod,
                        "nazov"
                      ),
                    };
                  }
                  throw new Error();
                }) ?? []
            }
            uncontrolled={{ defaultValue: closePersonData?.relationId }}
            error={errors.relation !== undefined}
            validationErrorMessage={errors.relation?.message}
            disabled
          />
          <ZvjsTextField
            label={capitalize(tuiz("ziadost.ziadostOtelefonovanie.labelTelefonneCislo"))}
            register={{ ...register("phoneCallContact") }}
            error={errors.phoneCallContact !== undefined}
            validationErrorMessage={errors.phoneCallContact?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("ziadost.ziadostOtelefonovanie.labelTelefonneCislo") + " 2")}
            register={{ ...register("phoneCallContact2") }}
            error={errors.phoneCallContact2 !== undefined}
            validationErrorMessage={errors.phoneCallContact2?.message}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} mt={3} justifyContent={"center"}>
          <ZvjsButton
            type={"submit"}
            zvjsVariant={"secondaryAction"}
            disabled={
              Object.keys(errors).length > 0 || isSubmitting(navigation)
            }
            text={capitalize(tuiz("Upraviť osobu"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            disabled={isSubmitting(navigation)}
            text={capitalize(tuiz("Zrušiť"))}
            startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
            onClick={closeModal}
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default SC_KIO_050701_EditPersonCallForm;
