export enum PodmienkaZiadostiEnum {
  BR006 = "BR006",
  BR024 = "BR024",
  BR025 = "BR025",
  BR026 = "BR026",
  BR027 = "BR027",
  BR029 = "BR029",
  BR030 = "BR030",
  BR034 = "BR034",
  BR036 = "BR036",
  BR037 = "BR037",
  BR038 = "BR038",
  BR039 = "BR039",
  BR042 = "BR042",
  BR044 = "BR044",
  BR047 = "BR047",
  BR048 = "BR048",
  BR049 = "BR049",
  BR071 = "BR071",
  BR072 = "BR072",
  BR073 = "BR073",
  BR075 = "BR075",
  BR076 = "BR076",
  BR077 = "BR077",
  BR079 = "BR079",
  BR083 = "BR083",
  BR084 = "BR084",
  BR131 = "BR131",
  BR132 = "BR132",
  BR133 = "BR133",
  BR148 = "BR148"
}