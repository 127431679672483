import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050202",
  basicInfo: [
    {
      text: "ziadost.ziadostOvydanieCeniny.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050202_isAvailableToUser",
  dataCalculations: {
    CLOSE_PEOPLE_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    BASIC_CLIENT_DATA: {
      calculateAt: Calculate.AT_INIT,
    },
    PRAVNY_ZASTUPCA_DATA: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  counters: [
    "/api/CisSposobOdovzdaniaCeniny/List",
    "/api/CisTypBlizkejOsoby/List",
  ],
  items: [
    {
      id: "sposobOdovzdania",
      title: "ziadost.ziadostOvydanieCeniny.labelSposobOdovzdania",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisSposobOdovzdaniaCeniny/List",
        textKey: "sposob_odovzdania",
        optValues: [{ id: "NAVST" }, { id: "ADVOK" }],
      },
    },
    {
      id: "evidovaneCeniny",
      title: "ziadost.ziadostOvydanieCeniny.labelEvidovaneCeniny",
      type: ZvjsCustomQuestionTypes.SC_KIO_050202_VALUABLE_ITEMS_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "sposobOdovzdania",
          hasValue: true,
        },
      ],
    },
    {
      id: "identifikatorOsoby",
      title: "ziadost.ziadostOvydanieCeniny.labelVyberOsoby",
      type: ZvjsCustomQuestionTypes.SC_KIO_050202_CLOSE_PEOPLE_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "sposobOdovzdania",
          value: ["NAVST"],
        },
      ],
    },
  ],
};
