import {API_Clients} from "../../../../../store/context/dataApi/Data";
import {getIsFemale} from "./calculations/reusable";
import {TypZiadostiEnum} from "./TypZiadostiEnum";
import {areAllPorusenePodmienky, isAnyPorusenaPodmienka} from "../../../../../utils/helpers";
import {PodmienkaZiadostiEnum} from "./PodmienkaZiadostiEnum";

export type IsAvailableToUserType =
  | "SC_KIO_051001_isAvailableToUser"
  | "SC_KIO_051402_isAvailableToUser"
  | "SC_KIO_051401_isAvailableToUser"
  | "SC_KIO_051301_isAvailableToUser"
  | "SC_KIO_051201_isAvailableToUser"
  | "SC_KIO_051104_isAvailableToUser"
  | "SC_KIO_051004_isAvailableToUser"
  | "SC_KIO_050602_isAvailableToUser"
  | "SC_KIO_050402_isAvailableToUser"
  | "SC_KIO_050201_isAvailableToUser"
  | "SC_KIO_050202_isAvailableToUser"
  | "SC_KIO_050203_isAvailableToUser"
  | "SC_KIO_050207_isAvailableToUser"
  | "SC_KIO_050206_isAvailableToUser"
  | "SC_KIO_050205_isAvailableToUser"
  | "SC_KIO_050208_isAvailableToUser"
  | "SC_KIO_050401_isAvailableToUser"
  | "SC_KIO_050501_isAvailableToUser"
  | "SC_KIO_050701_isAvailableToUser"
  | "SC_KIO_050801_isAvailableToUser"
  | "SC_KIO_050802_isAvailableToUser"
  | "SC_KIO_050803_isAvailableToUser"
  | "SC_KIO_050804_isAvailableToUser"
  | "SC_KIO_050901_isAvailableToUser"
  | "SC_KIO_051002_isAvailableToUser"
  | "SC_KIO_051404_isAvailableToUser"
  | "SC_KIO_051403_isAvailableToUser"
  | "SC_KIO_051501_isAvailableToUser";

const SC_KIO_051001_isAvailableToUser = async () => {
  const isUserFemale = await getIsFemale();
  if (isUserFemale) {
    return false;
  }
  return true;
};

const SC_KIO_051402_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasOtherRequestOfThisTypeInProgress = false;
  if (hasOtherRequestOfThisTypeInProgress) {
    return false;
  }

  return true;
};

const SC_KIO_051401_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z030003 });

  if (questionControlData === undefined){
    const isAccused = localStorage.getItem("aktivnaVazba");
    return isAccused === null ? true : JSON.parse(isAccused);
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR037,
    PodmienkaZiadostiEnum.BR038, PodmienkaZiadostiEnum.BR039]);
};

const SC_KIO_051201_isAvailableToUser = async () => {
  // TODO add real api calls
  const userHasSpecialDiet = false;
  if (userHasSpecialDiet) {
    return false;
  }

  const hasAlreadyRequestedThisCalendarMonth = false;
  if (hasAlreadyRequestedThisCalendarMonth) {
    return false;
  }

  return true;
};

const SC_KIO_051301_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z020001 });

  if (questionControlData === undefined){
    return true;
  }

  return !questionControlData?.porusenePodmienky?.some((x: string) => x === "BR006");
};

const SC_KIO_051104_isAvailableToUser = async () => {
  // TODO add real api calls
  const userUseInstitutionClothes = true;
  if (!userUseInstitutionClothes) {
    return false;
  }

  return true;
};

const SC_KIO_051004_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z070102 });

  if (questionControlData === undefined){
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR042,
    PodmienkaZiadostiEnum.BR077, PodmienkaZiadostiEnum.BR083, PodmienkaZiadostiEnum.BR084]);
};

const SC_KIO_050602_isAvailableToUser = async () => {
  const isAnyProgramAvailableToClient = true;
  // TODO check whether there is in a client's institution any program that he can attend and have not applied for yet
  if (!isAnyProgramAvailableToClient) {
    return false;
  }
  return true;
};

const SC_KIO_050402_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z150002 });

  if (questionControlData === undefined){
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR030]);
};

const SC_KIO_050208_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({
    typZiadosti: TypZiadostiEnum.Z090501,
    ustav: localStorage.getItem("klientUstavId"),
  });

  if (questionControlData === undefined){
    const isSentenced = localStorage.getItem("aktivnyTrest");
    return isSentenced === null ? true : JSON.parse(isSentenced);
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR034,
    PodmienkaZiadostiEnum.BR073, PodmienkaZiadostiEnum.BR075, PodmienkaZiadostiEnum.BR076,
    PodmienkaZiadostiEnum.BR077]);
};

const SC_KIO_050207_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z090701});

  if (questionControlData === undefined){
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR034,
    PodmienkaZiadostiEnum.BR077, PodmienkaZiadostiEnum.BR079]);
};

const SC_KIO_050206_isAvailableToUser = async () => {
  // TODO add real api calls
  const isUserSentenced = true;
  if (!isUserSentenced) {
    return false;
  }

  const hasAnyChildSupportObligation = true;
  if (!hasAnyChildSupportObligation) {
    return false;
  }

  return true;
};

const SC_KIO_050205_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z090502 });

  if (questionControlData === undefined){
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR073, PodmienkaZiadostiEnum.BR075]);
};

const SC_KIO_051404_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z030001 });

  if (questionControlData === undefined){
    const isSentenced = localStorage.getItem("aktivnyTrest");
    return isSentenced === null ? true : JSON.parse(isSentenced);
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR034]);
};

const SC_KIO_051403_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z030004 });

  if (questionControlData === undefined){
    const isSentenced = localStorage.getItem("aktivnyTrest");
    return isSentenced === null ? true : JSON.parse(isSentenced);
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR034]);
};

const SC_KIO_050501_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z080101 });

  if (questionControlData === undefined){
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006]);
};

const getQuestionControlData = async (params : object) => {
  const { SZOO_Get } = await API_Clients();
  const responseSzoo = await SZOO_Get("/api/Kio/ClientRequestValidate", {
    params: {
      query: {
        klientId: localStorage.getItem("klientObjectId") ?? "",
        ...params
      },
    },
  });

  if (responseSzoo.error || !responseSzoo.response.ok || !responseSzoo.data) {
    console.error("Failed to fetch request body from Szoo");
  }

  return responseSzoo?.data;
};

const SC_KIO_050801_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z100201});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006, PodmienkaZiadostiEnum.BR030]);
};

const SC_KIO_050803_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z010101 });

  if (questionControlData === undefined) {
    return true;
  }

  return !questionControlData?.porusenePodmienky?.some((x: string) => x === "BR006");
};

const SC_KIO_050804_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z100401 });

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006]);
};

const SC_KIO_050901_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({ typZiadosti: TypZiadostiEnum.Z060001 });

  if (questionControlData === undefined) {
    return true;
  }

  return !questionControlData?.porusenePodmienky?.some((x: string) => x === "BR006");
};

const SC_KIO_051002_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z070301});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006, PodmienkaZiadostiEnum.BR042]);
};

const SC_KIO_050202_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z090501,
    ustav: localStorage.getItem("klientUstavId")});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR027, PodmienkaZiadostiEnum.BR148]);
};

const SC_KIO_050201_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z090401,
    ustav: localStorage.getItem("klientUstavId")});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR029, PodmienkaZiadostiEnum.BR148]);
};

const SC_KIO_051501_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z140001});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006]);
};

const SC_KIO_050701_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z130001});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006]);
};

const SC_KIO_050401_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z150001});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006, PodmienkaZiadostiEnum.BR030]);
};

const SC_KIO_050203_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z090102});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006,
    PodmienkaZiadostiEnum.BR071, PodmienkaZiadostiEnum.BR072, PodmienkaZiadostiEnum.BR073]);
};

const SC_KIO_050802_isAvailableToUser = async () => {
  const questionControlData = await getQuestionControlData({typZiadosti: TypZiadostiEnum.Z100301});

  if (questionControlData === undefined) {
    return true;
  }

  return !isAnyPorusenaPodmienka(questionControlData, [PodmienkaZiadostiEnum.BR006])
      && !areAllPorusenePodmienky(questionControlData, [PodmienkaZiadostiEnum.BR131,
        PodmienkaZiadostiEnum.BR132, PodmienkaZiadostiEnum.BR133]) ;
};

const isAvailableToUserFunctions: IsAvailableToUserFunctionsType = {
  SC_KIO_051001_isAvailableToUser: SC_KIO_051001_isAvailableToUser,
  SC_KIO_051402_isAvailableToUser: SC_KIO_051402_isAvailableToUser,
  SC_KIO_051401_isAvailableToUser: SC_KIO_051401_isAvailableToUser,
  SC_KIO_051301_isAvailableToUser: SC_KIO_051301_isAvailableToUser,
  SC_KIO_051201_isAvailableToUser: SC_KIO_051201_isAvailableToUser,
  SC_KIO_051104_isAvailableToUser: SC_KIO_051104_isAvailableToUser,
  SC_KIO_051004_isAvailableToUser: SC_KIO_051004_isAvailableToUser,
  SC_KIO_050602_isAvailableToUser: SC_KIO_050602_isAvailableToUser,
  SC_KIO_050402_isAvailableToUser: SC_KIO_050402_isAvailableToUser,
  SC_KIO_050201_isAvailableToUser: SC_KIO_050201_isAvailableToUser,
  SC_KIO_050202_isAvailableToUser: SC_KIO_050202_isAvailableToUser,
  SC_KIO_050203_isAvailableToUser: SC_KIO_050203_isAvailableToUser,
  SC_KIO_050208_isAvailableToUser: SC_KIO_050208_isAvailableToUser,
  SC_KIO_050207_isAvailableToUser: SC_KIO_050207_isAvailableToUser,
  SC_KIO_050206_isAvailableToUser: SC_KIO_050206_isAvailableToUser,
  SC_KIO_050205_isAvailableToUser: SC_KIO_050205_isAvailableToUser,
  SC_KIO_050401_isAvailableToUser: SC_KIO_050401_isAvailableToUser,
  SC_KIO_050501_isAvailableToUser: SC_KIO_050501_isAvailableToUser,
  SC_KIO_050701_isAvailableToUser: SC_KIO_050701_isAvailableToUser,
  SC_KIO_050801_isAvailableToUser: SC_KIO_050801_isAvailableToUser,
  SC_KIO_050802_isAvailableToUser: SC_KIO_050802_isAvailableToUser,
  SC_KIO_050803_isAvailableToUser: SC_KIO_050803_isAvailableToUser,
  SC_KIO_050804_isAvailableToUser: SC_KIO_050804_isAvailableToUser,
  SC_KIO_050901_isAvailableToUser: SC_KIO_050901_isAvailableToUser,
  SC_KIO_051002_isAvailableToUser: SC_KIO_051002_isAvailableToUser,
  SC_KIO_051404_isAvailableToUser: SC_KIO_051404_isAvailableToUser,
  SC_KIO_051403_isAvailableToUser: SC_KIO_051403_isAvailableToUser,
  SC_KIO_051501_isAvailableToUser: SC_KIO_051501_isAvailableToUser,
};

type IsAvailableToUserFunctionsType = {
  [key in IsAvailableToUserType]: () => Promise<boolean>;
};

export default isAvailableToUserFunctions;
