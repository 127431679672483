import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import questionnaireRedux from "../../../../../../redux";
import { ZvjsCheckBoxStateValues } from "../../../../../../../components/ZvjsCheckBox";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import {
  RequestEditIcon,
  ZvjsAlert,
  ZvjsButton,
  ZvjsCheckBox,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsTable,
} from "../../../../../../../components";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SC_KIO_050701_AddPhoneCallPersonForm from "../../../forms/SC_KIO_050701_AddPhoneCallPersonForm";
import { useModal } from "../../../../../../../../store/context/modal-context";
import { useSubmit } from "react-router-dom";
import { ActionBodyWrapper } from "../../../../../../../../router/ActionBodyWrapper";
import { RequestActionsType } from "../../../../../../Request";
import { CisTypBlizkejOsoby_Dieta_ID } from "../../../../../../../specs/countersValues";
import {
  getAlertIconOfZvjsFlag,
  getSeverityOfZvjsFlag,
  ZvjsLabelFlagTypes
} from "../../../../../../redux/model";
import { Grid } from "@mui/material";
import SC_KIO_050701_EditPersonCallForm from "../../../forms/SC_KIO_050701_EditPersonCallForm";

export interface PersonRelation {
  name: string;
  surname: string;
  klientId: number;
  relation: number;
}

const SC_KIO_050701_PhoneCallCiviliansGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { openModal } = useModal();
  const { tuiz } = useUITranslation();
  const submit = useSubmit();
  const [newList, setNewList] = useState<PersonRelation[]>([]);

  console.debug(
    `SC_KIO_050701 PHONE CALL CIVILIANS GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getPhoneCallCiviliansGridDisplayData(
      location,
      newList
    ),
    shallowEqual
  );

  const closePeopleList = useSelector(
    questionnaireRedux.selectors.getPhoneCallCiviliansList(),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | string[];

  const basicClientData = useSelector(
    questionnaireRedux.selectors.getPhoneCallCiviliansBasicClientData(),
    shallowEqual
  );

  const closePersonRelationCounter = useSelector(
    questionnaireRedux.selectors.getPhoneCallCiviliansRelationTypeCounter(),
    shallowEqual
  );

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "right",
      width: 250,
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
    {
      align: "right",
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("meno")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tuiz("priezvisko")),
      ...dataStyle[1],
    },
    {
      value: capitalize(tuiz("ziadost.ziadostOnavstevu.labelVztahKosobe")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("ziadost.ziadostOtelefonovanie.labelTelefonneCislo")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("ziadost.ziadostOtelefonovanie.labelTelefonneCislo") + " 2"),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOtelefonovanie.labelPozadujemMoznostTelefonovat")
      ),
      ...dataStyle[3],
    },
    {
      value: "",
      ...dataStyle[4],
    },
  ];

  const onEditPersonSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050701_EDIT_CONTACT,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  const onChangeHandler = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    if (checked) {
      const answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy.push(key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    } else {
      let answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy = answersCopy.filter((answer) => answer !== key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    }
  };

  const bodyData: Array<RowData> = questionData.peopleList.map((personDisplayData) => {
    const isChecked = answerVal?.some((answer) => {
      return answer === personDisplayData.id.toString();
    });

    const getRequestEditIcon = () => {
      const closePersonData = closePeopleList.find(
        (closePerson) => closePerson.fyzickaOsobaId === personDisplayData.id
      );

      if (closePersonData) {
        return (
          <RequestEditIcon
            onClick={(event) =>
              openModal(
                <SC_KIO_050701_EditPersonCallForm
                  closePersonData={closePersonData}
                  closePersonDisplayData={personDisplayData}
                  onSubmit={onEditPersonSubmit}
                  closePersonRelationCounter={closePersonRelationCounter}
                />
              )
            }
          />
        );
      }
      return "";
    };

    const closePersonData = closePeopleList.find(
      (closePerson) => closePerson.fyzickaOsobaId === personDisplayData.id
    );

    const numberOfChildren = closePeopleList.filter(
      (person) => person.relationId === CisTypBlizkejOsoby_Dieta_ID
    ).length;

    const numberOfSelectedChildren =
      answerVal?.filter((answer) =>
        closePeopleList.some(
          (person) =>
            person.fyzickaOsobaId.toString() === answer &&
            person.relationId === CisTypBlizkejOsoby_Dieta_ID
        )
      ).length ?? 0;

    return {
      row: [
        {
          value: personDisplayData.firstname ?? "",
          ...dataStyle[0],
        },
        {
          value: personDisplayData.surname ?? "",
          ...dataStyle[1],
        },
        {
          value: personDisplayData.relationText ?? "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData?.phoneCallContact?.data ?? "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData?.phoneCallContact2?.data ?? "",
          ...dataStyle[2],
        },
        {
          value: (
            <ZvjsCheckBox
              onChange={onChangeHandler}
              data={[
                {
                  key: personDisplayData.id.toString(),
                  checked: isChecked,
                  disabled:
                    !isChecked &&
                    (numberOfChildren > 4
                      ? !(
                        closePersonData?.relationId ===
                        CisTypBlizkejOsoby_Dieta_ID
                      ) &&
                      (answerVal?.length ?? 0 - numberOfSelectedChildren) > 4
                      : (answerVal?.length ?? 0) > 4),
                },
              ]}
            />
          ),
          ...dataStyle[3],
        },
        {
          value: getRequestEditIcon(),
          ...dataStyle[4],
        },
      ],
    };
  });

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  const onSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050701_ADD_NEW_PERSON,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  // Check if any selected person has isPossibleToContact === false
  const isContactBlocked = closePeopleList.some(
    (person) =>
      answerVal?.includes(person.fyzickaOsobaId.toString()) &&
      person.isPossibleToContact === false
  );

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      <ZvjsButton
        zvjsVariant={"requestAction"}
        text={tuiz("ziadost.ziadostOnavstevu.labelDoplnenieInejOsoby")}
        onClick={() => {
          openModal(
            <SC_KIO_050701_AddPhoneCallPersonForm
              onSubmit={onSubmit}
              basicClientData={basicClientData}
              closePersonRelationCounter={closePersonRelationCounter}
              newList={newList}
            />
          );
        }}
        endIcon={
          <AddCircleOutlineOutlinedIcon sx={{ fontSize: "30px !important" }} />
        }
      />
      {isContactBlocked && (
        <Grid item xs mt={2}>
          <ZvjsAlert
            severity={getSeverityOfZvjsFlag(ZvjsLabelFlagTypes.INFO)}
            iconMapping={getAlertIconOfZvjsFlag(ZvjsLabelFlagTypes.INFO)}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: tuiz(
                  "ziadost.ziadostOtelefonovanie.napovedaBlokovaneCislo"
                ),
              }}
            />
          </ZvjsAlert>
        </Grid>
      )}
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050701_PhoneCallCiviliansGrid;
