import { ZvjsQuestionnaireTemplate, ZvjsQuestionTypes } from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051501",
  basicInfo: [
    {
      text: "ziadost.vseobecnaZiadost.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_051501_isAvailableToUser",
  items: [
    {
      id: "predmetZiadosti",
      title: "ziadost.vseobecnaZiadost.labelNazovAleboPredmet",
      type: ZvjsQuestionTypes.TEXT,
      validations: ["MAX_ANSWER_LENGTH_150"],
      isRequired: true,
    },
    {
      id: "obsahZiadosti",
      title: "Obsah žiadosti",
      type: ZvjsQuestionTypes.TEXT,
      multiline: true,
      minRows: 4,
      isRequired: true,
    },
  ],
};
