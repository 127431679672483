import { State } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { isAnyPorusenaPodmienka } from "../../../../../../../../utils/helpers";
import { PodmienkaZiadostiEnum } from "../../../../utils/PodmienkaZiadostiEnum";

/**
 * Selectors
 */

const displayClientHasEnoughMoneyOnAccount_SC_KIO_050301 = () =>
  createSelector(selectSelf, (state: State): boolean => {
    const questionControl = state.userCalcData["SC_KIO_050301_QUESTION_CONTROL"];
    if (questionControl === undefined || questionControl.porusenePodmienky === null){
      return false;
    }
    return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR024]);
  });
const displayClientShoppedLastCalendarMonth_SC_KIO_050301 = () =>
  createSelector(selectSelf, (state: State): boolean => {
    const questionControl = state.userCalcData["SC_KIO_050301_QUESTION_CONTROL"];
    if (questionControl === undefined || questionControl.porusenePodmienky === null){
      return false;
    }
    return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR025]);
  });

export const allSelectors = {
  displayClientHasEnoughMoneyOnAccount_SC_KIO_050301,
  displayClientShoppedLastCalendarMonth_SC_KIO_050301,
};
