import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050801",
  basicInfo: [
    {
      text: "ziadost.ziadostOpohovorSoSocPracovnikom.ZakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050801_isAvailableToUser",
  counters: ["/api/CisOblastSocialnehoPoradenstva/List"],
  items: [
    {
      id: "oblastiSocialnehoPoradenstva",
      title: "ziadost.ziadostOpohovorSoSocPracovnikom.labelOblastiPoradenstva",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      isRequired: true,
      hintText:
        "ziadost.ziadostOpohovorSoSocPracovnikom.napovedaPripravaSocPracovnika",
      options: {
        answerId: "oblastiSocialnehoPoradenstva",
        counterKey: "/api/CisOblastSocialnehoPoradenstva/List",
        textKey: "nazov",
        children: [
          {
            id: "1",
          },
          {
            id: "2",
          },
          {
            id: "3",
          },
          {
            id: "4",
          },
          {
            id: "5",
          },
          {
            id: "6",
          },
          {
            id: "7",
          },
          {
            id: "8",
          },
        ],
      },
    },
    {
      id: "upresnenieInejOblastSocialnehoPoradenstva",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "oblastiSocialnehoPoradenstva",
          value: [{ oblastiSocialnehoPoradenstva: "8" }],
        },
      ],
    },
  ],
};
