import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { API_Clients } from '../../../../../../../../store/context/dataApi/Data';
import { LoaderError } from '../../../../../../../../router/LoaderError';

const AVAILABLE_CLOTHES = "CLOTHES_TO_CHANGE";
export const ClothesChangeDropdownConstants = {
  AVAILABLE_CLOTHES: AVAILABLE_CLOTHES,
};

/**
 * OnInit
 */

export interface ProgramType {
  id: string;
  name: string;
  content?: string;
}

export const SC_KIO_051104_ClothingChangeDropdown_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { SZOO_Post } = await API_Clients();

  const data = await Promise.all([
    SZOO_Post("/api/interfaces/Cis/ListEdpoVystrojnaSucastA", {
      body: {},
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam vystrojnych sucasti]");
  }

  const listOfClothes = (data[0].data ?? []).map((program: any) => ({
    value: program.edpoVystrojnaSucastId,
    text: program.nazov,
  }));

  console.log(listOfClothes);

  calcData[ClothesChangeDropdownConstants.AVAILABLE_CLOTHES] = listOfClothes;

  console.log(calcData[
    ClothesChangeDropdownConstants.AVAILABLE_CLOTHES
    ]);
};

/**
 * Selectors
 */

const getClothesToChangeDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      options: state.userCalcData[
        ClothesChangeDropdownConstants.AVAILABLE_CLOTHES
      ],
      isRequired: true,
    };
  });

export const allSelectors = {
  getClothesToChangeDisplayData,
};
