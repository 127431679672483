import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";

import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(
    `VyplatnePaskyTab loadData datumOd[${datumOd}] datumDo[${datumDo}]`
  );

  const { FEOO_Post } = await API_Clients();

  const vyplatnePasky = await FEOO_Post(
    "/api/integracie/Kiosk/ListVyplatnaPaska",
    {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }
  );

  if (vyplatnePasky.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [výplatné pásky]");
  }

  if (datumOd) {
    vyplatnePasky.data = vyplatnePasky.data?.filter(
      (item) =>
        item.mesiac &&
        item.rok &&
        datumOd &&
        new Date(
          moment(`${item.rok}-${item.mesiac}`, "YYYY-M").toISOString()
        ) >= new Date(datumOd)
    );
  }

  if (datumDo) {
    vyplatnePasky.data = vyplatnePasky.data?.filter(
      (item) =>
        item.mesiac &&
        item.rok &&
        datumDo &&
        new Date(
          moment(`${item.rok}-${item.mesiac}`, "YYYY-M").toISOString()
        ) <= new Date(datumDo)
    );
  }

  return {
    vyplatnePasky: vyplatnePasky,
  };
};

export const VyplatnePaskyTabLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

export interface VyplatnePaskyTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const VyplatnePaskyTab = (props: VyplatnePaskyTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(
      `VyplatnePaskyTab onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSearchBar
        title={tui("Vyhľadať výplatnú pásku")} // TODO osobneUdaje.vyplatnePasky.vyhladatVyplatnuPasku
        type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
        onSubmit={onSearch}
      />
      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("osobneUdaje.vyplatnePasky.rok")),
              align: "left",
              width: "26%",
            },
            {
              value: capitalize(tui("osobneUdaje.vyplatnePasky.mesiac")),
              align: "left",
              width: "37%",
            },
            {
              value: capitalize(tui("Suma z rozúčtovania na konto")), // TODO: osobneUdaje.vyplatnePasky.sumaRozuctovaniaNaKonto
              align: "left",
              width: "37%",
            },
          ],
          body:
            props.data.vyplatnePasky.data
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((vyplatnaPaska) => {
                const rd: RowData = {
                  row: [
                    {
                      value: vyplatnaPaska.rok ?? "",
                      align: "left",
                      width: "26%",
                    },
                    {
                      value: vyplatnaPaska.mesiac ?? "",
                      align: "left",
                      width: "37%",
                    },
                    {
                      value: vyplatnaPaska.cistaPracovnaOdmenaAFo
                        ? vyplatnaPaska.cistaPracovnaOdmenaAFo.toFixed(2) +
                          " EUR"
                        : "",
                      align: "left",
                      width: "37%",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(VyplatnePaskyTab);
