import React, { useRef } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import classes from "./ZvjsSelect.module.scss";
import { ZvjsAlert, ZvjsHintModalFragment } from "./index";
import { useUITranslation } from "../../store/context/translation-context";
import {
  getSeverityOfValidationFlag,
  ZvjsValidationFlagTypes,
} from "../request/redux/model";
import { ChangeHandler, RefCallBack } from "react-hook-form";
import { isEmptyArray } from "../../utils/helpers";

export interface ZvjsSelectItem {
  value: number | string;
  text: string;
  icon?: {
    src: string;
    alt: string;
  };
}

interface ZvjsSelectProps<T> {
  id?: string;
  items: ZvjsSelectItem[];
  text?: string;
  // if select is supposed to be controlled, this prop needs to be filled out
  controlled?: {
    changeHandler: (event: SelectChangeEvent<T>) => void;
    selectedValue: T;
  };
  // if select is supposed to be uncontrolled, this prop needs to be filled out
  uncontrolled?: {
    defaultValue: T;
  };
  muiSelectSx?: SxProps;
  hintTitle?: string;
  hintText?: string;
  hintColor?: string;
  hintSize?: number;
  answerRequired?: boolean;
  error?: boolean;
  validationErrorMessage?: string;
  validationFlagType?: ZvjsValidationFlagTypes;
  disabled?: boolean;
  // used in react-hook-form
  register?: {
    onBlur: ChangeHandler;
    ref: RefCallBack;
    min?: string | number;
    onChange: ChangeHandler;
    max?: string | number;
    minLength?: number;
    name: string;
    pattern?: string;
    disabled?: boolean;
    maxLength?: number;
    required?: boolean;
  };
}

// Warning: Do not place select near to the viewport edge.
// It is a known MUI issue: https://github.com/mui/material-ui/issues/35155
const ZvjsSelect = <T,>({
  items,
  text,
  controlled,
  uncontrolled,
  id,
  muiSelectSx,
  hintTitle,
  hintText,
  hintColor,
  hintSize,
  answerRequired,
  error,
  validationErrorMessage,
  validationFlagType = ZvjsValidationFlagTypes.ERROR,
  disabled,
  register,
}: ZvjsSelectProps<T>) => {
  const { tui } = useUITranslation();
  const selectRef = useRef<HTMLDivElement | null>(null);

  return (
    <Grid container>
      <FormControl sx={{ textAlign: "start" }} fullWidth>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {text}
            {answerRequired && "*"}
          </Typography>
          {hintText && (
            <ZvjsHintModalFragment
              title={hintTitle === undefined ? tui("nápoveda") : hintTitle} //todo change translation
              text={hintText}
              color={hintColor}
              size={hintSize}
            />
          )}
        </Stack>
        <Select
          id={id}
          labelId={id}
          ref={selectRef}
          value={controlled?.selectedValue}
          onChange={controlled?.changeHandler}
          IconComponent={({ className }) => {
            return <KeyboardArrowDown className={className} />;
          }}
          error={error}
          disabled={disabled}
          onClose={() => {
            // Used to overcome issue between mui Select and react-simple-pull-to-refresh lib
            // mouseup event is normally called after you release the left mouse button but this is not happening when you click on mui select - therefore we trigger mouseup event manually via which we simulate releasing of the mouse left button
            // https://github.com/thmsgbrt/react-simple-pull-to-refresh/issues/88
            if (selectRef.current) {
              const event = new MouseEvent("mouseup", {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              selectRef.current.dispatchEvent(event);
            }
          }}
          defaultValue={uncontrolled?.defaultValue}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 300,
                overflowY: "auto",
                border: `2px solid ${ZVJS_COLORS.BLACK}`,
              },
            },
          }}
          sx={{
            ...{
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  border: `2px solid ${ZVJS_COLORS.BLACK}`,
                },
                "&.Mui-focused fieldset": {
                  border: `2px solid ${ZVJS_COLORS.BLACK}`,
                },
              },
              height: 45,
            },
            ...muiSelectSx,
          }}
          {...register}
        >
          {isEmptyArray(items) && (
            <MenuItem disabled={true} value={""}>
              {/*TODO add translation*/}
              {tui("Nie sú k dispozícii žiadne možnosti")}
            </MenuItem>
          )}
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.value}
                sx={
                  index !== 0
                    ? { borderTop: `1px solid ${ZVJS_COLORS.GREY_2}` }
                    : {}
                }
              >
                {item.icon ? (
                  <>
                    <img
                      className={classes["menu-item-icon"]}
                      src={item.icon.src}
                      alt={item.icon.alt}
                    />
                    {item.text}
                  </>
                ) : (
                  item.text
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {error === true && validationErrorMessage && validationFlagType && (
        <Grid item xs>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationFlagType)}
            style={{ marginTop: "1rem" }}
          >
            {validationErrorMessage}
          </ZvjsAlert>
        </Grid>
      )}
    </Grid>
  );
};

export default ZvjsSelect;
