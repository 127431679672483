import {
  Calculate,
  ZvjsConditionTypes, ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate, ZvjsQuestionTypes,
} from "../redux/model";

export const PERSON_TYPE_ID = "personType";

export const SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST =
  "SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST";

export const SC_KIO_050101_CLOSE_PEOPLE_LIST =
  "SC_KIO_050101_CLOSE_PEOPLE_LIST";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050701",
  basicInfo: [
    {
      text: "ziadost.ziadostOtelefonovanie.zakladneInfoObvineny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_ACCUSED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOtelefonovanie.zakaldneInfoOdsudeny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_SENTENCED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
  isAvailableToUser: "SC_KIO_050701_isAvailableToUser",
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050101_CLOSE_PEOPLE_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    BASIC_CLIENT_DATA: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050701_QUESTION_CONTROL: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST: {
      calculateAt: Calculate.AT_INIT,
    }
  },
  counters: [
    "/api/CisTypBlizkejOsoby/List",
  ],
  items: [
    {
      id: "SC_KIO_050701_NOT_ALLOWED_TO_SEND_REQUEST_LABEL_QUESTION",
      type: ZvjsCustomQuestionTypes.SC_KIO_050701_NOT_ALLOWED_TO_SEND_REQUEST_LABEL,
      title: "",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: PERSON_TYPE_ID,
      title: "Žiadosť o telefonovanie",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
      options: [
        {
          id: "CIOS",
          label: "Civilná osoba",
        },
        {
          id: "OBAD",
          label: "Obhajca/advokát na telefonát",
        },
      ],
    },
    {
      id: "civilnaOsobaVoVVVT",
      title:
        "Civilná osoba vo VV/VT",
      type: ZvjsQuestionTypes.SINGLE_CHECKBOX_DISABLED,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: PERSON_TYPE_ID,
          value: ["CIOS"],
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050701_IS_ANY_CLOSE_PERSON_IN_PRISON",
          conditionMetWhenValueIs: true,
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "civilneOsobyList",
      title: "Civilná osoba",
      type: ZvjsCustomQuestionTypes.SC_KIO_050701_PHONE_CALL_CIVILIANS_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: PERSON_TYPE_ID,
          value: ["CIOS"],
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "obhajcoviaAdvokatiList",
      title: "Obhajca/advokát na telefonát",
      type: ZvjsCustomQuestionTypes.SC_KIO_050701_PHONE_CALL_LAWYERS_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: PERSON_TYPE_ID,
          value: ["OBAD"],
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050701_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
  ],
};
