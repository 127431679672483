import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051403",
  basicInfo: [
    {
      text: "ziadost.ziadostOpremiestnenie.zakladneInfoOdsudeny",
    },
  ],
  isAvailableToUser: "SC_KIO_051403_isAvailableToUser",
  dataCalculations: {
    SC_KIO_051403_SHOULD_SHOW_PRIORITY_QUESTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_051403_QUESTION_CONTROL: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  counters: ["/api/CisDovodPodaniaZiadosti/List", "/api/CisUstavZvjs/List"],
  items: [
    {
      id: "SC_KIO_051403_NOT_ALLOWED_TO_SEND_REQUEST_LABEL_QUESTION",
      type: ZvjsCustomQuestionTypes.SC_KIO_051403_NOT_ALLOWED_TO_SEND_REQUEST_LABEL,
      title: "",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "miestoPozadovanehoPremiestnenia",
      type: ZvjsCustomQuestionTypes.SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION,
      title: "ziadost.ziadostOpremiestnenie.labelMiestoPremiestnenia",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    /*{
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOpremiestnenie.napovedaStupenStrazenie",
      flag: ZvjsLabelFlagTypes.WARNING,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc:
            "SC_KIO_050101_SHOULD_DISPLAY_INVITED_PERSON_IN_PRISON_WARNING",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOpremiestnenie.napovedaUbytovaciaPlocha",
      flag: ZvjsLabelFlagTypes.WARNING,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc:
            "SC_KIO_050101_SHOULD_DISPLAY_INVITED_PERSON_IN_PRISON_WARNING",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "warnings",
      type: ZvjsCustomQuestionTypes.SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION,
      title: "",
    },
    */
    {
      id: "prioritnyUstav",
      type: ZvjsCustomQuestionTypes.SC_KIO_051403_PRIORITY_QUESTION,
      title: "Prioritný ústav",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051403_SHOULD_SHOW_PRIORITY_QUESTION",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "dovodPodaniaZiadosti",
      title: "Dôvod podania žiadosti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST",
          conditionMetWhenValueIs: false,
        },
      ],
      options: {
        counterKey: "/api/CisDovodPodaniaZiadosti/List",
        textKey: "dovod",
        optValues: [
          {
            id: "ROD",
          },
          {
            id: "BEZP",
          },
          {
            id: "ZMENA",
          },
          {
            id: "INED",
          },
        ],
      },
      validations: ["SC_KIO_051403_VALIDATE_RELOCATION_REASON"],
    },
    {
      id: "doplnujuceInformacie",
      title: "ziadost.ziadostOpremiestnenie.labelBlizsiaSpecifikaciaDovodu",
      type: ZvjsQuestionTypes.TEXT,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051403_IS_CLIENT_ALLOWED_TO_SEND_REQUEST",
          conditionMetWhenValueIs: false,
        },
      ],
    },
  ],
};
