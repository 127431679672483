import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import {API_Clients} from "../../../../../../../../store/context/dataApi/Data";

const AVAILABLE_GROUPS = "GROUPS";
export const GroupQuestionConstants = {
  AVAILABLE_GROUPS,
};

/**
 * OnInit
 */

export interface GroupType {
  id: string;
  name: string;
}

export interface ZvjsOprapEpvvvtZoznamKruzkov {
  oraKruzokVoFazeKoordinacieId?: number | null;
  kruzokBehId?: number | null;
  ustavId?: number | null;
  ustavNazov?: string | null;
  ustavKod?: string | null;
  typKruzkuId?: number | null;
  typKruzkuNazov?: string | null;
  typKruzkuKod?: string | null;
  specializaciaKruzkuId?: number | null;
  specializaciaKruzkuNazov?: string | null;
  specializaciaKruzkuKod?: string | null;
  kontraindikacie?: string | null;
  behKruzkuNazov?: string | null;
  behTerminZaciatku?: string | null;
  behTerminUkoncenia?: string | null;
  behMaxPocetClenov?: number | null;
  behMiestoRealizacie?: string | null;
  oraAdresatiId?: number | null;
  skupinaObvinenychKlientov?: boolean | null;
  skupinaOdsudenychKlientov?: boolean | null;
  skupinaAdresatovId?: number | null;
  skupinaAdresatovKod?: string | null;
  skupinaAdresatovNazov?: string | null;
  oraOsobaZodpovednaZaRealizaciuVrptaId?: number | null;
  oraTriedaId?: number | null;
  oraPoskytovatelProgramuId?: number | null;
  typOsobyId?: number | null;
  typOsobyKod?: string | null;
  typOsobyNazov?: string | null;
  pouzivatelId?: string | null;
  externyPoskytovatelNazov?: string | null;
  externyPoskytovatelIco?: string | null;
  externyPoskytovatelPravnickaOsobaId?: number | null;
}

export const SC_KIO_050604_GroupQuestion_onInit = async (calcData: UserCalcData): Promise<void> => {

  const { SHARE_INT_Post } = await API_Clients();

  // TODO filter out groups which client cannot apply for
  const data = await Promise.any([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListZoznamKruzkov", {
      body: {},
    }),
  ]);
  
  calcData[GroupQuestionConstants.AVAILABLE_GROUPS] = mapZoznamKruzkov(data) ?? [];
};

const mapZoznamKruzkov = (data: any): GroupType[] => data && data?.data?.map((el: ZvjsOprapEpvvvtZoznamKruzkov) => ({
  id: `${el?.typKruzkuId}`,
  name: el?.typKruzkuNazov
}));

/**
 * Selectors
 */

const getGroupQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      answerRequired: question.isRequired,
      options: (
        state.userCalcData[
          GroupQuestionConstants.AVAILABLE_GROUPS
        ] as GroupType[]
      ).map((item) => {
        return { value: item.id, text: item.name };
      }),
    };
  });

export const allSelectors = {
  getGroupQuestionDisplayData,
};
