import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import ClearIcon from "@mui/icons-material/Clear";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { useModal } from "../../../../../../store/context/modal-context";
import {
  ZvjsButton, ZvjsSectionUI,
  ZvjsSelect,
  ZvjsTextField,
} from "../../../../../components";
import {
  getTranslationByLanguageCode,
  isSubmitting,
} from "../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../locales/i18n";
import i18n from "i18next";
import { Params, useNavigation } from "react-router-dom";
import { ActionResultTypes } from "../../../../../../router/ActionResult";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import ZvjsDateSelect from "../../../../../components/ZvjsDateSelect";
import {
  DetailZakladneUdajeKlientaData,
} from "../../../../../specs/swaggerTypes";
import {
  CisPohlavie_NEZISTENE_ID, EooCiselnikTypKontaktu_Mobil_ID,
  EooCiselnikTypKontaktu_Telefon_ID,
} from "../../../../../specs/countersValues";
import {
  addNewOptionLawyer,
  RequestActionResult,
  RequestActionsType,
} from "../../../../Request";
import { LoaderError } from "../../../../../../router/LoaderError";

interface AddNewPersonSubmitData {
  basicClientData: DetailZakladneUdajeKlientaData;
  formData: AddPersonFormData;
}

export async function SC_KIO_050701_addPhoneCallLawyer({
  params,
  data,
}: {
  params: Params;
  data: unknown;
}): Promise<RequestActionResult> {
  const { EOO_Post } = await API_Clients();

  const dataToCreateClosePerson = data as AddNewPersonSubmitData;

  const resultNovaFyzickaOsoba = await EOO_Post(
    "/api/FyzickaOsoba/CreateFyzickaOsoba",
    {
      body: {
        meno: dataToCreateClosePerson.formData.firstname,
        priezvisko: dataToCreateClosePerson.formData.surname,
        datumNarodenia: dataToCreateClosePerson.formData.dateOfBirth,
        pohlavieId: CisPohlavie_NEZISTENE_ID,
      },
    }
  );

  if (resultNovaFyzickaOsoba.error || !resultNovaFyzickaOsoba.response.ok) {
    if (
      resultNovaFyzickaOsoba.response.status === 503 &&
      resultNovaFyzickaOsoba.response.headers.get("X-Zvjs-Offline") ===
        "offline"
    ) {
      return {
        type: ActionResultTypes.ERROR,
        // TODO add translation
        message: "Akcia nie je na tablete podporovaná",
        action: RequestActionsType.SC_KIO_050701_ADD_NEW_LAWYER,
      };
    }

    return {
      type: ActionResultTypes.ERROR,
      // TODO add translation
      message: "Osobu sa nepodarilo pridať",
      action: RequestActionsType.SC_KIO_050701_ADD_NEW_LAWYER,
    };
  }

  const resultNovyTelKontakt = await EOO_Post("/api/FyzickaOsoba/CreateKontakt", {
    body: {
      typId: EooCiselnikTypKontaktu_Telefon_ID,
      osobaId: resultNovaFyzickaOsoba.data.data?.fyzickaOsobaId,
      data: dataToCreateClosePerson.formData.phoneCallContact,
      platnostOd: new Date().toISOString(),
      primarny: true,
    },
  });

  const resultNovyTelKontakt2 = await EOO_Post("/api/FyzickaOsoba/CreateKontakt", {
    body: {
      typId: EooCiselnikTypKontaktu_Mobil_ID,
      osobaId: resultNovaFyzickaOsoba.data.data?.fyzickaOsobaId,
      data: dataToCreateClosePerson.formData.phoneCallContact2,
      platnostOd: new Date().toISOString(),
      primarny: true,
    },
  });

  await addNewOptionLawyer(params.requestCode, {
    id: undefined,
    fyzickaOsobaId: resultNovaFyzickaOsoba.data.data?.fyzickaOsobaId ?? 0,
    firstname: resultNovaFyzickaOsoba.data.data?.meno,
    surname: resultNovaFyzickaOsoba.data.data?.priezvisko,
    phoneCallAsCivilian: true,
    phoneCallContact: resultNovyTelKontakt?.data?.data ?? undefined,
    phoneCallContact2: resultNovyTelKontakt2?.data?.data ?? undefined,
  });

  return {
    type: ActionResultTypes.SUCCESS,
    action: RequestActionsType.SC_KIO_050701_ADD_NEW_LAWYER,
  };
}

const generateSchema = (tuiz: (key: string) => string) =>
  yup
    .object({
      firstname: yup
        .string()
        .required(capitalize(tuiz("Napíšte meno")))
        .default(""), // todo add translation
      surname: yup
        .string()
        .required(capitalize(tuiz("Napíšte priezvisko")))
        .default(""), // todo add translation
      dateOfBirth: yup
        .string()
        .required(capitalize(tuiz("Napíšte dátum narodenia")))
        .default(""),
      phoneCallContact: yup
        .string()
        .required(capitalize(tuiz("Napíšte telefónne číslo")))
        .default(""), // todo add translation
      phoneCallContact2: yup
        .string()
        .notRequired()
        .default(""),
      // todo add translation
      vecZastupovanaAdvokatom: yup
        .number()
        // valid ids start from 0
        .min(0, capitalize(tuiz("Vyberte vec zastupovanú advokátom")))
        .default(-1),
      oznacenieKonania: yup
        .string()
        .required(capitalize(tuiz("Napíšte číslo alebo iné preukázateľné označenie konania")))
        .default(""), // todo add translation
    })
    .required();
type AddPersonFormData = yup.InferType<ReturnType<typeof generateSchema>>;

interface PersonRelation {
  name: string;
  surname: string;
  klientId: number;
  relation: number;
}

interface SC_KIO_050701_AddPhoneCallLawyerFormProps {
  onSubmit: (toSubmit: unknown) => void;
  basicClientData: DetailZakladneUdajeKlientaData;
  // closePersonRelationCounter: CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"];
  newList?: PersonRelation[];
}

const SC_KIO_050701_AddPhoneCallLawyerForm = ({
  onSubmit,
  basicClientData,
  newList,
}: {
  onSubmit: (toSubmit: unknown) => void;
  basicClientData: DetailZakladneUdajeKlientaData;
  newList?: PersonRelation[];
}) => {
  const { closeModal } = useModal();
  const { tuiz, getFallbackJazyk } = useUITranslation();
  const navigation = useNavigation();

  console.log(getFallbackJazyk);

  const [data, setData] = useState<any[]>([]);
  const [agree, setAgree] = useState<boolean | null>(null);

  const schema = generateSchema(tuiz);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(undefined),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchData = async () => {
      const { SZOO_Post } = await API_Clients();
      const result = await SZOO_Post("/api/interfaces/Cis/ListKioCisVecZastupovanaAdvokatomA", {
        body: {},
      });
      if (result.error) {
        throw new LoaderError("Nepodarilo sa načítať dáta [vec zastupovana advokatom]");
      }
      setData(result.data || []);
    };

    fetchData();
  }, []);

  const handleOnSubmit = (formData: AddPersonFormData) => {
    const toSubmit: AddNewPersonSubmitData = {
      formData: formData,
      basicClientData: basicClientData,
    };
    onSubmit(toSubmit);
  };

  return (
    <Stack width={"70vw"} maxHeight={"80vh"} overflow={"auto"}>
      <Typography variant={"h2"} mb={2}>
        {capitalize(tuiz("ziadost.ziadostOtelefonovanie.btnPridatNovehoObhajcu"))}
      </Typography>
      <form
        style={{ width: "20 rem" }}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Stack spacing={1}>
          <ZvjsTextField
            label={capitalize(tuiz("Meno"))}
            register={{ ...register("firstname") }}
            error={errors.firstname !== undefined}
            validationErrorMessage={errors.firstname?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Priezvisko"))}
            register={{ ...register("surname") }}
            error={errors.surname !== undefined}
            validationErrorMessage={errors.surname?.message}
          />
          <ZvjsDateSelect
            text={capitalize(tuiz("Dátum narodenia"))}
            register={{ ...register("dateOfBirth") }}
            error={errors.dateOfBirth !== undefined}
            validationErrorMessage={errors.dateOfBirth?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Telefónne číslo"))}
            register={{ ...register("phoneCallContact") }}
            error={errors.phoneCallContact !== undefined}
            validationErrorMessage={errors.phoneCallContact?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Telefónne číslo 2"))}
            register={{ ...register("phoneCallContact2") }}
            error={errors.phoneCallContact2 !== undefined}
            validationErrorMessage={errors.phoneCallContact2?.message}
          />
          <ZvjsSelect
            text={capitalize(tuiz("ziadost.ziadostOtelefonovanie.labelZastupovanaVec"))}
            register={{ ...register("vecZastupovanaAdvokatom") }}
            items={
              data
                ?.filter((vecZastupovanaAdvokatom) => vecZastupovanaAdvokatom.kioCisVecZastupovanaAdvokatomId)
                .reduce<{ value: string; text: string }[]>((uniqueItems, vecZastupovanaAdvokatom) => {
                  const existingItem = uniqueItems.find(
                    (item) => item.value === vecZastupovanaAdvokatom.kioCisVecZastupovanaAdvokatomId
                  );
                  if (!existingItem) {
                    uniqueItems.push({
                      value: vecZastupovanaAdvokatom.kioCisVecZastupovanaAdvokatomId,
                      text: getTranslationByLanguageCode(
                        data ?? [],
                        getCiselnikJazyk(i18n.language),
                        getFallbackJazyk(),
                        vecZastupovanaAdvokatom.kod,
                        "nazov"
                      ),
                    });
                  }
                  return uniqueItems;
                }, []) ?? []
            }
            uncontrolled={{ defaultValue: "" }}
            error={errors.vecZastupovanaAdvokatom !== undefined}
            validationErrorMessage={errors.vecZastupovanaAdvokatom?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("ziadost.ziadostOtelefonovanie.labelCisloKonania"))}
            register={{ ...register("oznacenieKonania") }}
            error={errors.oznacenieKonania !== undefined}
            validationErrorMessage={errors.oznacenieKonania?.message}
          />
          <Grid item xs>
            <ZvjsSectionUI
              sectionTitles={["Súhlas - prístup ako k civilným osobám"]}
            >
              <Stack spacing={2}>
                <Typography>
                  {capitalize(
                    tuiz(
                      "ziadost.ziadostOtelefonovanie.napovedaOverenieAdvokata"
                    )
                  )}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agree === true}
                        onChange={() => setAgree(true)}
                      />}
                    label="Áno"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agree === false}
                        onChange={() => setAgree(false)}
                      />}
                    label="Nie"
                  />
                </Stack>
              </Stack>
            </ZvjsSectionUI>
          </Grid>
        </Stack>
        <Stack direction={"row"} spacing={2} mt={3} justifyContent={"center"}>
          <ZvjsButton
            type={"submit"}
            zvjsVariant={"secondaryAction"}
            disabled={
              Object.keys(errors).length > 0 || isSubmitting(navigation) || agree !== true
            }
            text={capitalize(tuiz("Pridať osobu"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            disabled={isSubmitting(navigation)}
            text={capitalize(tuiz("Zrušiť"))}
            startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
            onClick={closeModal}
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default SC_KIO_050701_AddPhoneCallLawyerForm;
