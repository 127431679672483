import React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import ClearIcon from "@mui/icons-material/Clear";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { useModal } from "../../../../../../store/context/modal-context";
import {
  ZvjsButton,
  ZvjsSelect,
  ZvjsTextField,
} from "../../../../../components";
import {
  getTranslationByLanguageCode,
  isSubmitting,
} from "../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../locales/i18n";
import i18n from "i18next";
import { Params, useNavigation } from "react-router-dom";
import { ActionResultTypes } from "../../../../../../router/ActionResult";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import {
  SC_KIO_050502_ClosePerson
} from "../../utils/calculations/custom";
import { CiselnikListResultType } from "../../../../../../store/context/dataApi/Data";
import {
  RequestActionsType,
  RequestActionResult,
} from "../../../../Request";
import {
  SC_KIO_050502_PersonReceivingPackageDisplayData
} from "../questions/customQuestions/SC_KIO_050502_PersonReceivingPackageGrid/redux";

interface EditPersonContactSubmitData {
  formData: PersonFormData;
  closePersonData: SC_KIO_050502_ClosePerson;
}
export async function SC_KIO_050502_editAddressContact({
  params,
  data,
}: {
  params: Params;
  data: unknown;
}): Promise<RequestActionResult> {
  const { EOO_Post } = await API_Clients();

  const dataToEditPersonContact = data as EditPersonContactSubmitData;

  if (dataToEditPersonContact.closePersonData.adresaId) {
    const updatedClosePersonData = {
      adresaId: dataToEditPersonContact.closePersonData.adresaId,
      statNazov: dataToEditPersonContact.formData.stat,
      krajNazov: dataToEditPersonContact.formData.kraj,
      obecNazov: dataToEditPersonContact.formData.obec,
      psc: dataToEditPersonContact.formData.psc,
      ulica: dataToEditPersonContact.formData.ulica,
      supisneCislo: dataToEditPersonContact.formData.supisneCislo,
      orientacneCislo: dataToEditPersonContact.formData.orientacneCislo,
    };

    const resultUpdatedKontakt = await EOO_Post(
      "/api/FyzickaOsoba/UpdateAdresaOsoba",
      {
        body: updatedClosePersonData,
      }
    );

    if (resultUpdatedKontakt.error || !resultUpdatedKontakt.response.ok) {
      if (
        resultUpdatedKontakt.response.status === 503 &&
        resultUpdatedKontakt.response.headers.get("X-Zvjs-Offline") ===
        "offline"
      ) {
        return {
          type: ActionResultTypes.SUCCESS,
          action: RequestActionsType.SC_KIO_050502_EDIT_ADDRESS_CONTACT,
          // TODO add translation
          message:
            "Požiadavka na zmenu údajov bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.",
        };
      }

      return {
        type: ActionResultTypes.ERROR,
        message: "Kontakt na videohovor sa nepodarilo upraviť",
        action: RequestActionsType.SC_KIO_050502_EDIT_ADDRESS_CONTACT,
      };
    }
  } else {
    const resultNovaAdresa = await EOO_Post(
      "/api/FyzickaOsoba/CreateAdresaOsoba",
      {
        body: {
          adresaOsobaId: dataToEditPersonContact.closePersonData.fyzickaOsobaId,
          // platnostOd: new Date().toISOString(),
          stat: dataToEditPersonContact.formData.stat,
          kraj: dataToEditPersonContact.formData.kraj,
          obec: dataToEditPersonContact.formData.obec,
          psc: dataToEditPersonContact.formData.psc,
          ulica: dataToEditPersonContact.formData.ulica,
          supisneCislo: dataToEditPersonContact.formData.supisneCislo,
          orientacneCislo: dataToEditPersonContact.formData.orientacneCislo,
        },
      }
    );

    if (resultNovaAdresa.error || !resultNovaAdresa.response.ok) {
      if (
        resultNovaAdresa.response.status === 503 &&
        resultNovaAdresa.response.headers.get("X-Zvjs-Offline") === "offline"
      ) {
        return {
          type: ActionResultTypes.SUCCESS,
          action: RequestActionsType.SC_KIO_050502_EDIT_ADDRESS_CONTACT,
          // TODO add translation
          message:
            "Požiadavka na zmenu údajov bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.",
        };
      }

      return {
        type: ActionResultTypes.ERROR,
        message: "Kontakt na videohovor sa nepodarilo upraviť",
        action: RequestActionsType.SC_KIO_050502_EDIT_ADDRESS_CONTACT,
      };
    }
  }

  return {
    type: ActionResultTypes.SUCCESS,
    action: RequestActionsType.SC_KIO_050502_EDIT_ADDRESS_CONTACT,
  };
}

const generateSchema = (
  tuiz: (key: string) => string,
  closePersonData: SC_KIO_050502_ClosePerson,
  closePersonDisplayData?: SC_KIO_050502_PersonReceivingPackageDisplayData
) =>
  yup
    .object({
      firstname: yup.string().default(closePersonDisplayData?.firstname ?? ""), // todo add translation
      surname: yup.string().default(closePersonDisplayData?.surname ?? ""), // todo add translation
      // default value needs to be passed to ZvjsSelect component also directly
      // (yup default holds value stored in react-hook-form, value passed to ZvjsSelect is shown as default value in ui (basically placeholder))
      relation: yup.number().default(closePersonData?.relationId), // todo add translation
      stat: yup
        .string()
        .required(capitalize(tuiz("Napíšte štát")))
        .default(closePersonDisplayData?.stat ?? ""), // todo add translation
      kraj: yup
        .string()
        .required(capitalize(tuiz("Napíšte kraj")))
        .default(closePersonDisplayData?.kraj ?? ""), // todo add translation
      okres: yup
        .string()
        .required(capitalize(tuiz("Napíšte okres")))
        .default(closePersonDisplayData?.okres ?? ""), // todo add translation
      obec: yup
        .string()
        .required(capitalize(tuiz("Napíšte obec")))
        .default(closePersonDisplayData?.obec ?? ""), // todo add translation
      psc: yup
        .string()
        .required(capitalize(tuiz("Napíšte PSČ")))
        .default(closePersonDisplayData?.psc ?? ""), // todo add translation
      ulica: yup
        .string()
        .required(capitalize(tuiz("Napíšte ulicu")))
        .default(closePersonDisplayData?.ulica ?? ""), // todo add translation
      supisneCislo: yup
        .string()
        .required(capitalize(tuiz("Napíšte súpisné číslo")))
        .default(closePersonDisplayData?.supisneCislo ?? ""), // todo add translation
      orientacneCislo: yup
        .string()
        .required(capitalize(tuiz("Napíšte orientačné číslo")))
        .default(closePersonDisplayData?.orientacneCislo ?? ""), // todo add translation
    })
    .required();
type PersonFormData = yup.InferType<ReturnType<typeof generateSchema>>;

interface SC_KIO_050502_AddressFormProps {
  closePersonData: SC_KIO_050502_ClosePerson;
  closePersonDisplayData?: SC_KIO_050502_PersonReceivingPackageDisplayData;
  onSubmit: (toSubmit: unknown) => void;
  closePersonRelationCounter: CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"];
}

const SC_KIO_0505021_EditAddressForm: React.FC<
  SC_KIO_050502_AddressFormProps
> = ({
  closePersonData,
  closePersonDisplayData,
  onSubmit,
  closePersonRelationCounter,
}) => {
  const { closeModal } = useModal();
  const { tuiz, getFallbackJazyk } = useUITranslation();
  const navigation = useNavigation();

  const schema = generateSchema(tuiz, closePersonData, closePersonDisplayData);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(undefined),
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (formData: PersonFormData) => {
    const toSubmit: EditPersonContactSubmitData = {
      formData: formData,
      closePersonData: closePersonData,
    };
    onSubmit(toSubmit);
  };

  return (
    <Stack width={"70vw"} maxHeight={"80vh"} overflow={"auto"}>
      {/* todo add translation */}
      <Typography variant={"h2"} mb={2}>
        {/*TODO add translation*/}
        {capitalize(tuiz("Zmena údajov"))}
      </Typography>{" "}
      <form
        style={{ width: "20 rem" }}
        onSubmit={handleSubmit(handleOnSubmit)}
        // TODO think about how to make live validation work - low priority
        // onBlur={handleSubmit(dummyFunction)}
      >
        <Stack spacing={1}>
          {/*/!* todo add translation *!/*/}
          <ZvjsTextField
            label={capitalize(tuiz("Meno"))}
            register={{ ...register("firstname") }}
            error={errors.firstname !== undefined}
            validationErrorMessage={errors.firstname?.message}
            disabled
          />
          <ZvjsTextField
            label={capitalize(tuiz("Priezvisko"))}
            register={{ ...register("surname") }}
            error={errors.surname !== undefined}
            validationErrorMessage={errors.surname?.message}
            disabled
          />
          <ZvjsSelect
            text={capitalize(tuiz("Vzťah k osobe"))}
            register={{ ...register("relation") }}
            items={
              closePersonRelationCounter?.records
                ?.filter((relation) => relation.id)
                .map((relation) => {
                  if (relation.id && relation.kod) {
                    return {
                      value: relation.id,
                      text: getTranslationByLanguageCode(
                        closePersonRelationCounter?.records ?? [],
                        getCiselnikJazyk(i18n.language),
                        getFallbackJazyk(),
                        relation.kod,
                        "nazov"
                      ),
                    };
                  }
                  throw new Error();
                }) ?? []
            }
            uncontrolled={{ defaultValue: closePersonData?.relationId }}
            error={errors.relation !== undefined}
            validationErrorMessage={errors.relation?.message}
            disabled
          />
          <ZvjsTextField
            label={capitalize(tuiz("Štát"))}
            register={{ ...register("stat") }}
            error={errors.stat !== undefined}
            validationErrorMessage={errors.stat?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Kraj"))}
            register={{ ...register("kraj") }}
            error={errors.kraj !== undefined}
            validationErrorMessage={errors.kraj?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Okres"))}
            register={{ ...register("okres") }}
            error={errors.okres !== undefined}
            validationErrorMessage={errors.okres?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Obec"))}
            register={{ ...register("obec") }}
            error={errors.obec !== undefined}
            validationErrorMessage={errors.obec?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("PSČ"))}
            register={{ ...register("psc") }}
            error={errors.psc !== undefined}
            validationErrorMessage={errors.psc?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Ulica"))}
            register={{ ...register("ulica") }}
            error={errors.ulica !== undefined}
            validationErrorMessage={errors.ulica?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Súpisné číslo"))}
            register={{ ...register("supisneCislo") }}
            error={errors.supisneCislo !== undefined}
            validationErrorMessage={errors.supisneCislo?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Orientačné číslo"))}
            register={{ ...register("orientacneCislo") }}
            error={errors.orientacneCislo !== undefined}
            validationErrorMessage={errors.orientacneCislo?.message}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} mt={3} justifyContent={"center"}>
          <ZvjsButton
            type={"submit"}
            zvjsVariant={"secondaryAction"}
            disabled={
              Object.keys(errors).length > 0 || isSubmitting(navigation)
            }
            text={capitalize(tuiz("Upraviť osobu"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            disabled={isSubmitting(navigation)}
            text={capitalize(tuiz("Zrušiť"))}
            startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
            onClick={closeModal}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default SC_KIO_0505021_EditAddressForm;
