import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import questionnaireRedux from "../../../../../../redux";
import { ZvjsCheckBoxStateValues } from "../../../../../../../components/ZvjsCheckBox";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import {
  RequestEditIcon,
  ZvjsButton,
  ZvjsCheckBox,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsTable,
} from "../../../../../../../components";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useModal } from "../../../../../../../../store/context/modal-context";
import { useSubmit } from "react-router-dom";
import { ActionBodyWrapper } from "../../../../../../../../router/ActionBodyWrapper";
import { RequestActionsType } from "../../../../../../Request";
import SC_KIO_050502_AddPersonReceivingPackageForm
  from "../../../forms/SC_KIO_050502_AddPersonReceivingPackageForm";
import { SC_KIO_050502_EditAddressForm } from "../../../forms";

export interface PersonRelation {
  name: string;
  surname: string;
  klientId: number;
  relation: number;
}

const SC_KIO_050502_PersonReceivingPackageGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { openModal } = useModal();
  const { tuiz } = useUITranslation();
  const submit = useSubmit();
  const [newList, setNewList] = useState<PersonRelation[]>([]);

  console.debug(
    `SC_KIO_050502 PERSON RECEIVING PACKAGE GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getPersonReceivingPackageGridDisplayData(
      location,
      newList
    ),
    shallowEqual
  );

  const closePeopleList = useSelector(
    questionnaireRedux.selectors.getPersonReceivingPackageList(),
    shallowEqual
  );

  console.log(questionData);

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | string[];

  const basicClientData = useSelector(
    questionnaireRedux.selectors.getPersonReceivingPackageBasicClientData(),
    shallowEqual
  );

  const closePersonRelationCounter = useSelector(
    questionnaireRedux.selectors.getPersonReceivingPackageRelationTypeCounter(),
    shallowEqual
  );

  const dataStyle: TableStyle[] = [
    {
      align: "left",
      width: 90,
    },
    {
      align: "left",
      width: 90,
    },
    {
      align: "left",
      width: 90,
    },
    {
      align: "right",
      width: 250,
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
    {
      align: "right",
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("meno")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tuiz("priezvisko")),
      ...dataStyle[1],
    },
    {
      value: capitalize(tuiz("ziadost.ziadostOnavstevu.labelVztahKosobe")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("štát")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("kraj")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("okres")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("obec")),
      ...dataStyle[2],
    },
    {
      value: (tuiz("PSČ")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("ulica")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("Súpisné číslo")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tuiz("Orientačné číslo")),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOvydanieVeci.labelZaslatOsobeBalik")
      ),
      ...dataStyle[3],
    },
    {
      value: "",
      ...dataStyle[4],
    },
  ];

  const onEditPersonVideoCallSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050502_EDIT_ADDRESS_CONTACT,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  const onChangeHandler = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    let answersCopy: string[] = answerVal ? [...answerVal] : [];

    if (checked) {
      answersCopy = [key];
    } else {
      answersCopy = answersCopy.filter((answer) => answer !== key);
    }

    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        answersCopy,
      )
    );
  };

  const bodyData: Array<RowData> = questionData.peopleList.map((personDisplayData) => {
    const isChecked = answerVal?.includes(personDisplayData.id.toString());

    const getRequestEditIcon = () => {
      const closePersonData = closePeopleList.find(
        (closePerson) => closePerson.fyzickaOsobaId === personDisplayData.id
      );

      if (closePersonData) {
        return (
          <RequestEditIcon
            onClick={(event) =>
              openModal(
                <SC_KIO_050502_EditAddressForm
                  closePersonData={closePersonData}
                  closePersonDisplayData={personDisplayData}
                  // On submit method cannot be defined directly inside dialog, as dialog itself is mounted in the root of application (via context) which means that router action (registered to specific route) would not catch this submit action
                  onSubmit={onEditPersonVideoCallSubmit}
                  closePersonRelationCounter={closePersonRelationCounter}
                />
              )
            }
          />
        );
      }
      return "";
    };

    return {
      row: [
        {
          value: personDisplayData.firstname ?? "",
          ...dataStyle[0],
        },
        {
          value: personDisplayData.surname ?? "",
          ...dataStyle[1],
        },
        {
          value: personDisplayData.relationText ?? "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.stat : "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.kraj : "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.okres : "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.obec : "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.psc : "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.ulica : "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.supisneCislo : "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.isPossibleToContact ? personDisplayData.orientacneCislo : "",
          ...dataStyle[2],
        },
        {
          value: (
            <ZvjsCheckBox
              onChange={onChangeHandler}
              data={[
                {
                  key: personDisplayData.id.toString(),
                  checked: answerVal?.includes(personDisplayData.id.toString()),
                },
              ]}
            />
          ),
          ...dataStyle[3],
        },
        {
          value: getRequestEditIcon(),
          ...dataStyle[4],
        },
      ],
    };
  });

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  const onSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050502_ADD_NEW_PERSON,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      <ZvjsButton
        zvjsVariant={"requestAction"}
        text={tuiz("ziadost.ziadostOnavstevu.labelDoplnenieInejOsoby")}
        onClick={() => {
          openModal(
            <SC_KIO_050502_AddPersonReceivingPackageForm
              onSubmit={onSubmit}
              basicClientData={basicClientData}
              closePersonRelationCounter={closePersonRelationCounter}
              newList={newList}
            />
          );
        }}
        endIcon={
          <AddCircleOutlineOutlinedIcon sx={{ fontSize: "30px !important" }} />
        }
      />
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050502_PersonReceivingPackageGrid;
