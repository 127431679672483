import { ZvjsQuestionnaireTemplate, ZvjsQuestionTypes } from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050901",
  basicInfo: [
    {
      text: "ziadost.ziadostOpohovorSriaditelomUstavu.napovedaJednaMoznost",
    },
  ],
  isAvailableToUser: "SC_KIO_050901_isAvailableToUser",
  counters: [
    "/api/CisDovodZiadostiOrozhovor/List",
    "/api/CisZabezpeceniePrav/List",
    "/api/CisPodnetNaOchranu/List",
    "/api/CisUbytovanie/List",
    "/api/CisStravovanie/List",
    "/api/CisOdievanie/List",
    "/api/CisSpanok/List",
    "/api/CisVeciOsobnejPotreby/List",
    "/api/CisPrijimaniePenazProstriedkov/List",
    "/api/CisZdravotnaStarostlivost/List",
  ],
  items: [
    {
      id: "dovodZiadostiOPohovor",
      title: "ziadost.ziadostOpohovorSriaditelomUstavu.labelDovodZiadosti",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      isRequired: true,
      maxNumberSelect: 1,
      hintText: "ziadost.ziadostOpohovorSriaditelomUstavu.napovedaJednaMoznost",
      options: {
        answerId: "dovodZiadostiOPohovor1",
        counterKey: "/api/CisDovodZiadostiOrozhovor/List",
        textKey: "nazov",
        children: [
          {
            id: "VAZBA",
          },
          {
            id: "PODMIE",
          },
          {
            id: "PRAVA",
            options: {
              answerId: "dovodZiadostiOPohovor2",
              counterKey: "/api/CisZabezpeceniePrav/List",
              textKey: "nazov",
              children: [
                {
                  id: "UBYTUM",
                  options: {
                    answerId: "dovodZiadostiOPohovor3",
                    counterKey: "/api/CisUbytovanie/List",
                    textKey: "nazov",
                    children: [
                      { id: "VYBAVA" },
                      { id: "UBYJED" },
                      { id: "PODMUM" },
                      { id: "INE" },
                    ],
                  },
                },
                {
                  id: "STRAVA",
                  options: {
                    answerId: "dovodZiadostiOPohovor3",
                    counterKey: "/api/CisStravovanie/List",
                    textKey: "nazov",
                    children: [
                      { id: "ZLOZEN" },
                      { id: "KVALIT" },
                      { id: "INE" },
                    ],
                  },
                },
                {
                  id: "ODEV",
                  options: {
                    answerId: "dovodZiadostiOPohovor3",
                    counterKey: "/api/CisOdievanie/List",
                    textKey: "nazov",
                    children: [
                      { id: "VLASTN" },
                      { id: "USTAV" },
                      { id: "INE" },
                    ],
                  },
                },
                {
                  id: "SPANOK",
                  options: {
                    answerId: "dovodZiadostiOPohovor3",
                    counterKey: "/api/CisSpanok/List",
                    textKey: "nazov",
                    children: [{ id: "SPRCH" }, { id: "ZDPOH" }, { id: "INE" }],
                  },
                },
                {
                  id: "VECIOP",
                  options: {
                    answerId: "dovodZiadostiOPohovor3",
                    counterKey: "/api/CisVeciOsobnejPotreby/List",
                    textKey: "nazov",
                    children: [{ id: "ELSPOT" }, { id: "INE" }],
                  },
                },
                { id: "NAVST" },
                { id: "POSTA" },
                { id: "TELEF" },
                {
                  id: "PENIAZ",
                  options: {
                    answerId: "dovodZiadostiOPohovor3",
                    counterKey: "/api/CisPrijimaniePenazProstriedkov/List",
                    textKey: "nazov",
                    children: [
                      { id: "NEZROV" },
                      { id: "STAVKR" },
                      { id: "INFOV" },
                      { id: "PRACOD" },
                      { id: "DOCH" },
                      { id: "INE" },
                    ],
                  },
                },
                { id: "NAKUPY" },
                {
                  id: "ZDRAST",
                  options: {
                    answerId: "dovodZiadostiOPohovor3",
                    counterKey: "/api/CisZdravotnaStarostlivost/List",
                    textKey: "nazov",
                    children: [{ id: "LIEKY" }, { id: "VSEOB" }, { id: "INE" }],
                  },
                },
                { id: "VZDELA" },
              ],
            },
          },
          {
            id: "DISCIPL",
          },
          {
            id: "PRACA",
          },
          {
            id: "ZAOBCH",
          },
          {
            id: "INE",
          },
          {
            id: "OCHRAN",
            options: {
              answerId: "dovodZiadostiOPohovor2",
              counterKey: "/api/CisPodnetNaOchranu/List",
              textKey: "nazov",
              children: [{ id: "KONVO" }, { id: "KONPZ" }],
            },
          },
          {
            id: "INEBEZ",
          },
        ],
      },
    },
    {
      id: "blizsieOdovodnenieZiadostiORozhovor",
      title:
        "ziadost.ziadostOpohovorSriaditelomUstavu.labelBlizsieSpecifikovanie",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      hintText:
        "ziadost.ziadostOpohovorSriaditelomUstavu.napovedaDovodPohovoru",
      validations: ["MAX_ANSWER_LENGTH_500"],
    },
  ],
};
