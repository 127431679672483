import { UserCalcData } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectUserCalcData } from "../../../../../../redux/selectors";
import {isAnyPorusenaPodmienka} from "../../../../../../../../utils/helpers";
import {PodmienkaZiadostiEnum} from "../../../../utils/PodmienkaZiadostiEnum";

/**
 * Selectors
 */

const displayClientIsInNtGroupWarning051403 = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      const questionControl = state["SC_KIO_051403_QUESTION_CONTROL"];
      if (questionControl === undefined || questionControl.porusenePodmienky === null){
        return false;
      }
      return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR047]);
    }
  );

const displaySameRequestInNonTerminatedStateWarning051403 = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      const questionControl = state["SC_KIO_051403_QUESTION_CONTROL"];
      if (questionControl === undefined || questionControl.porusenePodmienky === null){
        return false;
      }
      return isAnyPorusenaPodmienka(questionControl, [PodmienkaZiadostiEnum.BR049]);
    }
  );

export const allSelectors = {
  displayClientIsInNtGroupWarning051403,
  displaySameRequestInNonTerminatedStateWarning051403,
};
