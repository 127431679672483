import { createSelector } from "@reduxjs/toolkit";
import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { findItem } from "../../../../../../redux/slice";
import { selectSelf } from "../../../../../../redux/selectors";
import {
  SC_KIO_050701_PravnyZastupca
} from "../../../../utils/calculations/custom";
import {
  SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST
} from "../../../../../../requestTemplates/SC_KIO_050701_Ziadost o telefonovanie";
import {
  DetailZakladneUdajeKlientaData,
  FyzickaOsobaKontakt
} from "../../../../../../../specs/swaggerTypes";

/**
 * Selectors
 */
const getPhoneCallLawyersGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    // const { getFallbackJazyk } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      peopleList: (
        state.userCalcData[
          SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST
        ] as SC_KIO_050701_PravnyZastupca[]
      ).map((person) => {
        return {
          id: person.id,
          fyzickaOsobaId: person.fyzickaOsobaId,
          firstname: person.firstname,
          surname: person.surname,
          phoneCallAsCivilian: person.phoneCallAsCivilian,
          phoneCallContact: person.phoneCallContact,
          phoneCallContact2: person.phoneCallContact2,
        } as SC_KIO_050701_PhoneCallLawyersDisplayData;
      }),
    };
  });

const getPhoneCallLawyersList = () =>
  createSelector(selectSelf, (state: State): SC_KIO_050701_PravnyZastupca[] => {
    return state.userCalcData[
      SC_KIO_050701_PRAVNY_ZASTUPCOVIA_KLIENTA_LIST
    ] as SC_KIO_050701_PravnyZastupca[];
  });

const getPhoneCallLawyersBasicClientData = () =>
  createSelector(selectSelf, (state: State): DetailZakladneUdajeKlientaData => {
    return state.userCalcData["BASIC_CLIENT_DATA"];
  });

export const allSelectors = {
  getPhoneCallLawyersGridDisplayData,
  getPhoneCallLawyersList,
  getPhoneCallLawyersBasicClientData,
};

export interface SC_KIO_050701_PhoneCallLawyersDisplayData {
  id: number | undefined;
  fyzickaOsobaId: number;
  firstname: string | undefined;
  surname: string | undefined;
  phoneCallAsCivilian: boolean;
  phoneCallContact: FyzickaOsobaKontakt | undefined;
  phoneCallContact2: FyzickaOsobaKontakt | undefined;
}
