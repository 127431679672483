import {
  Calculate,
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate
} from '../redux/model';

export const SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST =
  "SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050502",
  counters: [
    "/api/CisTypBlizkejOsoby/List",
  ],
  dataCalculations: {
    SC_KIO_050502_ZASLANY_BALIK_OSOBY_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  basicInfo: [
    {
      text: "ziadost.ziadostOvydanieVeci.zakladneInfo",
    },
  ],
  items: [
    {
      id: "civilneOsobyList",
      title: "Výber osoby, ktorej má byť zaslaný balík",
      type: ZvjsCustomQuestionTypes.SC_KIO_050502_PERSON_RECEIVING_PACKAGE_GRID,
      isRequired: true,
    },
  ],
};
