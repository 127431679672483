import { defer, useNavigate } from 'react-router-dom';
import {
  ZvjsButton,
  ZvjsTable,
} from '../../../../common';
import { Grid, Stack, Typography } from '@mui/material';
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData, TableData,
  TableStyle,
  ZvjsTableVariant,
} from '../../../../common/components/ZvjsTable';
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import {
  MAX_NUMBER_OF_ITEMS,
} from "../../../../store/context/dataApi/Data";
import { ZVJS_COLORS } from "../../../../theme/zvjs_theme";
import {
  getTranslationByLanguageId,
} from '../../../../utils/helpers';
import i18n, { getCiselnikJazyk } from "../../../../locales/i18n";
import React from 'react';
import { Requests_SC_KIO_0481 } from '../../../../common/specs/sections/Requests_SC_KIO_0481';
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";

const loadData = async () => {
  const { CIS_Post, SZOO_Post } = await API_Clients();

  const data = await Promise.all([
    SZOO_Post(
      "/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId") ?? "",
          },
          kategoriaZiadostiKod: {
            eq: "9",
          }
        }
      }
    ),
    CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam žiadostí klienta]");
  }

  const sortedRequests = data[0]?.data.sort(
    (r1, r2) =>
      new Date(r2.datumPodaniaZiadosti ?? "").getTime() -
      new Date(r1.datumPodaniaZiadosti ?? "").getTime()
  );

  return {
    userRequests: sortedRequests,
    requestTypes: data[1],
  };
};

export const ZiadostiTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface ZiadostiTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const ZiadostiTab = (props: ZiadostiTabProps) => {
  const { getFallbackJazyk } = useUITranslation();
  const navigate = useNavigate();

  const { userRequests, requestTypes } = props.data;

  const tableStyle: TableStyle[] = [
    { align: "left", width: 130 },
    { align: "left" },
    { align: "left" },
    { align: "right" },
  ];

  const header: CellData[] = [
    {
      value: "Dátum podania",
      ...tableStyle[0],
    },
    {
      value: "Názov žiadosti",
      ...tableStyle[1],
    },
    {
      value: "Stav",
      ...tableStyle[2],
    },
    {
      value: "Akcia",
      ...tableStyle[3],
    },
  ];

  const getRequestStateColor = (requestState?: string | null): string => {
    if (requestState) {
      if (requestState.includes("Zaevidovaná")) return ZVJS_COLORS.BLUE;
      if (requestState.includes("Schválená")) return ZVJS_COLORS.GREEN;
      if (requestState.includes("Zamietnutá")) return ZVJS_COLORS.RED;
      if (requestState.includes("Späťvzatá")) return ZVJS_COLORS.PURPLE;
    }
    return ZVJS_COLORS.BLUE;
  };

  const getTableBody = (): RowData[] => {
    return (
      userRequests?.map((request: any) => {
        return {
          row: [
            {
              value: new Date(request.datumPodaniaZiadosti ?? "").zvjsToString(),
              ...tableStyle[0],
            },
            {
              value: request.typZiadostiId
                ? getTranslationByLanguageId(
                  requestTypes?.data?.records ?? [],
                  getCiselnikJazyk(i18n.language),
                  getFallbackJazyk(),
                  request.typZiadostiId,
                  "nazov"
                )
                : "",
              ...tableStyle[1],
            },
            {
              value: (
                <div
                  style={{
                    backgroundColor: getRequestStateColor(
                      request.stavZiadostiNazov
                    ),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "7rem",
                    borderRadius: "0.2rem",
                  }}
                >
                  <Typography
                    fontSize={"20"}
                    color={ZVJS_COLORS.WHITE}
                    textAlign={"center"}
                  >
                    {request.stavZiadostiNazov}
                  </Typography>
                </div>
              ),
              ...tableStyle[2],
            },
            {
              value: (
                <ZvjsButton
                  text={"Detail"}
                  zvjsVariant="secondaryAction"
                  startIcon={
                    <DetailIcon
                      style={{ fill: "white", width: 28, height: 28 }}
                    />
                  }
                  onClick={() => {
                    navigate(
                      `/auth/Requests/${Requests_SC_KIO_0481.Detail}/${request.ziadostId}`
                    );
                  }}
                />
              ),
              ...tableStyle[3],
            },
          ],
        };
      }) ?? []
    );
  };

  const data: TableData = {
    header: header,
    body: getTableBody(),
  };

  return (
    <Grid pt={3}>
      <Stack mb={3}>
        <ZvjsTable variant={ZvjsTableVariant.NORMAL} data={data} height={500} />
      </Stack>
    </Grid>
  );
};

export default withLoader(ZiadostiTab);
