import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import { ZvjsSelect } from '../../../../../../../components';
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { Grid, SelectChangeEvent } from '@mui/material';

const SC_KIO_051104_ClothingChangeDropdown: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.debug(
    `SC_KIO_051104 CLOTHING CHANGE DROPDOWN QUESTION RERENDER ${location.join("-")}`
  );

  const dropdownQuestionData = useSelector(
    questionnaireRedux.selectors.getClothesToChangeDisplayData(location),
    shallowEqual
  );
  console.log(dropdownQuestionData);

  /*const dropdownQuestionData = useSelector(
    questionnaireRedux.selectors.getDropdownQuestionNewDisplayData(location)
  );*/

  console.log(dropdownQuestionData);

  // use shallowEqual so question is not re-rendered each time selector returns new array instance: https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  /*const isOptionDisplayedArray = useSelector(
    questionnaireRedux.selectors.getDropdownNewOptionsDisplayedArray(location),
    shallowEqual
  );*/

  const answerVal = useSelector(
    questionnaireRedux.selectors.getDropdownQuestionClothesAnswerData(
      location,
      dropdownQuestionData.id
    )
  );
  const validationError = useSelector(
    questionnaireRedux.selectors.getValidationError(dropdownQuestionData.id)
  );

  const filteredOptions = dropdownQuestionData.options;

  const onChangeHandler = (event: SelectChangeEvent) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        dropdownQuestionData.id,
        event.target.value
      )
    );
  };

  // if only one option is available to user, answer is required,
  // and no option is selected yet, then auto-select the only option available
  useEffect(() => {
    if (
      filteredOptions.length === 1 &&
      answerVal === undefined &&
      dropdownQuestionData.isRequired
    ) {
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          dropdownQuestionData.id,
          filteredOptions[0].value
        )
      );
    }
  }, [
    answerVal,
    dispatch,
    dropdownQuestionData.id,
    dropdownQuestionData.isRequired,
    filteredOptions,
    location,
  ]);

  return (
    <Grid container>
      <ZvjsSelect
        id={`dropdown-question-${location.join("-")}`}
        items={filteredOptions}
        text={tuiz(dropdownQuestionData.title)}
        controlled={{
          selectedValue: answerVal ?? "",
          changeHandler: onChangeHandler,
        }}
        hintText="Hintik"
        hintSize={20}
        answerRequired={dropdownQuestionData.isRequired}
        error={validationError !== undefined}
        validationFlagType={validationError?.type}
        validationErrorMessage={tuiz(validationError?.message ?? "")}
      />
    </Grid>
  );
};

export default SC_KIO_051104_ClothingChangeDropdown;
