import React, { Fragment } from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

export interface ZvjsCheckBoxData {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  children?: ZvjsCheckBoxData[];
  onChange?: (value: boolean) => void;
  key: string;
}

export interface ZvjsCheckBoxStateValues {
  uniqueKey: string;
  checked: boolean;
  label?: string;
}

interface ZvjsCheckBoxDataIndex {
  label?: string;
  disabled?: boolean;
  children?: ZvjsCheckBoxDataIndex[];
  index: number;
  onChange?: (value: boolean) => void;
  key: string;
}

interface ZvjsCheckBoxProp {
  onChange?:
    | ((
        label: string | undefined,
        key: string,
        checked: boolean,
        checkedValues: ZvjsCheckBoxStateValues[]
      ) => void)
    | undefined;
  onDisabledCheckBoxClick?: (key: string) => void;
  data: ZvjsCheckBoxData[];
  label?: string;
  offset?: number;
  disabled?: boolean;
}

const ZvjsCheckBoxNew: React.FC<ZvjsCheckBoxProp> = ({ data, label, ...rest }) => {
  const createStateArray = (dataP: ZvjsCheckBoxData[]): ZvjsCheckBoxStateValues[] => {
    const arr: ZvjsCheckBoxStateValues[] = [];
    const recursionCreate = (data: ZvjsCheckBoxData[]): void => {
      for (const mapData of data) {
        arr.push({
          checked: mapData.checked || false,
          uniqueKey: mapData.key,
          label: mapData.label,
        });
        if (mapData.children) {
          recursionCreate(mapData.children);
        }
      }
    };
    recursionCreate(dataP);
    return arr;
  };

  // Initialize state from props
  const [checked, setChecked] = React.useState<ZvjsCheckBoxStateValues[]>(createStateArray(data));

  // Sync state with data prop
  React.useEffect(() => {
    setChecked(createStateArray(data));
  }, [data]); // Re-run whenever `data` changes

  const handleClick = (index: number, indexStart?: number, indexEnd?: number) => {
    const prevState = [...checked];
    prevState[index].checked = !prevState[index].checked;
    if (indexStart && indexEnd) {
      for (let i = indexStart; i < indexEnd; i++) {
        prevState[i].checked = prevState[index].checked;
      }
    }
    setChecked(prevState);
    if (rest.onChange) {
      const newArr = prevState.filter((values) => values.checked);
      rest.onChange(
        prevState[index].label,
        prevState[index].uniqueKey,
        prevState[index].checked,
        newArr
      );
    }
  };

  // Process the `data` recursively to render checkboxes (no changes here)
  const processData = (dataP: ZvjsCheckBoxData[]): React.ReactNode => {
    const recursion = (dataP: ZvjsCheckBoxData[], level: number): React.ReactNode => {
      return dataP.map((mapData) => {
        if (mapData.children) {
          return (
            <Fragment key={mapData.key}>
              <FormControlLabel
                label={mapData.label}
                control={
                  <Checkbox
                    checked={checked.find((item) => item.uniqueKey === mapData.key)?.checked || false}
                    disabled={mapData.disabled}
                    onClick={() => handleClick(data.findIndex((d) => d.key === mapData.key))}
                  />
                }
              />
              <Box sx={{ ml: level * (rest.offset || 3) }}>
                {recursion(mapData.children, level + 1)}
              </Box>
            </Fragment>
          );
        } else {
          return (
            <FormControlLabel
              key={mapData.key}
              label={mapData.label}
              control={
                <Checkbox
                  checked={checked.find((item) => item.uniqueKey === mapData.key)?.checked || false}
                  disabled={mapData.disabled}
                  onClick={() => handleClick(data.findIndex((d) => d.key === mapData.key))}
                />
              }
            />
          );
        }
      });
    };

    return recursion(dataP, 1);
  };

  return (
    <Stack direction="column">
      {label && <Typography variant="h2">{label}</Typography>}
      {processData(data)}
    </Stack>
  );
};

export default ZvjsCheckBoxNew;
